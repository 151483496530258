import React, { Component } from 'react';
import Loader from "react-loader-spinner";
import {Layer, Stage} from "react-konva";
import ProductImage from "../../KonvaCanvasComponents/ProductImage";

import $ from "jquery";




class ProductCanvasPanel extends Component {


    constructor(props) {

        super(props);

        let localSidesScreenshotsObject = {};

        // генеруємо об*єкт скірншовтів сторін , для локального користування
        this.props.sidesArrayByActiveColor.forEach((side) => {

            localSidesScreenshotsObject[side.side_name] = null;

        });

        // initializing local state
        this.state = {
            localSidesScreenshotsObject: localSidesScreenshotsObject
        }


        this.getScreenshotOfMainCanvasBySingleSide = this.getScreenshotOfMainCanvasBySingleSide.bind(this);
        this.mainCanvasRef = React.createRef();

    }




    componentDidUpdate(prevProps, prevState) {

        if (prevProps.activeProductColor !== this.props.activeProductColor || prevProps.isProductImageLoaderActive !== this.props.isProductImageLoaderActive) {
        // if ( prevProps !== this.props ) {

            //this.getScreenshotOfMainCanvasBySingleSide();

        }


    }



    getScreenshotOfMainCanvasBySingleSide() {

        if (this.mainCanvasRef.current) {

            let base64ImageOfMainCanvasBySingleSide = this.mainCanvasRef.current.toDataURL({ pixelRatio: 0.3 });


            this.setState(prevState => ({
                localSidesScreenshotsObject: {
                    ...prevState.localSidesScreenshotsObject,
                    [`${this.props.activeProductSide}`] : base64ImageOfMainCanvasBySingleSide
                }
            }));

        }

    }


    clearAllScreenshotOfMainCanvas() {

        let localSidesScreenshotsObject = {};

        // генеруємо об*єкт скірншовтів сторін , для локального користування
        this.props.sidesArrayByActiveColor.forEach((side) => {

            localSidesScreenshotsObject[side.side_name] = null;

        });

        this.setState({

            localSidesScreenshotsObject: localSidesScreenshotsObject

        });
    }




    render() {

        return (

            <div className="productCanvasPanel">

                {/* SIDES NAVIGATION BUTTONS */}
                <div className="sidesButtonsList">
                    <ul>
                        { this.props.renderSidesButtonsList(this.props.sidesArrayByActiveColor, this.state.localSidesScreenshotsObject) }

                        <li className="showOnModelModal" data-toggle="modal" data-target="#onModelGalleryModal">
                            <div className="sideButtonName">-</div>
                            <div className="sideButtonImageContainer">
                                <span>Model<br />shots</span>
                            </div>
                        </li>

                    </ul>
                </div>




                <div className="productMainCanvasImageContainer">

                    <button onClick={this.props.generateURLForSavedOrderData} data-toggle="modal" data-target="#saveForLaterModal" className="saveForLaterButton">
                        <span className="icon-save"></span> Save order
                    </button>

                    { (this.props.isProductImageLoaderActive) ? <div className="imageLoader">
                        <Loader
                            type="Oval"
                            color="#ED0084"
                            height="50"
                            width="50"
                        />
                    </div> : null }

                    {
                        (!this.props.isProductImageLoaderActive)
                            ? this.props.renderFileUploadButton(this.props.uploadImageObject)
                            : null
                    }

                    <div id="konvaCanvasContainer">

                        <Stage
                            width={472}
                            height={500}
                            ref={this.mainCanvasRef}
                            onMouseDown={ this.props.handleTransformerMethodForDraggableImage }>

                            <Layer>
                                <ProductImage
                                    src={this.props.mainProductImageUrl}
                                    text="Product Side Image"
                                    productImageIsLoaded={this.props.productImageIsLoaded}
                                    onStartProductImageLoading={this.props.onStartProductImageLoading}
                                    isScreenshotMode={false}
                                />
                            </Layer>


                            { this.props.renderDraggableImageOnProductComponent(this.props.uploadImageObject) }

                        </Stage>
                    </div>

                </div>

            </div>

        )

    }


}

export default ProductCanvasPanel;
