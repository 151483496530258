import React, { Component } from 'react';


class CategoriesAPILoader extends Component {

    constructor(props) {
        super(props);
    }


    renderProductsList() {

        let productCartList = [];

        for (let i = 1; i <= 4; i++) {

            productCartList.push (

                <div className="col-3" key={i}>
                    <div className="productItem loadingAnimationContent">
                        <div className="productItemImageContainer load-content-animation"></div>
                        <div className="productItemBody">
                            <div className="productItemName load-content-animation"></div>
                            <p className="productItemInfo load-content-animation"></p>
                        </div>
                    </div>
                </div>

            );

        }

        return productCartList;

    }



    renderProductsCategoryList() {

        let categoriesList = [];

        for (let i = 1; i <= 3; i++) {

            categoriesList.push(
                <div className="productCategoryRow row" key={i}>

                    <div className="productCategoryRowContainer col-12">

                        <div className="productCategoryRowTitle">

                            <div className="categoryTitleLoader load-content-animation"><span className="icon-fire"></span> Name</div>

                        </div>
                    </div>

                    <div className="productList">

                        <div className="row">

                            {this.renderProductsList()}

                        </div>

                    </div>

                    <div className="showAllProductButtonContainer col-2">
                        <div
                            className="showAllProductButton showAllProductButtonHidden"
                           >
                            <div className="showAllProductButtonBody">
                                <span className="icon-plus"></span>
                                Show All
                            </div>
                        </div>
                    </div>

                </div>
            );

        }

        return categoriesList;


    }




    render() {

        return (

                this.renderProductsCategoryList()

        )

    }

}

export default CategoriesAPILoader;
