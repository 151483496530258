import React, { Component } from 'react';
import './InitializePage.css';
import { withRouter } from 'react-router-dom';

const queryString = require('query-string');



class InitializePage extends Component {


    constructor(props) {

        super(props);
        this.state = {
            isInitializeLinkToEmailSent: false
        }

        this.sendInitializeLinkToEmail = this.sendInitializeLinkToEmail.bind(this);

    }


    sendInitializeLinkToEmail() {

        alert('пісьмо полетіло');
        // використати цей рядок коли відправлено!
        this.setState({ isInitializeLinkToEmailSent: true })

    }




    render() {

        const parsedUrlParameters = queryString.parse(this.props.location.search);



        return (


            <div className="initializePage">

                <div className="container">

                    <div className="initializePageBody">

                        { (!this.state.isInitializeLinkToEmailSent)

                            ?
                            <div>
                                <h2> Hello {parsedUrlParameters.name}! </h2>

                                <p>
                                    You are about to begin your GarageChamps account setup. Upon finishing,
                                    you will have access to your history of orders in GarageChamps.

                                    You will need access to your <span>{parsedUrlParameters.email}</span> inbox. Click the button below
                                    to receive the email with a link to access your account.
                                </p>

                                <button onClick={this.sendInitializeLinkToEmail}>Send me the link</button>
                            </div>

                            :
                            <div className="initializePageBodyAfterSending">

                                <p>
                                    Email with the link was sent to your <strong><span>{parsedUrlParameters.email}</span></strong>.
                                    Please go to your inbox and click it. Check the spam folder if needed.<br/><br/>

                                    If something doesn't go according to plan - contact us via chat in the right bottom corner.
                                </p>

                            </div>
                        }

                    </div>

                </div>

            </div>

        )

    }


}

export default withRouter(InitializePage);

