import React, { Component } from 'react';
import './ProductsImagesScreenshot.css';
import { Stage, Layer, Image } from 'react-konva';
import * as filestack from "filestack-js";
import DraggableImageOnProduct from "../KonvaCanvasComponents/DraggableImageOnProduct";
import ProductImage from "../KonvaCanvasComponents/ProductImage";

// приставка до url для локального тестування
// const linkToImages = process.env.PUBLIC_URL + '/products/t-shirts/';
const filestackClient = filestack.init('ADrblcnafTIuIUCrkNTrNz');







class ProductsImagesScreenshot extends Component {


    constructor(props) {
        super(props);
        this.state = {
            productImageIsLoadedCount: 0,
            draggableImageOnProductCount: 0
        };

        this.uploadingImageIsLoaded = this.uploadingImageIsLoaded.bind(this);
        this.productImageIsLoaded = this.productImageIsLoaded.bind(this);
    }



    renderDraggableImageOnProduct(uploadImageObject) {


        if ( uploadImageObject.upload_image_object ) {

            // console.log(uploadImageObject.upload_image_object.url);

            return (

                <Layer>

                    <DraggableImageOnProduct
                        src={uploadImageObject.upload_image_object.url}
                        text="Draggable Image On Product"
                        x={uploadImageObject.coordX}
                        y={uploadImageObject.coordY}
                        scaleX={uploadImageObject.scaleX}
                        scaleY={uploadImageObject.scaleY}
                        rotation={uploadImageObject.rotation}
                        uploadingImageIsLoaded={this.uploadingImageIsLoaded}
                        isScreenshotMode={true}
                    />

                </Layer>

            )

        }


    }



    renderAllSidesCanvases() {


        let productObject = this.props.productObject;

        return productObject.map((color) => {

            if ( this.props.activeProductColor === color.color_name ) {


                return color.color_sides_images.map((side, index) => {

                    return (

                        <Stage
                            key={index}
                            width={472}
                            height={500}
                            ref={node => { this[`stageRef${index}`] = node}} >


                            <Layer>
                                <ProductImage
                                    src={side.side_image_url}
                                    text="Product Side Image"
                                    productImageIsLoaded={this.productImageIsLoaded}
                                    isScreenshotMode={true}
                                />
                            </Layer>

                            {this.renderDraggableImageOnProduct(this.props.productImagesObject[`${side.side_name}`])}


                        </Stage>

                    )

                });

            }

            return null;

        });

    }



    productImageIsLoaded() {

        this.setState({ productImageIsLoadedCount: this.state.productImageIsLoadedCount + 1 });

    }



    uploadingImageIsLoaded() {

        this.setState({ draggableImageOnProductCount: this.state.draggableImageOnProductCount + 1 });

    }



    componentDidUpdate(prevProps, prevState) {

        if (prevState !== this.state) {

            let productSidesCount = Object.keys(this.props.productImagesObject).length;
            let productUploadImageCount = 0;

            for (let key in this.props.productImagesObject) {

                if ( this.props.productImagesObject[key].upload_image_object !== null ) {

                    productUploadImageCount++;

                }

            }

            if ( productSidesCount === this.state.productImageIsLoadedCount && productUploadImageCount === this.state.draggableImageOnProductCount ) {

                let productImagesScreenshotsArray = [];

                for ( let i = 0; i < productSidesCount; i++) {

                    productImagesScreenshotsArray.push(this[`stageRef${i}`].toDataURL({ pixelRatio: 2 }));


                }

                console.log('скріншот канвасів');

                let productImagesScreenshotsArrayFromFilestackServer = [];

                // VERSION WITH FILESTACK ////////////////////////////////////////////////////

                let progressBarContainerElement = document.querySelector('.addToCartProgressBar > span');

                // show artwork upload progress bar
                const onProgress = (evt) => {

                    progressBarContainerElement.innerHTML = `${evt.totalPercent}%`;

                };

                filestackClient.multiupload(productImagesScreenshotsArray, {onProgress})
                    .then(res => {
                        res.forEach((item) => {

                            productImagesScreenshotsArrayFromFilestackServer.push(item.url)

                        });

                        this.props.productImagesScreenshotsIsDone(productImagesScreenshotsArrayFromFilestackServer);


                    })
                    .catch(err => {
                        console.log(err);
                    });

                // VERSION FOR TESTING WITHOUT FILESTACK //////////////////////////////////////

                // productImagesScreenshotsArrayFromFilestackServer = ['https://cdn.filestackcontent.com/rAM6QI08RkGgTKCB2H5M', 'https://cdn.filestackcontent.com/OOTDNbi4RhCd4mgLsDXx', 'https://cdn.filestackcontent.com/RB3qWwNQ1axKO4wMMgSK', 'https://cdn.filestackcontent.com/bQe325yZQlmT3Ayo17Pe']
                // this.props.productImagesScreenshotsIsDone(productImagesScreenshotsArrayFromFilestackServer);


            }
        }

    }



    render() {


        return (

            <div className="productsImagesScreenshot">

                <div className="productsImagesScreenshotCanvases">

                    {this.renderAllSidesCanvases()}

                </div>

            </div>

        )

    }


}

export default ProductsImagesScreenshot;
