import React, { Component } from 'react';
import DOMPurify from "dompurify";
import {Link} from "react-router-dom";



class ProductTitles extends Component {


    constructor(props) {

        super(props);

    }



    render() {

        return (

            <div className="productTitles">

                {
                    ( !this.props.isProductEditing )
                        ? <div className="singleProductSubTitle">
                            <Link to={'/'}>
                                <div className="backToStartStepButton">
                                    <span className="icon-arrow-right"></span> Back
                                </div>
                            </Link>
                            CREATE YOUR PRODUCT
                        </div>
                        : <div className="singleProductSubTitle productEditingMode">
                            EDITING PRODUCT FROM THE CART
                        </div>
                }

                <h1 className={`singleProductTitle ${( this.props.isProductEditing ) ? 'productEditingMode' : null }`}>
                    { this.props.productObject.product_name }
                </h1>

                <div className="singleProductDescription">
                    <span
                        dangerouslySetInnerHTML={{__html:
                                DOMPurify.sanitize(this.props.productObject.product_short_description)}}
                    ></span>
                    <button data-toggle="modal" data-target="#productSpecificationsModal">See specs</button>
                </div>

            </div>

        )

    }


}

export default ProductTitles;
