import React, { Component } from 'react';
import {
    MINIMUM_NUMBER_OF_PRODUCTS_FOR_EMBROIDERY,
    MINIMUM_NUMBER_OF_PRODUCTS_FOR_SCREEN_PRINTING
} from "../../../../../properties";



class ProductTotalPricePanel extends Component {


    constructor(props) {

        super(props);

    }


    render() {

        let additionalPercentageForTotalPriceBySpeedValue = (( this.props.deliveryTypeCoefficient - 1 ) * 100).toFixed();

        return (

            <div className="productTotalPricePanel">

                <div className={`additionalPercentageForTotalPriceBySpeed ${(additionalPercentageForTotalPriceBySpeedValue > 0) ? 'is-visible' : null }`}>
                    *Total is <strong>+{ additionalPercentageForTotalPriceBySpeedValue }%</strong> due to chosen production speed
                </div>

                <div className={`col-12 singleProductAddToCartContainer ${(!this.props.isProductEditing) ? "screenPrintingPriceBlock" : "embroideryPriceBlock" }`}>
                    <div className="row">
                        <div className="col-6">
                            <div className="totalPriceAfterCalculate">TOTAL:

                                {
                                    // перевіряємо тип друку
                                    (this.props.activeServiceType === 'screen printing')

                                        ? // якщо це "screen printing"

                                            ( this.props.colorsAmountValidation() && this.props.getTotalProducts() >= MINIMUM_NUMBER_OF_PRODUCTS_FOR_SCREEN_PRINTING )

                                                ?
                                                    <span>
                                                        <span className="totalPriceBlock">${ (this.props.getTotalProductsPrice(this.props.activeServiceType) * this.props.deliveryTypeCoefficient).toFixed(2) }</span>
                                                        <span className="volumeSavingsBlock" data-toggle="modal" data-target="#priceBreaksModal">Volume savings { this.props.getCurrentVolumeSavingByTotalOfProduct() }%</span>
                                                    </span>

                                                : <span className="totalPriceBlock">$ --.--</span>

                                        :   // якщо це "embroidery"

                                            ( this.props.getTotalProducts() >= MINIMUM_NUMBER_OF_PRODUCTS_FOR_EMBROIDERY )

                                                ?
                                                    <span>
                                                        <span className="totalPriceBlock">${(this.props.getTotalProductsPrice(this.props.activeServiceType) * this.props.deliveryTypeCoefficient).toFixed(2)}</span>
                                                        <span className="volumeSavingsBlock" data-toggle="modal" data-target="#priceBreaksModal">Volume savings { this.props.getCurrentVolumeSavingByTotalOfProduct() }%</span>
                                                    </span>

                                                : <span className="totalPriceBlock">$ --.--</span>

                                }

                            </div>
                            <div className="productionCompletedDate desktop-only">Production Completed By: { this.props.deliveryTypeDate } <span data-toggle="modal" data-target="#deliveringTypeModal">Change</span></div>
                        </div>
                        <div className="col-6">

                            {
                                ( !this.props.isProductEditing )

                                    ?   // render add to cart button

                                        // перевіряємо тип друку
                                        (this.props.activeServiceType === 'screen printing')

                                            ?   // якщо це "screen printing"

                                                ( this.props.colorsAmountValidation() && this.props.getTotalProducts() >= MINIMUM_NUMBER_OF_PRODUCTS_FOR_SCREEN_PRINTING )

                                                    ?   <button onClick={this.props.getProductImagesScreenshots} type="button" className="stepCalculationMainButton addToCart">Add to cart <span className="icon-cart"></span></button>
                                                    :   <button type="button" className="stepCalculationMainButton addToCart buttonNotValid">Add to cart <span className="icon-cart"></span></button>

                                            :   // якщо це "embroidery"

                                                ( this.props.getTotalProducts() >= MINIMUM_NUMBER_OF_PRODUCTS_FOR_EMBROIDERY )

                                                    ?   <button onClick={this.props.getProductImagesScreenshots} type="button" className="stepCalculationMainButton addToCart">Add to cart <span className="icon-cart"></span></button>
                                                    :   <button type="button" className="stepCalculationMainButton addToCart buttonNotValid">Add to cart <span className="icon-cart"></span></button>

                                    :   // render save product after editing button

                                        <div className="singleProductEditingButtonsBlock">
                                            <button className="cancelEditingProductButton" onClick={this.props.cancelEditingProduct} type="button" data-dismiss="modal">Cancel <span className="icon-close"></span></button>
                                                {
                                                    // перевіряємо тип друку
                                                    (this.props.activeServiceType === 'screen printing')

                                                        ?   // якщо це "screen printing"

                                                        ( this.props.colorsAmountValidation() && this.props.getTotalProducts() >= MINIMUM_NUMBER_OF_PRODUCTS_FOR_SCREEN_PRINTING )
                                                            ?   <button onClick={this.props.getProductImagesScreenshots} type="button" className="stepCalculationMainButton saveProductAfterEditing">Save edits <span className="icon-ok"></span></button>
                                                            :   <button type="button" className="stepCalculationMainButton saveProductAfterEditing buttonNotValid">Save edits <span className="icon-ok"></span></button>

                                                        :   // якщо це "embroidery"

                                                        ( this.props.getTotalProducts() >= MINIMUM_NUMBER_OF_PRODUCTS_FOR_EMBROIDERY )
                                                            ?   <button onClick={this.props.getProductImagesScreenshots} type="button" className="stepCalculationMainButton saveProductAfterEditing">Save edits <span className="icon-ok"></span></button>
                                                            :   <button type="button" className="stepCalculationMainButton saveProductAfterEditing buttonNotValid">Save edits <span className="icon-ok"></span></button>

                                                }

                                        </div>
                            }

                        </div>
                    </div>
                </div>

                <div className="col-12">
                    <div className="productionCompletedDate mobile-only">
                        Production Completed By:
                        <span className="productionCompletedDateValue"> { this.props.deliveryTypeDate } </span>
                        <span className="productionCompletedDateButton" data-toggle="modal" data-target="#deliveringTypeModal">Change</span></div>
                </div>

            </div>

        )

    }


}

export default ProductTotalPricePanel;
