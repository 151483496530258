import {
    ADD_PRODUCT_TO_CART,
    DELETE_PRODUCT_FROM_CART,
    SAVE_PRODUCT_AFTER_EDITING,
    RESET_CART_LIST
} from "../actions/actions";


let initialState;
let localStorage = window.localStorage.getItem('product_list');

if (localStorage) {

    initialState = {
        product_list: JSON.parse(localStorage)
    };

} else {

    initialState = {
        product_list: []
    };

}




export function cart_reducer(state = initialState, action) {
    switch (action.type) {
        case ADD_PRODUCT_TO_CART :

            return {
                ...state,
                product_list: [...state.product_list, action.payload]
            };

        case DELETE_PRODUCT_FROM_CART :

            return {
                ...state,
                product_list: action.payload
            };

        case SAVE_PRODUCT_AFTER_EDITING :

            return {
                ...state,
                product_list: action.payload
            };

        case RESET_CART_LIST :

            return {
                ...state,
                product_list: []
            };

        default:

            return state
    }
}
