import React, { Component } from 'react';
import {
    MINIMUM_NUMBER_OF_PRODUCTS_FOR_EMBROIDERY,
    MINIMUM_NUMBER_OF_PRODUCTS_FOR_SCREEN_PRINTING
} from "../../../../../properties";



class ProductPricePerItemPanel extends Component {


    constructor(props) {

        super(props);

    }


    render() {



        return (

            <div className="productPricePerItemPanel">

                <div className="col-12 productPricePerOneContainer">
                    <span className="productPricePerOneTitle">Price per item:</span>

                    <span className="productPricePerOne">

                        {
                            // перевіряємо тип друку
                            (this.props.activeServiceType === 'screen printing')

                                ?   // якщо це "screen printing"

                                    (this.props.colorsAmountValidation() && this.props.getTotalProducts() >= MINIMUM_NUMBER_OF_PRODUCTS_FOR_SCREEN_PRINTING)

                                        ? '$ '+this.props.getSingleProductPrice(this.props.activeServiceType).toFixed(2)
                                        : "$ -.--"

                                :   // якщо це "embroidery"

                                    (this.props.getTotalProducts() >= MINIMUM_NUMBER_OF_PRODUCTS_FOR_EMBROIDERY)

                                        ? '$ '+this.props.getSingleProductPrice(this.props.activeServiceType).toFixed(2)
                                        : "$ -.--"

                        }

                    </span>

                    {
                        // перевіряємо тип друку
                        (this.props.activeServiceType === 'screen printing')

                            ?   // якщо це "screen printing"

                                (this.props.colorsAmountValidation() && this.props.getTotalProducts() >= MINIMUM_NUMBER_OF_PRODUCTS_FOR_SCREEN_PRINTING)

                                    ? <button className="priceBreaksButton" data-toggle="modal" data-target="#priceBreaksModal">Price breaks</button>
                                    : <button className="priceBreaksButton priceBreaksButtonDisabled">Price breaks</button>

                            :   // якщо це "embroidery"

                                (this.props.getTotalProducts() >= MINIMUM_NUMBER_OF_PRODUCTS_FOR_EMBROIDERY)

                                    ? <button className="priceBreaksButton" data-toggle="modal" data-target="#priceBreaksModal">Price breaks</button>
                                    : <button className="priceBreaksButton priceBreaksButtonDisabled">Price breaks</button>

                    }

                </div>

            </div>

        )

    }


}

export default ProductPricePerItemPanel;
