export const STANDARD_DELIVERING_COEFFICIENT = 1;
export const STANDARD_DELIVERING_DAYS_AMOUNT = 10;

export const RAPID_DELIVERING_COEFFICIENT = 1.15;
export const RAPID_DELIVERING_DAYS_AMOUNT = 5;

export const FULL_THROTTLE_DELIVERING_COEFFICIENT = 1.30;
export const FULL_THROTTLE_DELIVERING_DAYS_AMOUNT = 3;






export const PRICE_BREAKS_QTY_ARRAY = [12, 24, 48, 99, 144, 200, 500, 1000];
export const MINIMUM_NUMBER_OF_PRODUCTS_FOR_SCREEN_PRINTING = 24;
export const MINIMUM_NUMBER_OF_PRODUCTS_FOR_EMBROIDERY = 6;
