import React, { Component } from 'react';
import {Image} from "react-konva";


class ProductImage extends React.Component {

    state = {
        image: null
    };

    componentDidMount() {
        this.loadImage();
    }

    componentDidUpdate(oldProps) {
        if (oldProps.src !== this.props.src) {
            this.loadImage();
        }
    }


    componentWillUnmount() {
        this.image.removeEventListener('load', this.handleLoad);
    }


    loadImage() {

        if ( this.props.isScreenshotMode ) {

            console.log('фотка продукту готова');
            // save to "this" to remove "load" handler on unmount
            this.image = new window.Image();
            this.image.crossOrigin = "anonymous";
            this.image.src = this.props.src;
            this.image.addEventListener('load', this.handleLoad);

        } else {

            this.props.onStartProductImageLoading();
            // save to "this" to remove "load" handler on unmount
            this.image = new window.Image();
            this.image.setAttribute('crossOrigin', 'anonymous');
            this.image.src = this.props.src;
            this.image.addEventListener('load', this.handleLoad, true);

        }

    }



    handleLoad = () => {

        this.setState({

            image: this.image

        }, () => {

            this.props.productImageIsLoaded();

        });

    };



    render() {

        return (
            <Image
                id={ (!this.props.isScreenshotMode) ? "productImage" : null }
                x={(472 - 332) / 2 - 50}
                y={0 - 50}
                width={332}
                scaleX={1.3}
                scaleY={1.3}
                height={500}
                image={this.state.image}
            />
        );
    }
}


export default ProductImage;
