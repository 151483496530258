import React, { Component } from 'react';
import './SetupPage.css';
import ReactTooltip from "react-tooltip";
import $ from "jquery";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import Loader from "react-loader-spinner";



let sha256 = require('js-sha256');
const queryString = require('query-string');



class UserProfileForm extends Component {


    constructor(props) {

        super(props);

        let requiredUserAccountDataObject;
        let noRequiredUserAccountDataObject;

        // INIT STATE IF IT IS "SIGNUP PROFILE DATA" FORM
        if ( this.props.formType === "signup-profile-data" ) {

            requiredUserAccountDataObject = {
                email: null,
                password: null,
                firstName: null,
                lastName: null,
                phone: null,
            };

            noRequiredUserAccountDataObject = {
                address: null,
                state: null,
                zip: null,
                city: null
            };


        }

        // INIT STATE IF IT IS "SETUP PROFILE DATA" FORM
        if ( this.props.formType === "setup-profile-data" ) {

            requiredUserAccountDataObject = {
                password: null,
                firstName: null,
                lastName: null,
                phone: null,
            };

            noRequiredUserAccountDataObject = {
                address: null,
                state: null,
                zip: null,
                city: null
            };


        }


        // INIT STATE IF IT IS "UPDATE PROFILE DATA" FORM
        if ( this.props.formType === "update-profile-data" ) {

            let userAccountData = this.props.userAccountData;

            if (userAccountData) {

                requiredUserAccountDataObject = {
                    password: userAccountData.customer_password,
                    firstName: userAccountData.customer_first_name,
                    lastName: userAccountData.customer_last_name,
                    phone: userAccountData.customer_phone_number,
                };

                noRequiredUserAccountDataObject = {
                    address: userAccountData.customer_address,
                    state: userAccountData.customer_state,
                    zip: userAccountData.customer_zip_number,
                    city: userAccountData.customer_city
                };

            } else {

                requiredUserAccountDataObject = {
                    password: null,
                    firstName: null,
                    lastName: null,
                    phone: null,
                };

                noRequiredUserAccountDataObject = {
                    address: null,
                    state: null,
                    zip: null,
                    city: null
                };

            }
        }







        this.state = {
            type: 'password',
            email: "example@example.com",
            userAuthID: null,
            isFetchActive: false,
            isEmailForSetupLoad: false,
            requiredUserAccountDataObject: requiredUserAccountDataObject,
            noRequiredUserAccountDataObject: noRequiredUserAccountDataObject
        };

        this.showHidePassword = this.showHidePassword.bind(this);
        this.handleChangeFormRequiredData = this.handleChangeFormRequiredData.bind(this);
        this.handleChangeFormNoRequiredData = this.handleChangeFormNoRequiredData.bind(this);
        this.setupProfileData = this.setupProfileData.bind(this);
        this.updateProfileData = this.updateProfileData.bind(this);
        this.renderUserProfileFormButtons = this.renderUserProfileFormButtons.bind(this);
        this.renderUserProfileEmailInput = this.renderUserProfileEmailInput.bind(this);
        this.signUpProfileData = this.signUpProfileData.bind(this);
    }


    componentDidMount() {

        if ( this.props.formType === "setup-profile-data" ) {

            // відправляємо на wp hash і получаємо у відповідь email користувача для Setup

            const parsedUrlParameters = queryString.parse(this.props.location.search);

            if ( parsedUrlParameters.hash ) {

                let decryptedUserEmail = fetch('https://garagechamps.com/wp-json/quoteapi/get-userprofile', {
                    method: "POST",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        "user_hash": parsedUrlParameters.hash
                    })
                });

                decryptedUserEmail
                    .then((response) => {

                        return response.json();

                    })
                    .then((data) => {
                        //TODO: добавити лоавдер і перевірку
                        this.setState({
                            email: data.customer_email,
                            userAuthID: data.auth_id,
                            isEmailForSetupLoad: true
                        });

                    });


            }


        }

    }



    componentDidUpdate(prevProps) {

        if ( this.props.userAccountData !== prevProps.userAccountData ) {

            let userAccountData = this.props.userAccountData;

            if ( userAccountData ) {

                let requiredUserAccountDataObject = {
                    password: userAccountData.customer_password,
                    firstName: userAccountData.customer_first_name,
                    lastName: userAccountData.customer_last_name,
                    phone: userAccountData.customer_phone_number,
                };

                let noRequiredUserAccountDataObject = {
                    address: userAccountData.customer_address,
                    state: userAccountData.customer_state,
                    zip: userAccountData.customer_zip_number,
                    city: userAccountData.customer_city
                };


                this.setState({
                    email: userAccountData.customer_email,
                    userAuthID: userAccountData.auth_id,
                    requiredUserAccountDataObject: requiredUserAccountDataObject,
                    noRequiredUserAccountDataObject: noRequiredUserAccountDataObject
                });


            }


        }

    }



    showHidePassword(e){

        e.preventDefault();
        e.stopPropagation();
        this.setState({
            type: this.state.type === 'input' ? 'password' : 'input'
        })

    }



    handleChangeFormNoRequiredData(event) {

        let inputName;
        let inputValue;

        if ( event.target !== undefined ) {

            inputName = event.target.name;
            inputValue = event.target.value;

        } else {

            inputName = event.name;
            inputValue = event.value;

        }


        this.setState(prevState => ({
            noRequiredUserAccountDataObject: {
                ...prevState.noRequiredUserAccountDataObject,
                [`${inputName}`]: inputValue
            }
        }));

    }



    handleChangeFormRequiredData(event) {

        let inputName;
        let inputValue;
        let parentElementClassList;

        if ( event.target !== undefined ) {

            inputName = event.target.name;
            inputValue = event.target.value;
            parentElementClassList = event.target.parentElement.classList;

        } else {

            inputName = event.name;
            inputValue = event.value;
            parentElementClassList = event.parentElement.classList;
        }


            if ( inputValue.trim().length > 0 ) {

            parentElementClassList.add('successInputMessage');
            parentElementClassList.remove('errorInputMessage');

            if ( inputName === 'phone' ) {

                let phoneReg = /^[-()\s +/0-9]+$/;

                if ( phoneReg.test(String(inputValue).toLowerCase()) ) {

                    parentElementClassList.add('successInputMessage');
                    parentElementClassList.remove('wrongFormatMessage');

                } else {

                    parentElementClassList.add('wrongFormatMessage');
                    parentElementClassList.remove('successInputMessage');

                }

            }


            if ( inputName === 'password' ) {

                let uppercase = /[A-Z]/;
                let lowercase = /[a-z]/;
                let number = /[0-9]/;

                if ( inputValue.trim().length >= 8 && uppercase.test(inputValue) && lowercase.test(inputValue) && number.test(inputValue) ) {

                    parentElementClassList.add('successInputMessage');
                    parentElementClassList.remove('wrongFormatMessage');

                } else {

                    parentElementClassList.add('wrongFormatMessage');
                    parentElementClassList.remove('successInputMessage');

                }

            }


        } else {

            parentElementClassList.add('errorInputMessage');
            parentElementClassList.remove('successInputMessage', 'wrongFormatMessage');

            // scroll to error element
            $('body,html').animate(
                {
                    scrollTop: $('.errorInputMessage').offset().top - 200

                }, 0
            );


        }


        this.setState(prevState => ({
            requiredUserAccountDataObject: {
                ...prevState.requiredUserAccountDataObject,
                [`${inputName}`]: inputValue
            }
        }));

    }



    setupProfileData(event) {

        event.preventDefault();

        let formData = this.state.requiredUserAccountDataObject;
        let wrongInputsList = document.querySelectorAll('.wrongFormatMessage');
        let inputsNamesArray = Object.keys(this.state.requiredUserAccountDataObject);

        /* Біжимо циклом по усім input форми і викликаємо до кожного функцію handleChangeFormRequiredData() з перевіркою на стан кожного елемента,
           і закрашуємо у відповідні статуси. */
        inputsNamesArray.forEach(inputName => {

            this.handleChangeFormRequiredData(document.querySelectorAll('[name=' + inputName + ']')[0]);

        });


        if ( !formData.firstName || !formData.lastName || !formData.phone || !formData.password || wrongInputsList.length > 0 ) {

            console.log('Form validation error!');

        } else {

            // відправляємо setup дані до WP

            this.setState({ isFetchActive: true });

            let setupDataObject = { ...this.state.requiredUserAccountDataObject, ...this.state.noRequiredUserAccountDataObject };

            let setupDataFetch = fetch('https://garagechamps.com/wp-json/quoteapi/save-userprofile', {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "user_hash": sha256(this.state.email),
                    "userAuthID": this.state.userAuthID,
                    "setupDataObject": setupDataObject
                })
            });

            setupDataFetch
                .then((response) => {

                    if ( response.ok ) {

                        this.setState({ isFetchActive: false });
                        $('#accountIsAllSetModalButton')[0].click();

                    } else {

                        //TODO: добавити перевірки і лоадери

                    }

                });

        }


    }



    signUpProfileData(event) {

        event.preventDefault();

        let formData = this.state.requiredUserAccountDataObject;
        let wrongInputsList = document.querySelectorAll('.wrongFormatMessage');
        let inputsNamesArray = Object.keys(this.state.requiredUserAccountDataObject);

        /* Біжимо циклом по усім input форми і викликаємо до кожного функцію handleChangeFormRequiredData() з перевіркою на стан кожного елемента,
           і закрашуємо у відповідні статуси. */
        inputsNamesArray.forEach(inputName => {

            this.handleChangeFormRequiredData(document.querySelectorAll('[name=' + inputName + ']')[0]);

        });


        if (!formData.email || !formData.firstName || !formData.lastName || !formData.phone || !formData.password || wrongInputsList.length > 0) {

            console.log('Form validation error!');

        } else {

            // відправляємо signup дані до WP

            this.setState({ isFetchActive: true });

            let setupDataObject = { ...this.state.requiredUserAccountDataObject, ...this.state.noRequiredUserAccountDataObject };

            let setupDataFetch = fetch('https://garagechamps.com/wp-json/quoteapi/user-signup/', {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "user_hash": sha256(this.state.requiredUserAccountDataObject.email),
                    "userAuthID": this.state.userAuthID,
                    "setupDataObject": setupDataObject
                })
            });

            setupDataFetch
                .then((response) => {

                    if ( response.ok ) {

                        this.setState({ isFetchActive: false });
                        $('#accountIsAllSetModalButton')[0].click();

                    } else {

                        //TODO: добавити перевірки і лоадери

                    }

                });

        }


    }



    updateProfileData(event) {


        event.preventDefault();


        let formData = this.state.requiredUserAccountDataObject;
        let wrongInputsList = document.querySelectorAll('.wrongFormatMessage');
        let inputsNamesArray = Object.keys(this.state.requiredUserAccountDataObject);

        /* Біжимо циклом по усім input форми і викликаємо до кожного функцію handleChangeFormRequiredData() з перевіркою на стан кожного елемента,
           і закрашуємо у відповідні статуси. */
        inputsNamesArray.forEach(inputName => {

            this.handleChangeFormRequiredData(document.querySelectorAll('[name=' + inputName + ']')[0]);

        });


        if (!formData.firstName || !formData.lastName || !formData.phone || !formData.password || wrongInputsList.length > 0) {

            console.log('Form validation error!');

        } else {

            // відправляємо edit profile дані до WP
            this.setState({ isFetchActive: true });

            let setupDataObject = { ...this.state.requiredUserAccountDataObject, ...this.state.noRequiredUserAccountDataObject };

            let setupDataFetch = fetch('https://garagechamps.com/wp-json/quoteapi/save-userprofile', {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "user_hash": sha256(this.state.email),
                    "userAuthID": this.state.userAuthID,
                    "setupDataObject": setupDataObject
                })
            });

            setupDataFetch
                .then((response) => {

                    if ( response.ok ) {

                        this.setState({ isFetchActive: false });
                        this.props.onSaveUserAccountData(null);
                        $('#editProfileTypeModal')[0].click();

                    } else {

                        alert('Something went wrong. please try again later or contact us +1916 631 8470.');
                        //TODO: добавити перевірки і лоадери

                    }

                });

        }

    }



    renderUserProfileFormButtons () {


        switch ( this.props.formType ) {

            case 'setup-profile-data':

                return (

                    <div className="setupPageFormButtonContainer">
                        <button onClick={this.setupProfileData}>
                            {
                                ( this.state.isFetchActive )

                                    ?

                                        <div className="saveOrderOnEmailLoader">
                                            <Loader
                                                type="ThreeDots"
                                                color="#FFFFFF"
                                                height="30"
                                                width="30"
                                            />
                                        </div>

                                    : <>Save and proceed <span className="icon-arrow-big-right"></span></>
                            }
                        </button>
                    </div>

                );

            case 'update-profile-data':

                return (

                    <div className="setupPageFormButtonContainer editProfileButtonsBlock">
                        <span className="cancelEditingProfile" data-dismiss="modal">Cancel</span>
                        <button className="updateProfileDataButton" onClick={this.updateProfileData}>
                            {
                                ( this.state.isFetchActive )

                                    ?

                                    <div className="saveOrderOnEmailLoader">
                                        <Loader
                                            type="ThreeDots"
                                            color="#FFFFFF"
                                            height="30"
                                            width="30"
                                        />
                                    </div>

                                    : <>Update Profile <span className="icon-save"></span></>
                            }
                        </button>
                    </div>

                );

            default:

                return (

                    <div className="setupPageFormButtonContainer">
                        <button onClick={this.signUpProfileData}>
                            {
                                ( this.state.isFetchActive )

                                    ?

                                    <div className="saveOrderOnEmailLoader">
                                        <Loader
                                            type="ThreeDots"
                                            color="#FFFFFF"
                                            height="30"
                                            width="30"
                                        />
                                    </div>

                                    : <>Sign Up <span className="icon-arrow-big-right"></span></>
                            }
                        </button>
                    </div>

                );

        }



    }



    renderUserProfileEmailInput () {


        switch ( this.props.formType ) {

            case 'setup-profile-data':

                return (

                    <>
                        <div className="setupPageFormLabel read-only">Email:</div>
                        <div className="setupPageFormInput read-only-input">

                            { ( this.state.isEmailForSetupLoad)

                                ?

                                    <input
                                        value={ ( this.props.userAccountData ) ? this.state.email : this.state.email }
                                        data-tip="The email field can't be changed on your own. If you really need it - please contact us using support chat."
                                        data-for='readOnly'
                                        type="text" readOnly={true}
                                    />

                                :

                                    <input
                                        value="loading..."
                                        type="text" readOnly={true}
                                    />


                            }

                            <ReactTooltip id="readOnly" place="top" type="light" effect="float"/>
                        </div>
                    </>

                );

            case 'update-profile-data':

                return (

                    <>
                        <div className="setupPageFormLabel read-only">Email:</div>
                        <div className="setupPageFormInput read-only-input">

                            <input
                                value={ ( this.props.userAccountData ) ? this.state.email : this.state.email }
                                data-tip="The email field can't be changed on your own. If you really need it - please contact us using support chat."
                                data-for='readOnly'
                                type="text" readOnly={true}
                            />

                            <ReactTooltip id="readOnly" place="top" type="light" effect="float"/>
                        </div>
                    </>

                );

            default:

                return (

                    <>
                        <div className="setupPageFormLabel">Email:</div>
                        <div className="setupPageFormInput inputContainer">
                            <input
                                placeholder="example@example.com"
                                type="text"
                                onChange={this.handleChangeFormRequiredData}
                                name="email"
                            />

                            <div className="messageBox">
                                <span className="emptyFormatTitle">Required input</span>
                            </div>
                        </div>
                    </>

                );

        }



    }




    render() {

        return (

            <form className="setupPageForm">

                <div className="setupPageFormRow">

                    { this.renderUserProfileEmailInput() }

                </div>

                <div className="setupPageFormRow">
                    <div className="setupPageFormLabel">{( this.props.formType === "setup-profile-data" ) ? "Your new password:*" : "Password:*" }</div>
                    <div className="setupPageFormInput inputContainer passwordInputContainer">

                        <input
                            placeholder="password"
                            onChange={this.handleChangeFormRequiredData}
                            type={this.state.type}
                            name="password"
                            className="password__input"
                            defaultValue={ ( this.props.userAccountData ) ? this.state.requiredUserAccountDataObject.password : null }
                        />
                        {(this.state.requiredUserAccountDataObject.password)
                            ?
                            <button className="password__show" onClick={this.showHidePassword}>
                                {this.state.type === 'input' ? <span className="icon-eye-off"></span> : <span className="icon-eye"></span>}
                            </button>
                            :
                            null
                        }
                        <div className="messageBox passwordNotValid">
                            <span className="wrongFormatTitle">Password should have: at least 8 characters including a lower-case letter, an upper-case letter, and a number.</span>
                            <span className="emptyFormatTitle">Required input</span>
                        </div>

                    </div>
                </div>

                <div className="setupPageFormRow">
                    <div className="setupPageFormRowCol-6">
                        <div className="setupPageFormLabel">First Name:*</div>
                        <div className="setupPageFormInput inputContainer">
                            <input
                                onChange={this.handleChangeFormRequiredData}
                                defaultValue={ ( this.props.userAccountData ) ? this.state.requiredUserAccountDataObject.firstName : null }
                                placeholder="Sarah"
                                type="text"
                                name="firstName"
                            />
                            <div className="messageBox">
                                <span className="emptyFormatTitle">Required input</span>
                            </div>
                        </div>
                    </div>
                    <div className="setupPageFormRowCol-6">
                        <div className="setupPageFormLabel">Last name:*</div>
                        <div className="setupPageFormInput inputContainer">
                            <input
                                onChange={this.handleChangeFormRequiredData}
                                defaultValue={ ( this.props.userAccountData ) ? this.state.requiredUserAccountDataObject.lastName : null }
                                placeholder="Miller"
                                type="text"
                                name="lastName"
                            />
                            <div className="messageBox">
                                <span className="emptyFormatTitle">Required input</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="setupPageFormRow">
                    <div className="setupPageFormLabel">Phone number:*</div>
                    <div className="setupPageFormInput inputContainer">
                        <input
                            onChange={this.handleChangeFormRequiredData}
                            defaultValue={ ( this.props.userAccountData ) ? this.state.requiredUserAccountDataObject.phone : null }
                            placeholder="+ 1 503 45 76 238"
                            type="text"
                            name="phone"
                        />
                        <div className="messageBox">
                            <span className="icon-close"></span>
                            <span className="wrongFormatTitle">Wrong format</span>
                            <span className="emptyFormatTitle">Required input</span>
                        </div>
                    </div>
                </div>

                <div className="setupFormNonRequiredInputsContainer">


                    <div className="setupPageFormRow">
                        <div className="setupPageFormLabel">Address:</div>
                        <div className="setupPageFormInput inputContainer">
                            <input
                                onChange={this.handleChangeFormNoRequiredData}
                                defaultValue={ ( this.props.userAccountData ) ? this.state.noRequiredUserAccountDataObject.address : null }
                                placeholder="623 SW Alameda St."
                                type="text"
                                name="address"
                            />
                        </div>
                    </div>

                    <div className="setupPageFormRow">
                        <div className="setupPageFormLabel">State:</div>
                        <div className="setupPageFormInput inputContainer">
                            <input
                                onChange={this.handleChangeFormNoRequiredData}
                                defaultValue={ ( this.props.userAccountData ) ? this.state.noRequiredUserAccountDataObject.state : null }
                                placeholder="Oregon"
                                type="text"
                                name="state"
                            />
                        </div>
                    </div>

                    <div className="setupPageFormRow">
                        <div className="setupPageFormLabel">ZIP number:</div>
                        <div className="setupPageFormInput inputContainer">
                            <input
                                onChange={this.handleChangeFormNoRequiredData}
                                defaultValue={ ( this.props.userAccountData ) ? this.state.noRequiredUserAccountDataObject.zip : null }
                                placeholder="97212"
                                type="text"
                                name="zip"
                            />
                        </div>
                    </div>

                    <div className="setupPageFormRow">
                        <div className="setupPageFormLabel">City:</div>
                        <div className="setupPageFormInput inputContainer">
                            <input
                                onChange={this.handleChangeFormNoRequiredData}
                                defaultValue={ ( this.props.userAccountData ) ? this.state.noRequiredUserAccountDataObject.city : null }
                                placeholder="Portland"
                                type="text"
                                name="city"
                            />
                        </div>
                    </div>


                </div>

                { this.renderUserProfileFormButtons() }



            </form>

        )

    }


}

export default withRouter(UserProfileForm);

