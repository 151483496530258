import React from 'react';
import './App.css';
import StepNavigation from "./components/StepsNavigation/StepNavigation";



const App = ({children}) => (



    <div className="GarageChampsApp">

        <StepNavigation />

            <div className="stepBody">

                {children}

            </div>

        {/*<div className="technicalWorksContainer">*/}

        {/*    <div className="technicalWorksTextBlock">*/}
        {/*        <h1>Sorry, the mobile version is coming soon!</h1>*/}
        {/*        <p>На даний момент мобільна і планшетна версія в розробці, зайдіть з <strong>десктоп режиму</strong>*/}
        {/*            і перегоянте контент, він там зараз доволі патрясний. Або воверніться на лендінг і скорестайтесь*/}
        {/*            мінімізованим калькулятором.*/}
        {/*        </p>*/}
        {/*    </div>*/}

        {/*    <div className="animationBgArea">*/}
        {/*        <ul className="animationBgElementsList">*/}
        {/*            <li></li>*/}
        {/*            <li></li>*/}
        {/*            <li></li>*/}
        {/*            <li></li>*/}
        {/*            <li></li>*/}
        {/*            <li></li>*/}
        {/*            <li></li>*/}
        {/*            <li></li>*/}
        {/*            <li></li>*/}
        {/*            <li></li>*/}
        {/*        </ul>*/}
        {/*    </div>*/}

        {/*</div>*/}

    </div>

);



export default App;
