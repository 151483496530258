

    function evaluatePrintingPriceFormula(formula, totalProducts, side) {
        let Parser = require('expr-eval').Parser;
        return Parser.evaluate(
            formula,
            {x:totalProducts}
        ) / side;
    };


    /**
     * [getPrintingPricePerPieceFirstSide description]
     * @param  {[type]} inkColors    [description]
     * @param  {[type]} totalProducts [description]
     * @param  {[type]} side          Can be first or second. If it's the second - price will be divided by two.
     * @return {[type]}               [description]
     */

    export function getPrintingPricePerPiece(inkColors, totalProducts, side) {

        inkColors = parseInt(inkColors);

        switch(inkColors) {
            case 0:
                //if there's nothing to print - it'll cost nothing
                return 0;
            case 1:
                if (totalProducts < 70) {
                    return evaluatePrintingPriceFormula(
                        "2.22686*10^-16 * x^8 - 4.43957*10^-13 * x^7 + 3.049*10^-10 * x^6 - 9.93171*10^-8 * x^5 + 0.0000173745 * x^4 - 0.00170557 * x^3 + 0.0929919 * x^2 - 2.62523 * x + 34.6227",
                        totalProducts,
                        side
                    );
                }

                else if (totalProducts < 200)
                    return evaluatePrintingPriceFormula(
                        "22.17956 - 0.6675593*x + 0.009576846*x^2 - 0.00006863683*x^3 + 2.426374e-7*x^4 - 3.378997e-10*x^5",
                        totalProducts,
                        side
                    );
                else if (totalProducts < 400)
                    return evaluatePrintingPriceFormula(
                        "0.4129878 + (2149.611 - 0.4129878)/(1 + (x/3.872243e-7)^0.3403577)",
                        totalProducts,
                        side
                    );
                else if (totalProducts < 1000)
                    return evaluatePrintingPriceFormula(
                        "3.80952381*10^-7*x^2 - 9.325714286*10^-4*x + 2.556209905",
                        totalProducts,
                        side
                    );

            case 2 :
                if (totalProducts < 23) {
                    return evaluatePrintingPriceFormula(
                        "0.000694444*x^3 + 0.0152778*x^2 - 1.87569*x + 35.3597",
                        totalProducts,
                        side
                    );
                }
                if (totalProducts >= 23 && totalProducts < 1000) {
                    //4pl with data points 23 to 1000 was used
                    return evaluatePrintingPriceFormula(
                        "2.06453 + (9928891 - 2.06453)/(1 + (x/2.151351e-8)^0.6837926)",
                        totalProducts,
                        side
                    );
                }

            /**
             * problems noticed:
             * on 71 t-shirts the price is lower than we'd want to
             * on 143 too
             */

            case 3 :
                if (totalProducts < 12) {
                    return evaluatePrintingPriceFormula(
                        "40.04 - 1.74*x", //this ensures 6 prints cost 29.6 , 11 cost 20.9 per piece
                        totalProducts,
                        side
                    );
                }
                else if(totalProducts < 46)
                    return evaluatePrintingPriceFormula(
                        "6.214365 + (13514360 - 6.214365)/(1 + (x/0.002745848)^1.655045)",
                        totalProducts,
                        side
                    );
                else if (totalProducts >45 && totalProducts < 72)
                    return evaluatePrintingPriceFormula(
                        "9.1125 - 0.0375*x", //just linear
                        totalProducts,
                        side
                    );
                else if (totalProducts >71 && totalProducts < 100)
                    return evaluatePrintingPriceFormula(
                        "8.73214 - 0.0321429*x", //just linear
                        totalProducts,
                        side
                    );
                else
                    return evaluatePrintingPriceFormula(
                        "2.831524 + (7.258869 - 2.831524)/(1 + (x/133.107)^1.5909)",
                        totalProducts,
                        side
                    );


            case 4 :
                if (totalProducts < 12)
                    return evaluatePrintingPriceFormula(
                        "46.588 - 2.008*x", //ensuring 6 prints = 34.54$, 11 prints = 24.5$ per print
                        totalProducts,
                        side
                    );
                else if (totalProducts < 43)
                    return evaluatePrintingPriceFormula(
                        "6.683231 + (20420520 - 6.683231)/(1 + (x/0.001484119)^1.56576)",
                        totalProducts,
                        side
                    );
                else if (totalProducts < 71)
                    return evaluatePrintingPriceFormula(
                        "10.8042 - 0.0458333*x",
                        totalProducts,
                        side
                    );
                else if (totalProducts < 100)
                    return evaluatePrintingPriceFormula(
                        "10.3393 - 0.0392857*x",
                        totalProducts,
                        side
                    );
                else if (totalProducts < 1000)
                    return evaluatePrintingPriceFormula(
                        "3.377108 + (8.014303 - 3.377108)/(1 + (x/143.3121)^1.838905)",
                        totalProducts,
                        side
                    );


                return null;

            case 5 :
                if (totalProducts < 12)
                    return evaluatePrintingPriceFormula(
                        "52.294 - 2.254*x", //ensuring [6=38.77],[11=27.5]
                        totalProducts,
                        side
                    );
                else if (totalProducts < 24)
                    return evaluatePrintingPriceFormula(
                        "40.1042 - 1.14583*x",
                        totalProducts,
                        side
                    );
                else if (totalProducts < 48)
                    return evaluatePrintingPriceFormula(
                        "17.4875 - 0.1625*x",
                        totalProducts,
                        side
                    );
                else if (totalProducts < 72)
                    return evaluatePrintingPriceFormula(
                        "12.2979 - 0.0520833*x",
                        totalProducts,
                        side
                    );
                else if (totalProducts < 99)
                    return evaluatePrintingPriceFormula(
                        "11.7696 - 0.0446429*x",
                        totalProducts,
                        side
                    );
                else if (totalProducts >98)
                    return evaluatePrintingPriceFormula(
                        "3.837783 + (8.784894 - 3.837783)/(1 + (x/155.0416)^1.998746)",
                        totalProducts,
                        side
                    );

            case 6 :
                if (totalProducts < 12)
                    return evaluatePrintingPriceFormula(
                        "64.05 - 3.05*x", //ensuring [6;45.75],[11;30.5]
                        totalProducts,
                        side
                    );
                else if (totalProducts < 24)
                    return evaluatePrintingPriceFormula(
                        "44.4792 - 1.27083*x", // [11;30.5],[23;15.25]
                        totalProducts,
                        side
                    );
                else if (totalProducts < 48)
                    return evaluatePrintingPriceFormula(
                        "19.0833 - 0.166667*x",
                        totalProducts,
                        side
                    );
                else if (totalProducts < 72)
                    return evaluatePrintingPriceFormula(
                        "14.1875 - 0.0625*x",
                        totalProducts,
                        side
                    );
                else if (totalProducts < 99)
                    return evaluatePrintingPriceFormula(
                        "13.5536 - 0.0535714*x",
                        totalProducts,
                        side
                    );
                else if (totalProducts > 98)
                    return evaluatePrintingPriceFormula(
                        "4.048386 + (10.15153 - 4.048386)/(1 + (x/154.1843)^1.794834)",
                        totalProducts,
                        side
                    );

            default:
            {
                console.log('hit the default calculation case!');
                return null
            };
        }
    }


    export function getEmbroideryPricePerPiece(totalProducts) {
        if (totalProducts < 6) {
            return evaluatePrintingPriceFormula('41/2 - x/2', totalProducts, 1);
        }
        if (totalProducts < 13) {
            return evaluatePrintingPriceFormula('141/7 - (3*x)/7', totalProducts, 1);
        }
        if (totalProducts < 37) {
            return evaluatePrintingPriceFormula('18 - x/4', totalProducts, 1);
        }
        if (totalProducts < 49) {
            return evaluatePrintingPriceFormula('15 - x/6', totalProducts, 1);
        }
        if (totalProducts < 73) {
            return evaluatePrintingPriceFormula('9 - x/24', totalProducts, 1);
        }
        if (totalProducts < 97) {
            return evaluatePrintingPriceFormula('7.5 - 0.0208333*x', totalProducts, 1);
        }
        if (totalProducts < 145) {
            return evaluatePrintingPriceFormula('6.5 - 0.0104167*x', totalProducts, 1);
        }
        if (totalProducts < 205) {
            return evaluatePrintingPriceFormula('6.2 - 0.00833333*x', totalProducts, 1);
        }
        if (totalProducts < 10000) {
            return 4;
        }
    }


    export function calculateCurrentProductPriceOfAmountForPriceBreaks(amount, productBasePrice, quantityPerSizeObject, serviceType) {
        let totalOrderPrice = 0;

        if (serviceType === 'embroidery') {
            let currentEmbroideryPricePerPiece = getEmbroideryPricePerPiece(parseInt(amount));

            totalOrderPrice = parseFloat(productBasePrice) * parseInt(amount) +
                currentEmbroideryPricePerPiece * parseInt(amount)
                +30; //30$ is a fixed set up fee for embroidery
        } else {
            //otherwise the service type is currently "screen printing"
            //get to know what values user put for ink colors for all the sides
            let inkColorsPerSideArray = [];

            for (let key in quantityPerSizeObject) {
                if (quantityPerSizeObject.hasOwnProperty(key)) {
                    inkColorsPerSideArray.push(quantityPerSizeObject[key]);
                }
            }


            //10.12 in the first release we only do calculation for frontside and backside printing
            let product_front_colors_amount = inkColorsPerSideArray[0];
            let product_back_colors_amount = inkColorsPerSideArray[1];

            let moreColorsSideNumber;
            let lessColorsSideNumber;

            if (product_front_colors_amount>product_back_colors_amount) {
                moreColorsSideNumber = product_front_colors_amount;
                lessColorsSideNumber = product_back_colors_amount;
            } else {
                moreColorsSideNumber = product_back_colors_amount;
                lessColorsSideNumber = product_front_colors_amount;
            }
            //TODO: extend the code here if there'll be more print sides (sleeves?)


            let printingPricePerPieceFirstSide = getPrintingPricePerPiece(moreColorsSideNumber, amount, 1);

            let printingPricePerPieceSecondSide = getPrintingPricePerPiece(lessColorsSideNumber, amount, 2);

            //TODO: floor to bigger number?

            //pseudo formula: product_price*amount + printingPriceFirstSide*amount + printingPriceSecondSide*amount;


            //todo: since now we do adjustment per size, productBasePrice

            totalOrderPrice = parseFloat(productBasePrice) * parseInt(amount) +
                printingPricePerPieceFirstSide * parseInt(amount) +
                printingPricePerPieceSecondSide * parseInt(amount) ;



        }


        return ( totalOrderPrice / amount ).toFixed(2);

    }




    export function getCategoryIcon(itemCategoryName) {

        switch (itemCategoryName) {
            case 'Most popular' :

                return 'icon-fire';

            case 'T-Shirts' :

                return 'icon-t-shirt';

            case 'Performance/Activewear' :

                return 'icon-tank-tops';

            case 'Sweatshirts' :

                return 'icon-sweatshirt';

            case 'Women\'s' :

                return 'icon-bags';

            case 'Youth' :

                return 'icon-hats';

            default:

                return 'icon-fire';
        }

    }





    export function generateUserProfileAvatar(user, userAccountData) {


        if ( user.picture.includes("gravatar") ) {

            if ( userAccountData ) {

                let userFullNameStr = userAccountData.customer_first_name + ' ' + userAccountData.customer_last_name;
                let userFullNameAcronymArray = userFullNameStr.toLowerCase().match(/\b(\w)/g);
                let userFullNameAcronymStr = userFullNameAcronymArray.join('');

                return  "https://i1.wp.com/cdn.auth0.com/avatars/" + userFullNameAcronymStr + ".png?ssl=1";

            }



        } else {

            return user.picture;

        }

    }
