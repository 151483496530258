import React, { Component } from 'react';
import './StepSelectProduct.css';
import { connect } from "react-redux";
import dollar from "../../../images/icons/dollar.svg";
import { StickyContainer, Sticky } from 'react-sticky';
import { getCategoryIcon } from '../../../Helpers';
import {Link, withRouter} from 'react-router-dom';
import { compose } from 'recompose';

import $ from "jquery";

import { addProductForCalculation, fetchProducts } from "../../../actions/actions";
import CategoriesAPILoader from "./CategoriesAPILoader/CategoriesAPILoader";
import {Helmet} from "react-helmet/es/Helmet";

const queryString = require('query-string');

// приставка до url для локального тестування
// const linkToImages = process.env.PUBLIC_URL + '/products/t-shirts/';

class StepSelectProduct extends Component {

    constructor(props) {

        super(props);
        this.state = {
            showAllProductList: false,
            showAllProductArray: []
        };

        this.selectProduct = this.selectProduct.bind(this);
        this.onClickShowAllProductList = this.onClickShowAllProductList.bind(this);
        this.onClickHideAllProductList = this.onClickHideAllProductList.bind(this);
        this.applyFilterForProductsList = this.applyFilterForProductsList.bind(this);
        this.replaceUrlOnCdnVersion = this.replaceUrlOnCdnVersion.bind(this);

    }


    componentDidMount() {

        this.props.onFetchProducts();

        this.dollarAnimationAfterRender();

        // add event listener by window scroll
        window.addEventListener('scroll', this.handleScroll, true);

        // update active category on navigation
        this.updateNavigation();

    }


    componentDidUpdate(prevProps) {

        if (this.props.productObjectFromAPI !== prevProps.productObjectFromAPI) {

            // init dollar animation after fetch data from API
            this.dollarAnimationAfterRender();

            // update active category on navigation
            this.updateNavigation();

            // scroll to top after fetch data from API
            window.scrollTo(0, 0);

        }

    }


    handleScroll = () => {

        this.updateNavigation();

    };


    updateNavigation() {

        let contentSections = $('.cd-section');
        let navigationItems = $('#cd-vertical-nav a');

        contentSections.each( function() {
            let then = $(this);
            let activeSection = $('#cd-vertical-nav a[href="#'+then.attr('id')+'"]').data('number') - 1;

            // if ( then.offset().top ) {
            if ( ( then.offset().top - $(window).height()/2 < $(window).scrollTop() ) && ( then.offset().top + then.height() - $(window).height()/2 > $(window).scrollTop() ) ) {
                navigationItems.eq(activeSection).addClass('is-selected');
            } else {
                navigationItems.eq(activeSection).removeClass('is-selected');
            }

        });
    }


    smoothScrollToCategory(itemName, e) {

        e.preventDefault();

        $('body,html').animate(
            {
                scrollTop: $('#'+itemName).offset().top - 140

            }, 0
        );

    }


    selectProduct(product_id, itemCategoryName) {

        this.props.productObjectFromAPI[itemCategoryName].map( (product) => {

            if ( product.product_meta.product_id === product_id ) {


                let newProductObjectForCalculation = {
                //     // product_app_id: Math.floor(Math.random() * Math.floor(99999)),
                    product_object: product.product_meta
                };

                //add product slug inside of object
                newProductObjectForCalculation.product_object.slug = product.slug;

                this.props.onAddProductForCalculation(newProductObjectForCalculation);

            }

            return null;

        });

    }


    onClickShowAllProductList(categoryItemName) {

        // this.setState({
        //     showAllProductList: true
        // });

        if (!this.state.showAllProductArray.includes(categoryItemName)) {

            this.setState(state => {

                const showAllProductArray = state.showAllProductArray.concat(categoryItemName);

                return {
                    showAllProductArray
                };

            });

        }

    }


    onClickHideAllProductList(categoryItemName) {

        // this.setState({
        //     showAllProductList: false
        // });

        this.setState(state => {

            const showAllProductArray = state.showAllProductArray.filter(item => categoryItemName !== item);

            return {
                showAllProductArray
            };

        });

    }


    dollarAnimationAfterRender() {

        setTimeout(function () {

            let dollarTagsList = document.querySelectorAll('.colorFillBlock[dollars-value]');

            for (let i = 0; i < dollarTagsList.length; i++) {

                dollarTagsList[i].getAttribute('dollars-value');
                dollarTagsList[i].setAttribute('style', 'width:' + dollarTagsList[i].getAttribute('dollars-value'));

            }

        }, 500);

    }


    replaceUrlOnCdnVersion(oldUrlVersion) {

        return oldUrlVersion;

    }


    renderProductsList(itemCategoryName, itemCategoryArray) {


        return itemCategoryArray.map((product, index) => {

                return (

                        <div className="col-3" key={index}>

                            <Link to={`product/${product.slug}`} >

                                <div className="productItem" onClick={this.selectProduct.bind(null, product.product_meta.product_id, itemCategoryName)}>

                                    <div className="productItemImageContainer">

                                        { (itemCategoryName === 'Most popular') ? <span className="icon-fire"></span> : null }

                                        <img className="frontImage" src={ this.replaceUrlOnCdnVersion(product.product_meta.product_image) } alt="Garge Champs" />
                                        <img className="backImage" src={ this.replaceUrlOnCdnVersion(product.product_meta.product_image_back) } alt="Garge Champs" />

                                    </div>

                                    <div className="productItemBody">

                                        <h5 className="productItemName">{ product.product_meta.product_name }</h5>
                                        <p className="productItemInfo">
                                            {/*style={{width: "50%"}}*/}
                                            <span className="dollarVisualizationContainer">
                                                <span className="colorFillBlock" dollars-value={`${product.product_meta.product_pricing_category}%`} ></span>
                                                <img src={dollar} alt="price indicator" />
                                            </span>

                                        | { product.product_meta.available_color_styles.length }
                                        &nbsp;color{product.product_meta.available_color_styles.length==1?'':'s'}</p>
                                        <button className="productItemButtonSelect">Select</button>

                                    </div>
                                </div>

                            </Link>

                        </div>

                )

        });



    }


    renderProductsCategoryList() {


        if (this.props.productObjectFromAPI) {

            let filteredProductsObject;
            let productsObjectFromAPI = this.props.productObjectFromAPI;
            const parsedUrlParameters = queryString.parse(this.props.location.search);

            // ЯКЩО В URL Є ПАРАМЕТРИ ДЛЯ ФІЛЬТРУВАННЯ ПО КАТЕГОРІЯМ , ТО СЛІД ЗАСТОВУВАТИ ФІЛЬТАЦІЮ ДЛЯ ОСНОВНОГО ОБ*ЄКАТ ПРОДУКТІВ
            if ( parsedUrlParameters.category ) {

                const allowedParametersForFiltering = parsedUrlParameters.category;

                filteredProductsObject = Object.keys(productsObjectFromAPI)
                    .filter(key => allowedParametersForFiltering.includes(key))
                    .reduce((obj, key) => {
                        obj[key] = productsObjectFromAPI[key];
                        return obj;
                    }, {});


            } else {

                filteredProductsObject = this.props.productObjectFromAPI;

            }



            return Object.entries(filteredProductsObject).map((item, index) => {

                let itemCategoryName = item[0];
                let itemCategoryArray = item[1];

                if (itemCategoryArray.length > 0) {

                    return (

                        <div className="productCategoryRow row cd-section" categoty-name={itemCategoryName} key={index} id={`section${index + 1}`}>

                            <div className="productCategoryRowContainer col-12">

                                <div className={`productCategoryRowTitle ${(itemCategoryName === 'Most popular') ? 'productCategoryHot' : null}`}>

                                    <div className="productCategoryTitle">
                                        <span className="icon-fire"></span>{itemCategoryName}
                                        <span className="categoryItemsLengthValue">{`${itemCategoryArray.length} items`}</span>
                                    </div>


                                    <div
                                        className={`productCategoryRowHideButton ${(!this.state.showAllProductArray.includes(itemCategoryName)) ? 'productCategoryRowHideButton productCategoryRowHideButtonHide' : null}`}
                                        onClick={this.onClickHideAllProductList.bind(this, itemCategoryName)}>
                                        <span className="icon-minus"></span> Hide
                                    </div>

                                </div>
                            </div>

                            <div
                                className={`productList col-10 ${(this.state.showAllProductArray.includes(itemCategoryName)) ? 'showAllProductList' : null}`}>

                                <div className="row">

                                    {this.renderProductsList(itemCategoryName, itemCategoryArray)}

                                </div>

                            </div>

                            <div className="showAllProductButtonContainer col-2">
                                <div
                                    className={`showAllProductButton ${(!this.props.productObjectFromAPI || itemCategoryArray.length <= 4) ? "showAllProductButtonHidden" : null}`}
                                    onClick={(this.props.productObjectFromAPI && itemCategoryArray.length > 4) ? this.onClickShowAllProductList.bind(this, itemCategoryName) : null}>
                                    <div className="showAllProductButtonBody">
                                        <span className="icon-plus"></span>
                                        Show All
                                        <span className="icon-arrow-right"></span>
                                    </div>
                                </div>
                            </div>

                        </div>

                    )

                }

            });

        } else {

            return (

                <CategoriesAPILoader />

            )

        }


    }


    renderCategoriesNavigation() {


        if (this.props.productObjectFromAPI) {


            return Object.entries(this.props.productObjectFromAPI).map((item, index) => {

                let itemCategoryName = item[0];

                return (

                    <li key={index}>
                        <a href={`#section${index+1}`} onClick={this.smoothScrollToCategory.bind(this, 'section'+(index+1))} data-number={index+1}>
                            <span className={ getCategoryIcon(itemCategoryName) }> </span>
                            <span className="cd-label">{ itemCategoryName }</span>
                        </a>
                    </li>

                )

            });

        }


    }



    applyFilterForProductsList(event) {

        console.log(event.target.name);
        console.log(event.target.checked);



    }










    render() {

        return (

            <StickyContainer className="stepSelectProduct">

                <Helmet>
                    <title>Select Product | GarageChamps</title>
                    <meta name="description" content="GarageChamps App" />
                </Helmet>

                <div className="container">

                    <div className="row">

                        {/*<div className="col-2 filtersPanel">*/}

                        {/*    <div className="filterTypeBlock">*/}
                        {/*        <div className="filterTypeName">CATEGORY</div>*/}

                        {/*        <ul className="filterTypeList">*/}

                        {/*            <ul>*/}
                        {/*                <li>*/}
                        {/*                    <input onClick={this.applyFilterForProductsList} name="T-Shirts" className="styled-checkbox" id="styled-checkbox-1" type="checkbox"*/}
                        {/*                           value="value1" />*/}
                        {/*                        <label htmlFor="styled-checkbox-1">T-Shirts</label>*/}
                        {/*                </li>*/}
                        {/*                <li>*/}
                        {/*                    <input onClick={this.applyFilterForProductsList} name="Sweatshirt" className="styled-checkbox" id="styled-checkbox-2" type="checkbox"*/}
                        {/*                           value="value2" />*/}
                        {/*                        <label htmlFor="styled-checkbox-2">Sweatshirt</label>*/}
                        {/*                </li>*/}
                        {/*                <li>*/}
                        {/*                    <input className="styled-checkbox" id="styled-checkbox-3" type="checkbox"*/}
                        {/*                           value="value3"  />*/}
                        {/*                        <label htmlFor="styled-checkbox-3">Bags</label>*/}
                        {/*                </li>*/}
                        {/*                <li>*/}
                        {/*                    <input className="styled-checkbox" id="styled-checkbox-4" type="checkbox"*/}
                        {/*                           value="value4" />*/}
                        {/*                        <label htmlFor="styled-checkbox-4">Hats</label>*/}
                        {/*                </li>*/}
                        {/*                <li>*/}
                        {/*                    <input className="styled-checkbox" id="styled-checkbox-5" type="checkbox"*/}
                        {/*                           value="value4" />*/}
                        {/*                    <label htmlFor="styled-checkbox-5">Polo Shirts</label>*/}
                        {/*                </li>*/}
                        {/*                <li>*/}
                        {/*                    <input className="styled-checkbox" id="styled-checkbox-6" type="checkbox"*/}
                        {/*                           value="value4" />*/}
                        {/*                    <label htmlFor="styled-checkbox-6">Tank Tops</label>*/}
                        {/*                </li>*/}
                        {/*            </ul>*/}

                        {/*        </ul>*/}

                        {/*    </div>*/}

                        {/*</div>*/}


                        <div className="col-12 productCategoryRowList">
                            { this.renderProductsCategoryList() }
                        </div>

                    </div>


                </div>

            </StickyContainer>

        )

    }


}

export default compose(
    withRouter,
    connect(
        state => ({
            productObjectFromAPI: state.product_option.product_object_from_API
        }),
        dispatch => ({
            onAddProductForCalculation: (productObject) => {
                dispatch(addProductForCalculation(productObject));
            },
            onFetchProducts: () => {
                dispatch(fetchProducts());
            }
        })
    )
)(StepSelectProduct);
