import { combineReducers } from 'redux';
import { product_reducer } from "./product_reducer";
import { cart_reducer } from "./cart_reducer";
import { delivering_reducer } from "./delivering_reducer";
import { order_reducer } from "./order_reducer";
import { errors_reducer } from "./errors_reducer";
import { user_account_reducer } from "./user_account_reducer";


export default combineReducers ({
    errors_log: errors_reducer,
    user_account: user_account_reducer,
    delivering_options: delivering_reducer,
    product_option: product_reducer,
    order_option: order_reducer,
    cart: cart_reducer
});
