//actions types
export const ADD_PRODUCT_FOR_CALCULATION = 'ADD_PRODUCT_FOR_CALCULATION';
export const SAVE_PRODUCT_AFTER_EDITING = 'SAVE_PRODUCT_AFTER_EDITING';
export const CANCEL_EDITING_PRODUCT = 'CANCEL_EDITING_PRODUCT';

export const ADD_PRODUCT_TO_CART = 'ADD_PRODUCT_TO_CART';
export const DELETE_PRODUCT_FROM_CART = 'DELETE_PRODUCT_FROM_CART';
export const EDIT_PRODUCT_FROM_CART = 'EDIT_PRODUCT_FROM_CART';
export const RESET_CART_LIST = 'RESET_CART_LIST';

export const UPDATE_ARRAYS_UPLOADED_FILES = 'UPDATE_ARRAYS_UPLOADED_FILES';
export const UPDATE_OBJECT_USER_ORDER_DATA = 'UPDATE_OBJECT_USER_ORDER_DATA';
export const CHANGE_DELIVERING_TYPE = 'CHANGE_DELIVERING_TYPE';

export const FETCH_PRODUCTS = 'FETCH_PRODUCTS';
export const FETCH_SINGLE_PRODUCT = 'FETCH_SINGLE_PRODUCT';
export const IS_FAIL_FETCH_SINGLE_PRODUCT = 'IS_FAIL_FETCH_SINGLE_PRODUCT';

export const SAVE_USER_ACCOUNT_DATA = 'SAVE_USER_ACCOUNT_DATA';

// actions creators
export function addProductForCalculation (productObject) {

    return {
        type: ADD_PRODUCT_FOR_CALCULATION,
        payload: productObject
    }

}


export function addToCart (productObject) {

    return {
        type: ADD_PRODUCT_TO_CART,
        payload: productObject
    }

}


export function editProductFromCart (dataForEditingProductFromCart) {

    return {
        type: EDIT_PRODUCT_FROM_CART,
        payload: dataForEditingProductFromCart
    }

}


export function deleteProductFromCart (newCartList) {

    return {
        type: DELETE_PRODUCT_FROM_CART,
        payload: newCartList
    }

}


export function resetCartList () {

    return {
        type: RESET_CART_LIST
    }

}


export function saveProductAfterEditing (newCartList) {

    return {
        type: SAVE_PRODUCT_AFTER_EDITING,
        payload: newCartList
    }

}


export function cancelEditingProduct () {

    return {
        type: CANCEL_EDITING_PRODUCT
    }

}


export function changeDeliveringType (newDeliveringTypeObject) {

    return {
        type: CHANGE_DELIVERING_TYPE,
        payload: newDeliveringTypeObject
    }

}


export function updateArraysUploadedFiles (newUploadedFilesArray) {

    return {
        type: UPDATE_ARRAYS_UPLOADED_FILES,
        payload: newUploadedFilesArray
    }

}


export function updateObjectUserOrderData (newUserOrderDataObject) {

    return {
        type: UPDATE_OBJECT_USER_ORDER_DATA,
        payload: newUserOrderDataObject
    }

}



export function saveUserAccountData (newUserAccountDataObject) {

    return {
        type: SAVE_USER_ACCOUNT_DATA,
        payload: newUserAccountDataObject
    }

}








// saving single product to Redux Store

export function saveSingleProductObjectFromAPI (json) {

    return {
        type: FETCH_SINGLE_PRODUCT,
        payload: json
    }

}

// if fetch single product is fail
export function isFailFetchSingleProduct (boolean) {

    return {
        type: IS_FAIL_FETCH_SINGLE_PRODUCT,
        payload: boolean
    }

}

// connection with WordPress API, and get single product JSON

export function fetchSingleProduct(slug) {


    return dispatch => {

        // let productObject = fetch("https://garagechamps.com/dev-only-json-endpoint/", {
        let productObject = fetch("https://garagechamps.com/products/"+slug, {
            method: "POST",
            headers: {
                // 'Accept': 'application/json',
                // 'Content-Type': 'application/json'
            },
            // body: JSON.stringify({text: dataForFetchingLesson.processTextValue, inputLanguage: "EN"})
        });



        productObject
            .then(response => {

                return response.json();

            })
            .then(json => {

                let newProductObjectForCalculation = {
                    product_object: json.product_meta
                };

                //add product slug inside of object
                newProductObjectForCalculation.product_object.slug = json.slug;

                return dispatch(saveSingleProductObjectFromAPI(newProductObjectForCalculation));

            })
            .catch(function(error) {

                return dispatch(isFailFetchSingleProduct(true));

            });

    }

}

// saving products list with categories to Redux Store

export function saveProductsObjectFromAPI (json) {

    return {
        type: FETCH_PRODUCTS,
        payload: json
    }

}


// connection with WordPress API, and getting products list with categories JSON

export function fetchProducts(dataForFetchingLesson) {

    return dispatch => {

        // let productObject = fetch("https://garagechamps.com/dev-only-json-endpoint/", {
        let productObject = fetch("https://garagechamps.com/dev-only-json-endpoint", {
            method: "POST",
            headers: {
                // 'Accept': 'application/json',
                // 'Content-Type': 'application/json'
            },
            // body: JSON.stringify({text: dataForFetchingLesson.processTextValue, inputLanguage: "EN"})
        });



        productObject
            .then(response => {

                return response.json();

            })
            .then(json => {

                console.log(json);

                return [
                    dispatch(isFailFetchSingleProduct(false)),
                    dispatch(saveProductsObjectFromAPI(json))
                ]

            });

    }

}
