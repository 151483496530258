import React, { Component } from 'react';
import ReactTooltip from "react-tooltip";
import Select, { components } from 'react-select';
import Switch from "react-switch";


// INK COLORS ARRAY
const INKS_COLORS_ARRAY = [
    { color_name: "Yellow #2", color_hex: '#FBD603' },
    { color_name: "Ucd Gold", color_hex: '#D8AC29' },
    { color_name: "Yellow #1", color_hex: '#FEC844' },
    { color_name: "Bright Gold", color_hex: '#FCB525' },
    { color_name: "Gold", color_hex: '#FAA21C' },
    { color_name: "Spice Brown", color_hex: '#956944' },
    { color_name: "Bright Orange", color_hex: '#EF4129' },

    { color_name: "Light Orange", color_hex: '#F26829' },
    { color_name: "Team Orange", color_hex: '#C3512F' },
    { color_name: "Red", color_hex: '#D8292E' },
    { color_name: "Scarlet", color_hex: '#CF202F' },
    { color_name: "Cardinal", color_hex: '#A42037' },
    { color_name: "Burgundy", color_hex: '#572A31' },
    { color_name: "Fuchsia", color_hex: '#A51F68' },

    { color_name: "Aurora Pink", color_hex: '#F0609E' },
    { color_name: "Cool Pink", color_hex: '#EB96C0' },
    { color_name: "Team Violet", color_hex: '#7765A1' },
    { color_name: "Purple", color_hex: '#4D4183' },
    { color_name: "Light Royal", color_hex: '#4C5FAB' },
    { color_name: "Royal", color_hex: '#1F3D7B' },
    { color_name: "Dark Navy", color_hex: '#0A1B2B' },

    { color_name: "Light Navy", color_hex: '#122047' },
    { color_name: "University Blue", color_hex: '#8CB5E1' },
    { color_name: "Light Blue", color_hex: '#3DB3E3' },
    { color_name: "UCD Blue", color_hex: '#004E7E' },
    { color_name: "Bright Blue", color_hex: '#0076BC' },
    { color_name: "Turqal", color_hex: '#01AD9F' },
    { color_name: "Turquoise", color_hex: '#006171' },

    { color_name: "SS Green", color_hex: '#115641' },
    { color_name: "Forest Green", color_hex: '#016241' },
    { color_name: "Dallas Green", color_hex: '#00984D' },
    { color_name: "Light Green", color_hex: '#6EBE4B' },
    { color_name: "Mint", color_hex: '#71C498' },
    { color_name: "Vegas Gold", color_hex: '#D9CE96' },
    { color_name: "SS Gold", color_hex: '#C7B783' },

    { color_name: "Dark Brown", color_hex: '#5E462C' },
    { color_name: "Flesh", color_hex: '#DBB9A0' },
    { color_name: "Black", color_hex: '#000000' },
    { color_name: "Charcoal", color_hex: '#56555A' },
    { color_name: "Grey", color_hex: '#888C8D' },
    { color_name: "Light Grey", color_hex: '#A1A9AC' },
    { color_name: "White", color_hex: '#FFFFFF' },
];


// SCREEN PRINTING ART WIDTH OPTIONS
const SCREEN_PRINTING_ART_WIDTH_OPTIONS = [
    // { value: 0, label: '0' },
    { value: 0, label: '3”' },
    { value: 1, label: '4” Standard left chest placement' },
    { value: 2, label: '5”' },
    { value: 3, label: '6”' },
    { value: 4, label: '7”' },
    { value: 5, label: '8” Standard full front placement' },
    { value: 6, label: '9”' },
    { value: 7, label: '10”' },
    { value: 8, label: '11”' },
];

const customStyles = {
    option: (provided, state) => ({}),
    control: (provided, state) => ({
        backgroundColor: '#ffffff'
    }),
    menu: (provided, state) => ({
        ...provided,
        width: 160
    }),
    indicatorSeparator: (provided, state) => ({
        display: 'none'
    }),
    valueContainer: (provided, state) => ({}),
    indicatorsContainer: (provided, state) => ({})
}


const DropdownIndicator = props => {
    return (
        <components.DropdownIndicator {...props}>
            <span className="icon icon-arrow-for-select"></span>
        </components.DropdownIndicator>
    );
};




// SCREEN PRINTING INKS COLORS ARRAY OPTIONS
const SCREEN_PRINTING_INKS_COLORS_ARRAY_OPTIONS = [
    { value: "Abe", label: "Abe", customAbbreviation: "A" },
    { value: "John", label: "John", customAbbreviation: "J" },
    { value: "Dustin", label: "Dustin", customAbbreviation: "D" }
];

// const inksColorsArrayFormatOptionLabel = ({ value, label, customAbbreviation }) => (
//     <div style={{ display: "flex" }}>
//         <div>{label}</div>
//         <div style={{ marginLeft: "10px", color: "#ccc" }}>
//             {customAbbreviation}
//         </div>
//     </div>
// );










class ProductSidesPanel extends Component {


    constructor(props) {

        super(props);

        this.state = {
            activeInkColorDropdownObject: {
                isOpen: false,
                side_name: null,
                array_position: null,
                current_color_hex: null,
                dropdown_position_x: null,
                dropdown_position_y: null
            }
        }


        this.onChangeItemInkColorBySide = this.onChangeItemInkColorBySide.bind(this);

    }



    showInksColorsPaletteDropdown = ( side_name, current_color_hex, array_position) => e => {

        // let stickyContainer = document.getElementsByClassName("icon-printing");
        // console.log(stickyContainer[0].getBoundingClientRect());

        let dropdown_position_x = e.clientX;
        let dropdown_position_y = e.clientY;

        this.setState(prevState => ({
            activeInkColorDropdownObject: {
                // ...prevState.activeInkColorDropdownObject,
                isOpen: true,
                side_name: side_name,
                current_color_hex: current_color_hex,
                array_position: array_position,
                dropdown_position_x : dropdown_position_x,
                dropdown_position_y: dropdown_position_y
            }
        }));

    }



    renderInksColorsPaletteDropdownList() {

        return INKS_COLORS_ARRAY.map(( item, index ) => {

            return (

                <button
                    key={index}
                    style={{borderColor: item.color_hex}}
                    title={item.color_name}
                    className={`inksColorsPaletteDropdownItem ${( this.state.activeInkColorDropdownObject.current_color_hex === item.color_hex) && "is-active" }`}
                    onClick={this.onChangeItemInkColorBySide.bind(null, item)}
                >
                    <span style={{backgroundColor: item.color_hex}}></span>
                </button>

            )


        });

    }



    onChangeItemInkColorBySide(item) {

        this.props.changeItemInkColorBySide(item.color_name, item.color_hex, this.state.activeInkColorDropdownObject.side_name, this.state.activeInkColorDropdownObject.array_position);

        this.setState(prevState => ({
            activeInkColorDropdownObject: {
                ...prevState.activeInkColorDropdownObject,
                isOpen: false
            }
        }));

    }



    renderInksColorsArrayBySide(side_name) {

        if ( this.props.advancedSettingsObject.inksColorsObjectBySide ) {

            return this.props.advancedSettingsObject.inksColorsObjectBySide[side_name].map((item, index) => {

                if ( item.enabled ) {

                    return (

                        <li key={index}>
                            <button
                                title={item.colorName}
                                style={{backgroundColor: item.colorHEX, borderColor: item.colorHEX}}
                                onClick={this.showInksColorsPaletteDropdown( side_name, item.colorHEX, index )}
                                className="inksColorsPaletteDropdownButton is-enabled"
                            >{index+1}</button>
                        </li>

                    )

                } else {

                    return (

                        <li key={index}>
                            <button
                                onClick={this.showInksColorsPaletteDropdown( side_name, item.colorHEX, index )}
                                className="inksColorsPaletteDropdownButton"
                            >{index+1}</button>
                        </li>

                    )

                }

            });

        }

    }



    getDefaultArtWidthValueBySide(side_name) {

        let defaultValue;
        let defaultValueObject = this.props.advancedSettingsObject.artsWidthsObjectBySide[side_name];

        if ( defaultValueObject ) {

            defaultValue = SCREEN_PRINTING_ART_WIDTH_OPTIONS[defaultValueObject.value];

        } else {

            defaultValue = null;

        }

        return defaultValue;

    }



    render() {

        // initialize content version type by screen width
        const screenWidth = window.innerWidth;
        const isMobileVersion = screenWidth < 992;



        return (

            <div className={`productSidesPanel ${ this.props.isAdvancedSettingsActive && "advanced-settings-is-active" }`}>

                <div className="servicesTypeRadioButtons">  {/*цей клас наразі задісплейнутий в css*/}
                    <button
                        onClick={this.props.changeActiveServiceType.bind(null, 'screen printing')}
                        className={`serviceTypeButton printingType ${(this.props.activeServiceType === 'screen printing') ? 'activeType' : null}`}>
                        <span className="icon-printing"></span>Screen Printing
                    </button>
                    <button
                        // data-tip="Embroidery is coming soon."
                        // data-for='comingSoon'
                        onClick={this.props.changeActiveServiceType.bind(null, 'embroidery')}
                        className={`serviceTypeButton embroideryType ${(this.props.activeServiceType === 'embroidery') ? 'activeType' : null}`}>
                        <span className="icon-embroidery"></span>Embroidery

                        {/*<ReactTooltip id="comingSoon" place="top" type="light" effect="float"/>*/}
                    </button>

                    <div className="advancedSettingsButton">
                        Advanced details
                        <Switch
                            checked={this.props.isAdvancedSettingsActive}
                            onChange={this.props.onSwitchAdvancedSettings}
                            onColor="#FFFFFF"
                            offColor="#FFFFFF"
                            onHandleColor="#EB158D"
                            // offHandleColor="#BDCAD8"
                            handleDiameter={8}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            height={16}
                            width={27}
                            className="advanced-settings-switch"
                        />
                    </div>

                </div>


                <div className="row">

                    <div className="col-12 singleProductSidesOptionsTable spr-panel">

                        <table className="table table-hover">
                            <thead>
                                <tr>

                                    <th>Location:</th>
                                    <th>Artworks:</th>

                                    { (this.props.activeServiceType === 'screen printing') ?
                                        <th className={(!this.props.colorsAmountValidation()) ? 'notValidInc' : null}>
                                            <span>
                                                { (!isMobileVersion) ? "Number of Ink Colors:" : "Ink colors:" }
                                                <div className="setInkNumberMessageBox message-box-style">
                                                    Set the number of Ink Colors to calculate the price
                                                </div>
                                            </span>
                                        </th>
                                    : null }

                                    { this.props.isAdvancedSettingsActive && <th>Art width:</th> }

                                </tr>
                            </thead>
                            <tbody>

                            <tr className="offsetTableRow"></tr>

                            {

                                this.props.sidesArrayByActiveColor.map((side, index) => {

                                    return (

                                        <tr key={index} onClick={this.props.changeActiveSide.bind(this, side.side_name)}>
                                            <td className="singleProductItemSideName">{side.side_name}</td>
                                            <td className="singleProductItemSideUploadedImageInfo">{this.props.renderUploadImageInfo(side.side_name)}</td>

                                            { (this.props.activeServiceType === 'screen printing') ?

                                                <td className="singleProductItemSideColorsAmount">

                                                <div className="singleProductItemSideColorsAmountWrapper">

                                                    <span className="sideColorsAmountSelectContainer">

                                                        { this.props.renderInkDropdownOptions(side.side_name) }

                                                        {/*{ (index === 0) ? <div className="setInkNumberMessageBox message-box-style">Set ink numbers to calculate the price</div> : null }*/}

                                                    </span>

                                                    { this.props.isAdvancedSettingsActive &&

                                                        <div className="inksColorsPalettesListContainer">

                                                            <ul className="inksColorsPalettesList">

                                                                {this.renderInksColorsArrayBySide(side.side_name)}

                                                            </ul>

                                                        </div>

                                                    }

                                                </div>

                                            </td>

                                            : null }

                                            { this.props.isAdvancedSettingsActive &&

                                                <td>
                                                    <Select
                                                        defaultValue={ this.getDefaultArtWidthValueBySide(side.side_name) }
                                                        className="artWidthSelect"
                                                        classNamePrefix="garage-select"
                                                        isSearchable={false}
                                                        placeholder="Size"
                                                        // defaultMenuIsOpen={true}
                                                        components={{ DropdownIndicator }}
                                                        styles={customStyles}
                                                        onChange={this.props.changeArtWidth.bind(this, side.side_name)}
                                                        options={SCREEN_PRINTING_ART_WIDTH_OPTIONS}
                                                    />
                                                </td>

                                            }


                                        </tr>

                                    );


                                })

                            }

                            </tbody>
                        </table>

                        <div className="artworkDisclaimer"><span className="icon-warning"></span> *If you prefer, you can email artwork after submitting your order.</div>

                    </div>

                </div>

                <div className={`inksColorsPaletteDropdown ${ this.state.activeInkColorDropdownObject.isOpen && "is-visible" }`}
                     style={ {
                         left: this.state.activeInkColorDropdownObject.dropdown_position_x,
                         top: this.state.activeInkColorDropdownObject.dropdown_position_y
                     } }>

                    <div className="inksColorsPaletteTitle">Choose Ink Color:</div>

                    <div className="inksColorsPaletteDropdownList">

                        { this.renderInksColorsPaletteDropdownList() }

                    </div>

                    {/*<button className="chooseForMeInkColor">Choose for me </button>*/}

                </div>

            </div>

        )

    }


}

export default ProductSidesPanel;
