import React, { Component } from 'react';
import './StepNavigation.css';
import { changeDeliveringType } from "../../actions/actions";
import {NavLink, withRouter} from 'react-router-dom';
import { connect } from "react-redux";
import {
    STANDARD_DELIVERING_COEFFICIENT,
    STANDARD_DELIVERING_DAYS_AMOUNT,
    RAPID_DELIVERING_COEFFICIENT,
    RAPID_DELIVERING_DAYS_AMOUNT,
    FULL_THROTTLE_DELIVERING_COEFFICIENT,
    FULL_THROTTLE_DELIVERING_DAYS_AMOUNT
} from "../../properties";

import $ from "jquery";

import garageLogoPng from '../../images/garage-chapms-logo.png';
import garageLogoSvg from '../../images/garage-chapms-logo.svg';
import UserAccountHeaderPanel from "./UserAccountHeaderPanel";


const navigationStepsButtons = [
    {
        path: '/',
        name: 'Select Product'
    },
    {
        path: '/product',
        name: 'Product Options'
    },
    {
        path: '/cart',
        name: 'Cart'
    }
    ];

let moment = require('moment-business-days');


class StepNavigation extends Component {


    constructor(props) {

        super(props);
        this.state = {
            isMiniCartModalVisible: false,
            navHamburgerDisplayStatus: 'hidden'
        };

        this.showMiniCartModal = this.showMiniCartModal.bind(this);
        this.hideMiniCartModal = this.hideMiniCartModal.bind(this);

    }


    openSaveProductModal(stepNumber, e) {

        e.preventDefault();

        $('.saveProductModal')[0].click();

    }


    showMiniCartModal() {

        this.setState({
            isMiniCartModalVisible: true
        });

    }


    hideMiniCartModal() {

        this.setState({
            isMiniCartModalVisible: false
        });

    }


    renderMiniCartModal() {

        if ( this.props.productList.length > 0 ) {

            return (

                <div className={`cartHoverInfoBox ${(this.state.isMiniCartModalVisible) ? 'cartHoverInfoBoxVisible' : null}`}>

                    <div className="cartHoverInfoBoxOuterContainer">
                        <span className="cartHoverInfoBoxOuter icon-arrow-for-select"></span>
                    </div>

                    <div className="cartHoverInfoBoxBody">
                        <div className="cartHoverInfoBoxTitle"><span>{this.props.productList.length}</span> items in your cart:</div>
                        <div className="cartHoverInfoBoxTable">

                            <table>
                                <tbody>

                                {
                                    this.props.productList.map((product, index) => {

                                        return (

                                            <tr key={index}>
                                                <td><div><img src={product.product_after_calculate_data.productImagesScreenshotsArray[0]} alt="test" /></div></td>
                                                <td><span>{product.product_main_data.product_name}</span></td>
                                                <td><span>{product.product_after_calculate_data.total_products} pcs</span></td>
                                                <td><span>${product.product_after_calculate_data.single_price}/pcs</span></td>
                                            </tr>

                                        )

                                    })

                                }
                                </tbody>
                            </table>

                        </div>
                    </div>

                </div>

            )


        } else {


            return (

                <div className={`cartHoverInfoBox cartHoverInfoBoxEmpty ${(this.state.isMiniCartModalVisible) ? 'cartHoverInfoBoxVisible' : null}`}>

                    <div className="cartHoverInfoBoxOuterContainer">
                        <span className="cartHoverInfoBoxOuter icon-arrow-for-select"></span>
                    </div>

                    <div className="cartHoverInfoBoxBody">
                        <div className="cartHoverInfoBoxTitle"><span>{this.props.productList.length}</span> items in your cart:</div>
                        <div className="cartHoverInfoBoxTable">


                        </div>
                    </div>

                </div>

            )

        }


    }


    renderStepsNavigationButtons() {

        return navigationStepsButtons.map( (button, index) => {

            if ( this.props.location.pathname === '/cart/thankyou' || this.props.location.pathname === '/cart/thankyou/' ) {

                return (

                    <label key={index+1} className="my-btn-secondary" data-toggle="not-allowed">
                        <div className="stepNavItemCircle">{index + 1}</div>
                        <div className="stepNavItemName">{button.name}</div>
                    </label>


                )

            }

            if ( button.name === 'Cart' ) {

                return (
                    <NavLink
                        key={index+1}
                        className="my-btn-secondary"
                        to={button.path} onMouseUp={this.hideMiniCartModal}
                        onMouseEnter={this.showMiniCartModal}
                        onMouseLeave={this.hideMiniCartModal}
                        onClick={(this.props.productObjectForEditing && this.props.location.pathname.startsWith('/product/')) ? this.openSaveProductModal.bind(this, button.path) : null }
                    >

                            <div className="stepNavItemCircle">{index + 1}</div>
                            <div className="stepNavItemName">{button.name}</div>
                            { (this.props.productList.length > 0) ? <span className="cartHasProductIndicator"></span> : null }

                            {this.renderMiniCartModal()}

                    </NavLink>

                )

            } else {


                return (

                    <NavLink
                        key={index+1}
                        exact={(button.path === '/') ? true : false}
                        to={button.path}
                        onClick={(this.props.productObjectForEditing && this.props.location.pathname.startsWith('/product/')) ? this.openSaveProductModal.bind(this, button.path) : null }
                        className={`my-btn-secondary ${(this.props.location.pathname.startsWith('/product') && button.path === "/product" ) ? "non-active-link" : null }`}
                    >

                            <div className="stepNavItemCircle">{index + 1}</div>
                            <div className="stepNavItemName">{button.name}</div>

                    </NavLink>

                )

            }



        });

    }


    toggleHamburgerMenu() {

        let hamburger_display_status = (this.state.navHamburgerDisplayStatus === "hidden") ? "open" : "hidden";

        this.setState({ navHamburgerDisplayStatus: hamburger_display_status });

    }




    render() {



        return (


            <div className="stepNavigation">

                <div className="headerContainer">
                    <div className="container">

                    <div className="row">

                        <div className="col-2 headerLogoContainer">

                            <a href="https://garagechamps.com/">
                                <img src={garageLogoPng} alt="garage champs" srcSet={garageLogoSvg} />
                            </a>

                        </div>

                        <div className="col-8">

                            <div className="stepNavButtonList">

                                { this.renderStepsNavigationButtons() }

                            </div>

                        </div>


                        <div className="col-2 stepNavDeliveringTypeContainer">
                            <UserAccountHeaderPanel />
                        </div>

                        {/*<div className="col-2 stepNavDeliveringTypeContainer">*/}

                        {/*    <div className="desktopNavMenuContainer">*/}

                        {/*        <div*/}
                        {/*            data-toggle={( this.props.location.pathname === '/cart/thankyou' || this.props.location.pathname === '/cart/thankyou/' ) ? "not-allowed" : null }*/}
                        {/*            className="stepNavDeliveringTypeTitle"*/}
                        {/*        >*/}
                        {/*                Production speed:</div>*/}
                        {/*        <div*/}
                        {/*            className="stepNavDeliveringTypeName"*/}
                        {/*            data-toggle={( this.props.location.pathname === '/cart/thankyou' || this.props.location.pathname === '/cart/thankyou/' ) ? "not-allowed" : "modal" }*/}
                        {/*            data-target="#deliveringTypeModal"*/}
                        {/*        >*/}
                        {/*                {this.props.deliveringTypeObject.delivering_type_name} <span>({this.props.deliveringTypeObject.delivering_type_days_amount} workdays)</span>*/}
                        {/*        </div>*/}

                        {/*    </div>*/}


                        {/*    <div className="mobileNavMenuContainer">*/}

                        {/*        <div className={this.state.navHamburgerDisplayStatus} id="nav-hamburger" onClick={this.toggleHamburgerMenu.bind(this)}>*/}
                        {/*            <span></span>*/}
                        {/*            <span></span>*/}
                        {/*            <span></span>*/}
                        {/*        </div>*/}

                        {/*    </div>*/}

                        {/*</div>*/}

                    </div>

                </div>
                </div>


                <button type="button" className="saveProductModal btn btn-primary" data-toggle="modal" data-target="#saveProductModal">save product modal</button>


                {/* MODAL DELIVERING TYPE HTML START */}
                <div className="modal fade" id="deliveringTypeModal" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <div className="deliveringModalTitle">Delivering on your shedule!</div>
                                {/*<button type="button" className="close" data-dismiss="modal" aria-label="Close">*/}
                                    {/*<span aria-hidden="true">&times;</span>*/}
                                {/*</button>*/}
                                <span className="icon-close" data-dismiss="modal" aria-label="Close"></span>
                            </div>
                            <div className="modal-body">
                                <div className="deliveringModalWave"><span className="icon-wave"></span></div>
                                <div className="deliveringModalDescription">
                                    We have three delivery speeds so that your shirts are delivered exactly
                                    when you need them: Standard 2-week delivery, Rapid 1-week option, and Full throttle 3-workdays delivery.
                                </div>
                                <div className="deliveringModalTypeList">
                                    <div
                                        className={(this.props.deliveringTypeObject.delivering_type_name === 'Standard') ? 'deliveringModalTypeItem deliveringModalTypeItemActive' : 'deliveringModalTypeItem'}
                                        onClick={this.props.onChangeDeliveringType.bind(this, STANDARD_DELIVERING_COEFFICIENT, 'Standard', STANDARD_DELIVERING_DAYS_AMOUNT)}>
                                        <div className="deliveringModalTypeItemIcon">
                                            <span className="icon-rabbit"></span>
                                        </div>
                                        <div className="deliveringModalTypeItemBody">
                                            <div className="deliveringModalTypeItemName">Standard</div>
                                            <div className="deliveringModalTypeItemInfo">
                                                <strong>2 weeks</strong>
                                                delivery by
                                                <strong>{moment(new Date()).businessAdd(10).format('MMMM D')}</strong>
                                            </div>
                                            <div className="deliveringModalTypeItemFooter">lowest price</div>
                                        </div>
                                    </div>
                                    <div
                                        className={(this.props.deliveringTypeObject.delivering_type_name === 'Rapid') ? 'deliveringModalTypeItem deliveringModalTypeItemActive' : 'deliveringModalTypeItem'}
                                        onClick={this.props.onChangeDeliveringType.bind(this, RAPID_DELIVERING_COEFFICIENT, 'Rapid', RAPID_DELIVERING_DAYS_AMOUNT)}>
                                        <div className="deliveringModalTypeItemIcon">
                                            <span className="icon-cheetah"></span>
                                        </div>
                                        <div className="deliveringModalTypeItemBody">
                                            <div className="deliveringModalTypeItemName">Rapid</div>
                                            <div className="deliveringModalTypeItemInfo">
                                                <strong>1 week</strong>
                                                delivery by
                                                <strong>{moment(new Date()).businessAdd(5).format('MMMM D')}</strong>
                                            </div>
                                            <div className="deliveringModalTypeItemFooter delivFootBg">+15% to price</div>
                                        </div>
                                    </div>
                                    <div
                                        className={(this.props.deliveringTypeObject.delivering_type_name === 'Full throttle') ? 'deliveringModalTypeItem deliveringModalTypeItemActive' : 'deliveringModalTypeItem'}
                                        onClick={this.props.onChangeDeliveringType.bind(this, FULL_THROTTLE_DELIVERING_COEFFICIENT, 'Full throttle', FULL_THROTTLE_DELIVERING_DAYS_AMOUNT)}>
                                        <div className="deliveringModalTypeItemIcon">
                                            <span className="icon-eagle"></span>
                                        </div>
                                        <div className="deliveringModalTypeItemBody">
                                            <div className="deliveringModalTypeItemName">Full throttle</div>
                                            <div className="deliveringModalTypeItemInfo">
                                                <strong>3 workdays</strong>
                                                delivery by
                                                <strong>{moment(new Date()).businessAdd(3).format('MMMM D')}</strong>
                                            </div>
                                            <div className="deliveringModalTypeItemFooter delivFootBg">+30% to price</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <span className="deliveringModalTypeResultName">You have selected <span>{this.props.deliveringTypeObject.delivering_type_name}</span></span>
                                <button className="changeDeliveringTypeButton" data-dismiss="modal" aria-label="Close">CONTINUE</button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* MODAL DELIVERING TYPE HTML END */}


            </div>

        )

    }


}

export default withRouter(connect(
    state => ({
        productObjectForEditing: state.product_option.product_object_for_editing,
        deliveringTypeObject: state.delivering_options,
        productList: state.cart.product_list
    }),
    dispatch => ({
        onChangeDeliveringType: (delivering_type_coefficient, delivering_type_name, delivering_type_days_amount) => {

            let newDeliveringTypeObject = {
                delivering_type_name: delivering_type_name,
                delivering_type_coefficient: delivering_type_coefficient,
                delivering_type_days_amount: delivering_type_days_amount
            };

            dispatch(changeDeliveringType(newDeliveringTypeObject));
        }
    })
)(StepNavigation));

