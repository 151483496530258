import React, { Component } from 'react';
import './SignUpPage.css';
import UserProfileForm from "./UserProfileForm";
import {Link} from "react-router-dom";
import $ from "jquery";



class SignUpPage extends Component {


    constructor(props) {

        super(props);

    }




    render() {


        return (


            <div className="signUpPage">

                <div className="container">

                    <div className="isHaveAccountMessage">Already have an account <Link to="/my-account?triggerLogin=true">Log in</Link> ? </div>

                    <div className="setupPageBody signUpPageBody">

                        <div className="setupPageTitle">Hello!</div>
                        <div className="setupPageSubTitle">Upon sign up you'll be able to see your history of orders inside your account.</div>


                        <UserProfileForm formType="signup-profile-data" />

                    </div>

                </div>


                {/* MODAL ACCOUNT IS ALL SET START*/}
                <button id="accountIsAllSetModalButton" className="hiddenElement" data-toggle="modal" data-target="#accountIsAllSetModal">-</button>

                <div className="modal fade" id="accountIsAllSetModal" tabIndex="-1" role="dialog" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog modal-dialog-centered" role="document">

                        <div className="modal-content">
                            <div className="modal-header">
                                <div className="guidelinesModalTitle modalErrorTitle">Your account is all set!</div>
                            </div>
                            <div className="modal-body">
                                <div className="guidelinesModalDescription modalErrorDescription">
                                    Now press <strong>"OK"</strong> to be redirected to the login page where you
                                    use the email and password you've just set to get into your all-new account.
                                </div>

                                <div className="iUnderstandButtonContainer">
                                    <Link to="/my-account?triggerLogin=true">
                                        <button>OK</button>
                                    </Link>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                {/* MODAL ACCOUNT IS ALL SET END */}


            </div>

        )

    }


}

export default SignUpPage;

