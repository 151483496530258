import {
    IS_FAIL_FETCH_SINGLE_PRODUCT
} from "../actions/actions";


const initialState = {
    isFailFetchSingleProduct: false
};



export function errors_reducer(state = initialState, action) {

    switch (action.type) {
        case IS_FAIL_FETCH_SINGLE_PRODUCT :

            return {
                ...state,
                isFailFetchSingleProduct: action.payload
            };

        default:

            return state
    }
}
