import React, { Component } from 'react';
import './StepThankYouForSubmitting.css';
import thankImagePng from '../../../images/thank-icon.png';
import thankImageSvg from '../../../images/thank-icon.svg';




class StepThankYouForSubmitting extends Component {

    constructor(props) {
        super(props);
        this.state = {
            productIDForDeleting: 0,
        };

    }


    render() {

        return (

            <div className="stepThankYouForSubmitting">

                <div className="container">

                    <div className="thankImageBlock">
                        <img src={thankImagePng} alt="Thank You For Submitting" srcSet={thankImageSvg} />
                    </div>

                    <div className="thankTitle">Thank you for submitting a quote request!</div>
                    <div className="thankDescription">One of our team members will review your order within the next 24 hours (M-F) and get back to you with any questions.</div>

                    <div className="thankBackToHomeBlock">
                        <a href="https://garagechamps.com/" className="thankBackToHome">Back to the homepage <span className="icon-home"></span></a>
                    </div>

                    <div className="thankContactsButtonBlocks">
                        <div className="thankContactsTitle">If you need immediate assistance feel free to call us or shoot as an email:</div>
                        <a name="phone" href="tel:+19166318470"><span className="icon-phone"></span> 916-631-8470 <p>(M-F from 8AM-5:30PM)</p></a>
                        <a name="mail" href="mailto:team@GarageChamps.com"><span className="icon-mail"></span> team@garagechamps.com</a>
                    </div>

                </div>

            </div>

        )

    }


}

export default StepThankYouForSubmitting;
