import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import HttpsRedirect from 'react-https-redirect';

import App from './App';
import store from './Store/Store';
import * as serviceWorker from './serviceWorker';

import {Auth0Provider} from "./react-auth0-spa";
import config from "./auth_config.json";


import './index.css';



import StepSelectProduct from "./components/StepsBody/StepSelectProduct/StepSelectProduct";
import StepCalculationProduct from "./components/StepsBody/StepCalculationProduct/StepCalculationProduct";
import StepCart from "./components/StepsBody/StepCart/StepCart";
import StepThankYouForSubmitting from "./components/StepsBody/StepThankYouForSubmitting/StepThankYouForSubmitting";
import PageNotFound from "./components/404/PageNotFound";
import GoToStartStepButton from "./components/StepsBody/GoToStartStepButton";
import UserAccount from "./components/UserAccount/UserAccount";
import InitializePage from "./components/UserAccount/InitializePage";
import SetupPage from "./components/UserAccount/SetupPage";
import SignUpPage from "./components/UserAccount/SignUpPage";


const onRedirectCallback = appState => {
    // alert('1');
};




ReactDOM.render(
    <Auth0Provider
        domain={config.domain}
        client_id={config.clientId}
        redirect_uri={window.location.origin+'/my-account'}
        onRedirectCallback={onRedirectCallback}
    >
        <Provider store={store}>
            <BrowserRouter> {/* basename="/quote"*/}
                <HttpsRedirect>
                    <App>
                        <Switch>
                            <Route exact path='/' component={StepSelectProduct}/>
                            <Route path='/my-account/signup' component={SignUpPage}/>
                            <Route path='/my-account/initialize' component={InitializePage}/>
                            <Route path='/my-account/setup' component={SetupPage}/>
                            <Route path='/my-account' component={UserAccount}/>
                            <Route exact path='/cart/thankyou' component={StepThankYouForSubmitting}/>
                            <Route exact path='/cart' component={StepCart}/>
                            <Route path='/saved/:slug' component={StepCalculationProduct}/>
                            <Route exact path='/product/:slug' component={StepCalculationProduct}/>
                            <Route path='/product' component={GoToStartStepButton}/>
                            <Route path='*' component={PageNotFound}/>

                            {/*<Route path='/product' component={StepCalculationProduct}/>*/}
                        </Switch>
                    </App>
                </HttpsRedirect>
            </BrowserRouter>
        </Provider>
    </Auth0Provider>,
    document.getElementById('root')

);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
