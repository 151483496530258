import React, { Component } from 'react';
import './StepCalculationProduct.css';
import { connect } from "react-redux";
import {
    addToCart,
    saveProductAfterEditing,
    cancelEditingProduct,
    fetchSingleProduct,
    isFailFetchSingleProduct
} from "../../../actions/actions";
import { getPrintingPricePerPiece, getEmbroideryPricePerPiece, calculateCurrentProductPriceOfAmountForPriceBreaks } from "../../../Helpers";
import { Layer } from 'react-konva';
import {MINIMUM_NUMBER_OF_PRODUCTS_FOR_EMBROIDERY, PRICE_BREAKS_QTY_ARRAY} from "../../../properties";
import ImageGallery from 'react-image-gallery';
import Loader from 'react-loader-spinner';
import $ from "jquery";
import * as filestack from 'filestack-js';
import ProductsImagesScreenshot from "./ProductsImagesScreenshot/ProductsImagesScreenshot";
import DOMPurify from 'dompurify';
import { Link, withRouter } from 'react-router-dom';

import embroideryInfo from '../../../images/embroidery-info.jpg';
import saveForLaterImg from '../../../images/save-for-later.svg';

import PageNotFound from "../../404/PageNotFound";
import GoToStartStepButton from "../GoToStartStepButton";
import ProductTitles from "./SingleProductElements/ProductTitles/ProductTitles";
import ProductSidesPanel from "./SingleProductElements/ProductSidesPanel/ProductSidesPanel";
import ProductColorsPanel from "./SingleProductElements/ProductColorsPanel/ProductColorsPanel";
import ProductQuantityAndSizePanel
    from "./SingleProductElements/ProductQuantityAndSizePanel/ProductQuantityAndSizePanel";
import ProductPricePerItemPanel from "./SingleProductElements/ProductPricePerItemPanel/ProductPricePerItemPanel";
import ProductTotalPricePanel from "./SingleProductElements/ProductTotalPricePanel/ProductTotalPricePanel";
import ProductCanvasPanel from "./SingleProductElements/ProductCanvasPanel/ProductCanvasPanel";
import DraggableImageOnProduct from "./KonvaCanvasComponents/DraggableImageOnProduct";
import TransformerHandler from "./KonvaCanvasComponents/TransformerHandler";
import UserAccount from "../../UserAccount/UserAccount";
import { Helmet } from "react-helmet/es/Helmet";
import Select, { components } from 'react-select';
import ProductSidesSlider from "./SingleProductElements/ProductSidesSlider/ProductSidesSlider";

const deepClone = require('deepclonejs');


// приставка до url для локального тестування
// const linkToImages = process.env.PUBLIC_URL + '/products/t-shirts/';


const filestackClient = filestack.init('ADrblcnafTIuIUCrkNTrNz');


// SCREEN PRINTING ART WIDTH OPTIONS ////////////////////
const SCREEN_PRINTING_COLORS_AMOUNT_OPTIONS = [
    { value: 0, label: '0' },
    { value: 1, label: '1' },
    { value: 2, label: '2' },
    { value: 3, label: '3' },
    { value: 4, label: '4' },
    { value: 5, label: '5' },
    { value: 6, label: '6' },
    { value: 6, label: 'Photo' },
];

const customStyles = {
    option: (provided, state) => ({}),
    control: (provided, state) => ({
        backgroundColor: '#ffffff'
    }),
    menu: (provided, state) => ({
        ...provided,
        width: 55
    }),
    indicatorSeparator: (provided, state) => ({
        display: 'none'
    }),
    valueContainer: (provided, state) => ({}),
    indicatorsContainer: (provided, state) => ({})
}

const DropdownIndicator = props => {
    return (
        <components.DropdownIndicator {...props}>
            <span className="icon icon-arrow-for-select"></span>
        </components.DropdownIndicator>
    );
};
////////////////////////////////////////////////////////////




class StepEditProduct extends Component {

    constructor(props) {
        super(props);

        let isProductEditing;
        let activeProductColor;
        let activeProductColorHEX;
        let activeProductSide;
        let sizeAmountObject = {};
        let sidesColorsAmountObject = {};
        let productImagesObject = {};
        let availableColorStyles;
        let sizesLabelObject;
        let sidesObjectByFirstColorOfProduct;
        let isAdvancedSettingsActive;
        let advancedSettingsObject;
        let activeServiceType;


        if ( this.props.productObjectForEditing !== null ) {

            //TODO: оптимізувати дубулювання присвоєння
            availableColorStyles = this.props.productObjectForCalculation.product_object.available_color_styles.find(color => color.is_color_available === true);
            sidesObjectByFirstColorOfProduct = availableColorStyles.color_sides_images;

            isProductEditing = true;
            sizeAmountObject = this.props.productObjectForEditing.sizeAmountObject;
            sidesColorsAmountObject = this.props.productObjectForEditing.sidesColorsAmountObject;
            productImagesObject = this.props.productObjectForEditing.productImagesObject;
            activeProductColor = this.props.productObjectForEditing.activeProductColor;
            activeProductColorHEX = this.props.productObjectForEditing.activeProductColorHEX;
            activeProductSide = sidesObjectByFirstColorOfProduct[0].side_name;
            isAdvancedSettingsActive = this.props.productObjectForEditing.isAdvancedSettingsActive;
            advancedSettingsObject = this.props.productObjectForEditing.advancedSettingsObject;
            activeServiceType = this.props.productObjectForEditing.activeServiceType;

        } else {

            let [
                isProductEditing_new,
                activeProductColor_new,
                activeProductColorHEX_new,
                activeProductSide_new,
                sizeAmountObject_new,
                sidesColorsAmountObject_new,
                productImagesObject_new,
                isAdvancedSettingsActive_new,
                advancedSettingsObject_new,
                activeServiceType_new
            ] = this.initializationState();


            isProductEditing = isProductEditing_new;
            activeProductColor = activeProductColor_new;
            activeProductColorHEX = activeProductColorHEX_new;
            activeProductSide = activeProductSide_new;
            sizeAmountObject = sizeAmountObject_new;
            sidesColorsAmountObject = sidesColorsAmountObject_new;
            productImagesObject = productImagesObject_new;
            isAdvancedSettingsActive = isAdvancedSettingsActive_new;
            advancedSettingsObject = advancedSettingsObject_new;
            activeServiceType = activeServiceType_new;
        }

        this.state = {
            urlForSavedOrderData: null,
            isUrlForSavedOrderDataLoading: false,
            isOrderDataSaveOnEmailLoading: false,
            screenWidth: window.innerWidth,
            isSingleProductObjectReadyForRendering: false,
            isSingleProductFetchLoaderActive: false,
            sizeAmountObject: sizeAmountObject,
            isProductEditing: isProductEditing,
            isImageTransformer: false,
            activeProductSide: activeProductSide,
            activeProductColor: activeProductColor,
            activeProductColorHEX: activeProductColorHEX,
            productImagesObject: productImagesObject,
            sidesColorsAmountObject: sidesColorsAmountObject,
            productImagesScreenshotsArray: null,
            getProductImagesScreenshots: false,
            isProductImageLoaded: false,
            // isUploadingImageLoaded: false,
            isPageLoaderActive: false,
            isProductImageLoaderActive: false,
            activeServiceType: activeServiceType,
            isAdvancedSettingsActive: isAdvancedSettingsActive,
            advancedSettingsObject: advancedSettingsObject
                // {
                //     artsWidthsObjectBySide: {
                //         'Front Side': 2,
                //         'Back Side': 4
                //     },
                //     inksColorsObjectBySide: {
                        // 'Front Side': [
                        //     {
                        //         enabled: false,
                        //         colorName: null,
                        //         colorHEX: null
                        //     },
                        //     {
                        //         enabled: true,
                        //         colorName: 'Pink Lux 200',
                        //         colorHEX: '#DFF421'
                        //     }
                        // ],
                        // 'Back Side': [
                        //     {
                        //         enabled: false,
                        //         colorName: null,
                        //         colorHEX: null
                        //     },
                        //     {
                        //         enabled: true,
                        //         colorName: 'Pink Lux 200',
                        //         colorHEX: '#DFF421'
                        //     }
                        // ]
                    // }
            // }
        };


        this.addToCart = this.addToCart.bind(this);
        this.renderSizesInput = this.renderSizesInput.bind(this);
        this.saveProductAfterEditing = this.saveProductAfterEditing.bind(this);
        this.cancelEditingProduct = this.cancelEditingProduct.bind(this);
        this.saveCoordinatesDraggableImageOnProduct = this.saveCoordinatesDraggableImageOnProduct.bind(this);
        this.saveTransformDataDraggableImageOnProduct = this.saveTransformDataDraggableImageOnProduct.bind(this);
        this.uploadingImageIsLoaded = this.uploadingImageIsLoaded.bind(this);
        this.productImageIsLoaded = this.productImageIsLoaded.bind(this);
        this.productImagesScreenshotsIsDone = this.productImagesScreenshotsIsDone.bind(this);
        this.getProductImagesScreenshots = this.getProductImagesScreenshots.bind(this);
        this.onStartProductImageLoading = this.onStartProductImageLoading.bind(this);
        this.changeActiveServiceType = this.changeActiveServiceType.bind(this);
        this.goToCartPage = this.goToCartPage.bind(this);
        this.handleWindowSizeChange = this.handleWindowSizeChange.bind(this);
        this.onCanvasTransformerChanges = this.onCanvasTransformerChanges.bind(this);



        this.deleteUploadImageFromSide = this.deleteUploadImageFromSide.bind(this);

        this.changeActiveSide = this.changeActiveSide.bind(this);
        this.renderUploadImageInfo = this.renderUploadImageInfo.bind(this);
        this.renderInkDropdownOptions = this.renderInkDropdownOptions.bind(this);
        this.renderColorsButtonsList = this.renderColorsButtonsList.bind(this);
        this.getTotalProducts = this.getTotalProducts.bind(this);
        this.colorsAmountValidation = this.colorsAmountValidation.bind(this);
        this.getSingleProductPrice = this.getSingleProductPrice.bind(this);
        this.getTotalProductsPrice = this.getTotalProductsPrice.bind(this);
        this.getCurrentVolumeSavingByTotalOfProduct = this.getCurrentVolumeSavingByTotalOfProduct.bind(this);
        this.renderSidesButtonsList = this.renderSidesButtonsList.bind(this);
        this.renderFileUploadButton = this.renderFileUploadButton.bind(this);
        this.handleTransformerMethodForDraggableImage = this.handleTransformerMethodForDraggableImage.bind(this);
        this.renderDraggableImageOnProductComponent = this.renderDraggableImageOnProductComponent.bind(this);
        this.generateURLForSavedOrderData = this.generateURLForSavedOrderData.bind(this);

        this.changeArtWidth = this.changeArtWidth.bind(this);
        this.changeItemInkColorBySide = this.changeItemInkColorBySide.bind(this);
        this.onSwitchAdvancedSettings = this.onSwitchAdvancedSettings.bind(this);

        this.saveForLaterOnEmail = this.saveForLaterOnEmail.bind(this);
        this.copyToClipboard = this.copyToClipboard.bind(this);


        // INITIALIZE REFS
        this.saveUrlToEmailInput = React.createRef();
        this.copyToClipboardInput = React.createRef();
        this.productCanvasPanelRef = React.createRef();
    }


    initializationState() {

        let isProductEditing;
        let activeProductColor;
        let activeProductColorHEX;
        let activeProductSide;
        let sizeAmountObject = {};
        let sidesColorsAmountObject = {};
        let productImagesObject = {};
        let availableColorStyles;
        let sizesLabelObject;
        let sidesObjectByFirstColorOfProduct;
        let isAdvancedSettingsActive;
        let advancedSettingsObject = {};
        let inksColorsObjectBySide = {};
        let artsWidthsObjectBySide = {};
        let activeServiceType;


        if (this.props.productObjectForCalculation) {

            //TODO: оптимізувати дубулювання присвоєння
            availableColorStyles = this.props.productObjectForCalculation.product_object.available_color_styles.find(color => color.is_color_available === true);
            sizesLabelObject = this.props.productObjectForCalculation.product_object.product_sizes;
            sidesObjectByFirstColorOfProduct = availableColorStyles.color_sides_images;

            isProductEditing = false;

            // генеруємо об*єкт кількості розмірів для калькуляції і валідації
            sizesLabelObject.forEach((size) => {

                let defaultSizeAmount = 0;

                switch (size.size_label) {
                    case 'S':
                        defaultSizeAmount = 16;
                        break;
                    case 'M':
                        defaultSizeAmount = 32;
                        break;
                    case 'L':
                        defaultSizeAmount = 32;
                        break;
                    case 'XL':
                        defaultSizeAmount = 16;
                        break;
                    case '2XL':
                        defaultSizeAmount = 4;
                        break;
                    case 'One Size':
                        defaultSizeAmount = 100;
                        break;
                    default:
                        defaultSizeAmount = 0;
                }

                sizeAmountObject[size.size_label] = defaultSizeAmount;

            });


            // генеруємо об*єкт кількості кольорів на artwork для кожного side для калькуляції і валідації
            sidesObjectByFirstColorOfProduct.forEach((side) => {

                sidesColorsAmountObject[side.side_name] = 0

            });


            // генеруємо об*єкт sides для подальшої роботи на canvas
            sidesObjectByFirstColorOfProduct.forEach((side) => {

                productImagesObject[side.side_name] = {
                    upload_image_object: null,
                    coordX: 160,
                    coordY: 130,
                    scaleX: 1,
                    scaleY: 1,
                    rotation: 0
                }

            });



            // генеруємо об*єкт artwork width для кожного side для для advanced settings
            sidesObjectByFirstColorOfProduct.forEach((side) => {

                artsWidthsObjectBySide[side.side_name] = {
                    label: null,
                    value: null
                }

            });



            // генеруємо об*єкт кількості кольорів на artwork для кожного side для advanced settings
            let inks_colors_arr = [];

            sidesObjectByFirstColorOfProduct.forEach((side) => {


                for (let i = 0; i < sidesColorsAmountObject[side.side_name]; i++) {

                    inks_colors_arr.push({
                        enabled: false,
                        colorName: null,
                        colorHEX: null
                    });

                }

                inksColorsObjectBySide[side.side_name] = inks_colors_arr;

                inks_colors_arr = [];

            });





            activeProductSide = sidesObjectByFirstColorOfProduct[0].side_name;
            activeProductColor = availableColorStyles.color_name;
            activeProductColorHEX = availableColorStyles.color_hex;
            isAdvancedSettingsActive = false;
            advancedSettingsObject = {
                artsWidthsObjectBySide: artsWidthsObjectBySide,
                inksColorsObjectBySide: inksColorsObjectBySide
            };
            activeServiceType = "screen printing";

        }

        return [
            isProductEditing,
            activeProductColor,
            activeProductColorHEX,
            activeProductSide,
            sizeAmountObject,
            sidesColorsAmountObject,
            productImagesObject,
            isAdvancedSettingsActive,
            advancedSettingsObject,
            activeServiceType
        ];
    }


    componentDidMount () {

        // listener for app version switcher (desktop/mobile)
        window.addEventListener('resize', this.handleWindowSizeChange);


        // if store not have data about product, call onFetchSingleProduct
        if ( !this.props.productObjectFromAPI && !this.props.productObjectForCalculation ) {

            this.setState({
                isSingleProductFetchLoaderActive: true
            });

            if ( this.props.match.path === "/saved/:slug" ) {

                // this.props.onFetchSavedSingleProduct(this.props.match.params.slug);
                let savedProductDataId = this.props.match.params.slug;

                let urlForSavedOrderData = fetch("https://garagechamps.com/wp-json/quoteapi/opensaved/"+savedProductDataId, {
                    method: "GET",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                });


                return urlForSavedOrderData
                    .then(response => {

                        return response.json();

                    })
                    .then(json => {

                        if (json) {

                            this.setState({
                                sizeAmountObject: json.order_data.state.sizeAmountObject,
                                isProductEditing: json.order_data.state.isProductEditing,
                                isImageTransformer: json.order_data.state.isImageTransformer,
                                activeProductSide: json.order_data.state.activeProductSide,
                                activeProductColor: json.order_data.state.activeProductColor,
                                activeProductColorHEX: json.order_data.state.activeProductColorHEX,
                                productImagesObject: json.order_data.state.productImagesObject,
                                sidesColorsAmountObject: json.order_data.state.sidesColorsAmountObject,
                                productImagesScreenshotsArray: json.order_data.state.productImagesScreenshotsArray,
                                getProductImagesScreenshots: json.order_data.state.getProductImagesScreenshots,
                                activeServiceType: json.order_data.state.activeServiceType,
                                isAdvancedSettingsActive: json.order_data.state.isAdvancedSettingsActive,
                                advancedSettingsObject: json.order_data.state.advancedSettingsObject
                            });

                            this.props.onFetchSingleProduct(json.product_slug);

                        } else {

                            this.props.onIsFailFetchSingleProduct(true);

                        }


                    });




            } else {

                this.props.onFetchSingleProduct(this.props.match.params.slug);

            }




        } else {

            this.setState({
                isSingleProductObjectReadyForRendering: true
            });

        }

    }



    componentDidUpdate(prevProps) {

        if (prevProps.productsInCart !== this.props.productsInCart) {

            // save product list in cart to local storage after updating cart store
            window.localStorage.setItem('product_list', JSON.stringify(this.props.productsInCart.product_list));


            this.setState({
                getProductImagesScreenshots: false,
                isPageLoaderActive: false
            });

            if ( this.state.isProductEditing ) {

                this.props.history.push('/cart');

            } else {

                window.scrollTo(0, 0);

                //TODO: придумати реалізацію для модалок більш елегантну
                setTimeout(function () {

                     $('#afterAddedToCartModalButton')[0].click();

                }, 500);

            }

        }


        if ( this.props.productObjectForCalculation !== prevProps.productObjectForCalculation && this.props.productObjectForCalculation ) {

            if ( this.props.match.path === "/saved/:slug" ) {

                this.setState({ isSingleProductObjectReadyForRendering: true });

            } else {

                let [
                    isProductEditing_new,
                    activeProductColor_new,
                    activeProductColorHEX_new,
                    activeProductSide_new,
                    sizeAmountObject_new,
                    sidesColorsAmountObject_new,
                    productImagesObject_new,
                    isAdvancedSettingsActive_new,
                    advancedSettingsObject_new,
                    activeServiceType_new
                ] = this.initializationState();


                this.setState({
                        isSingleProductObjectReadyForRendering: true,
                        isSingleProductFetchLoaderActive: false,
                        sizeAmountObject: sizeAmountObject_new,
                        isProductEditing: isProductEditing_new,
                        isImageTransformer: false,
                        activeProductSide: activeProductSide_new,
                        activeProductColor: activeProductColor_new,
                        activeProductColorHEX: activeProductColorHEX_new,
                        productImagesObject: productImagesObject_new,
                        sidesColorsAmountObject: sidesColorsAmountObject_new,
                        productImagesScreenshotsArray: null,
                        getProductImagesScreenshots: false,
                        isProductImageLoaded: false,
                        isPageLoaderActive: false,
                        isProductImageLoaderActive: false,
                        activeServiceType: activeServiceType_new,
                        isAdvancedSettingsActive: isAdvancedSettingsActive_new,
                        advancedSettingsObject: advancedSettingsObject_new
                });

            }

        }

    }


    // make sure to remove the listener
    // when the component is not mounted anymore
    componentWillUnmount() {

        window.removeEventListener('resize', this.handleWindowSizeChange);

    }



    handleWindowSizeChange() {

        this.setState({ screenWidth: window.innerWidth });

    }



    getTotalProductsPrice(active_service_type) {

        if ( active_service_type === 'screen printing' ) {

            let totalPriceAfterCalculate = 0;
            let quantityPerSizeObject = this.state.sizeAmountObject;
            let totalProducts = 0;

            for (let key in quantityPerSizeObject) {
                totalProducts += quantityPerSizeObject[key];
            }

            let productBasePrice = this.props.productObjectForCalculation.product_object.product_base_price;

            //get to know what values user put for ink colors for all the sides
            let inkColorsPerSideArray = [];

            for (let key in this.state.sidesColorsAmountObject) {
                if (this.state.sidesColorsAmountObject.hasOwnProperty(key)) {
                    inkColorsPerSideArray.push(this.state.sidesColorsAmountObject[key]);
                }
            }

            //10.12 in the first release we only do calculation for frontside and backside printing
            let product_front_colors_amount = inkColorsPerSideArray[0];
            let product_back_colors_amount = inkColorsPerSideArray[1];

            let moreColorsSideNumber;
            let lessColorsSideNumber;

            if (product_front_colors_amount>product_back_colors_amount) {
                moreColorsSideNumber = product_front_colors_amount;
                lessColorsSideNumber = product_back_colors_amount;
            } else {
                moreColorsSideNumber = product_back_colors_amount;
                lessColorsSideNumber = product_front_colors_amount;
            }
            //TODO: extend the code here if there'll be more print sides (sleeves?)


            let printingPricePerPieceFirstSide = getPrintingPricePerPiece(moreColorsSideNumber, totalProducts, 1);

            let printingPricePerPieceSecondSide = getPrintingPricePerPiece(lessColorsSideNumber, totalProducts, 2);

            //TODO: floor to bigger number?

            //pseudo formula: product_price*amount + printingPriceFirstSide*amount + printingPriceSecondSide*amount;


            //todo: since now we do adjustment per size, productBasePrice

            let totalOrderPrice = parseFloat(productBasePrice) * parseInt(totalProducts) +
                printingPricePerPieceFirstSide * parseInt(totalProducts) +
                printingPricePerPieceSecondSide * parseInt(totalProducts) ;

            //this function will insert the final price into the TotalPrice bottomline in the product constructor page
            return totalOrderPrice;

        } else {
            //this is the embroidery calculation
            let totalPriceAfterCalculate = 0;
            let quantityPerSizeObject = this.state.sizeAmountObject;
            let totalProducts = 0;

            for (let key in quantityPerSizeObject) {
                totalProducts += quantityPerSizeObject[key];
            }

            let productBasePrice = this.props.productObjectForCalculation.product_object.product_base_price;


            let totalOrderPrice = parseFloat(productBasePrice) * parseInt(totalProducts) +
                getEmbroideryPricePerPiece(totalProducts) * parseInt(totalProducts) +
                30 ; //30 is a one-time set up fee that gets distributed among all pieces


            return totalOrderPrice;

        }



    }



    getSingleProductPrice(active_service_type) {

        let totalProductsPrice = this.getTotalProductsPrice(active_service_type);
        let totalProducts = this.getTotalProducts();
        let singleProductPrice;

        if ( totalProducts === 0 ) {

            singleProductPrice = 0.00;


        } else {

            singleProductPrice = totalProductsPrice / totalProducts;

        }

        return singleProductPrice;

    }



    getTotalProducts() {

        let totalPriceAfterCalculate = 0;
        let sizeAmountObject = this.state.sizeAmountObject;

        for (let key in sizeAmountObject) {

            totalPriceAfterCalculate += sizeAmountObject[key];

        }

        return totalPriceAfterCalculate;


    }



    addToCart() {

        let productObjectForCalculation = this.props.productObjectForCalculation;

        let productToCartObject = {

            // id: productObjectForCalculation.product_app_id,
            id: Math.floor(Math.random() * Math.floor(99999)),
            product_main_data: productObjectForCalculation.product_object,
            product_after_calculate_data: {
                total_price: this.getTotalProductsPrice(this.state.activeServiceType).toFixed(2),
                single_price: this.getSingleProductPrice(this.state.activeServiceType).toFixed(2),
                total_products: this.getTotalProducts(),
                sizeAmountObject: this.state.sizeAmountObject,
                sidesColorsAmountObject: this.state.sidesColorsAmountObject,
                productImagesObject: this.state.productImagesObject,
                productImagesScreenshotsArray: this.state.productImagesScreenshotsArray,
                activeProductColor: this.state.activeProductColor,
                activeProductColorHEX: this.state.activeProductColorHEX,
                activeServiceType: this.state.activeServiceType,
                productSlug: this.props.productObjectForCalculation.product_object.slug,
                isAdvancedSettingsActive: this.state.isAdvancedSettingsActive,
                advancedSettingsObject: this.state.advancedSettingsObject
            }

        };

        this.props.onAddToCart(productToCartObject);

    }



    saveProductAfterEditing() {


        let newCartList = deepClone( this.props.productsInCart.product_list );
        let productId = this.props.productObjectForCalculation.product_app_id;

        for ( let i = 0; i < newCartList.length; i++ ) {

            if ( newCartList[i].id === productId ) {

                newCartList[i].product_after_calculate_data = {
                    total_price: this.getTotalProductsPrice(this.state.activeServiceType).toFixed(2),
                    single_price: this.getSingleProductPrice(this.state.activeServiceType).toFixed(2),
                    total_products: this.getTotalProducts(),
                    sizeAmountObject: this.state.sizeAmountObject,
                    sidesColorsAmountObject: this.state.sidesColorsAmountObject,
                    productImagesObject: this.state.productImagesObject,
                    productImagesScreenshotsArray: this.state.productImagesScreenshotsArray,
                    activeProductColor: this.state.activeProductColor,
                    activeProductColorHEX: this.state.activeProductColorHEX,
                    activeServiceType: this.state.activeServiceType,
                    productSlug: this.props.productObjectForCalculation.product_object.slug,
                    isAdvancedSettingsActive: this.state.isAdvancedSettingsActive,
                    advancedSettingsObject: this.state.advancedSettingsObject
                };

            }

        }

        this.props.onSaveProductAfterEditing(newCartList);

    }



    cancelEditingProduct() {

        this.setState({
            isSingleProductObjectReadyForRendering: false
        }, () => {

            this.props.onCancelEditingProduct();
            this.props.history.push('/product');

        });

    }



    onFocusAllContentsOfInput(event) {

        event.target.select();

    }



    renderSizesInput() {

        let productSizesArray = this.props.productObjectForCalculation.product_object.product_sizes;

        return productSizesArray.map((size, index) => {

            return (

                <div key={index} className="productSizeItem" style={{width: 100 / ( productSizesArray.length + 1 ) - 1.5 + '%' }}>
                    <label>{size.size_label}</label>
                    <input type="number" min={0} defaultValue={this.state.sizeAmountObject[size.size_label]} onFocus={this.onFocusAllContentsOfInput} onChange={this.changeSizeAmount.bind(this, size.size_label)} />
                </div>

            )

        });

    }



    uploadImageFileOnCanvas(imageObjectFromFilestackServer){

        this.setState(prevState => ({
            productImagesObject: {
                ...prevState.productImagesObject,
                [`${this.state.activeProductSide}`] : {
                    ...prevState.productImagesObject[`${this.state.activeProductSide}`],
                    upload_image_object: imageObjectFromFilestackServer
                }
            }
        }));

    }



    renderDraggableImageOnProductComponent (uploadImageObject) {

        if ( uploadImageObject ) {

            return (

                <Layer>

                    <DraggableImageOnProduct
                        src={uploadImageObject.url}
                        text="Draggable Image On Product"
                        x={this.state.productImagesObject[`${this.state.activeProductSide}`].coordX}
                        y={this.state.productImagesObject[`${this.state.activeProductSide}`].coordY}
                        scaleX={this.state.productImagesObject[`${this.state.activeProductSide}`].scaleX}
                        scaleY={this.state.productImagesObject[`${this.state.activeProductSide}`].scaleY}
                        rotation={this.state.productImagesObject[`${this.state.activeProductSide}`].rotation}
                        saveCoordinatesDraggableImageOnProduct={this.saveCoordinatesDraggableImageOnProduct}
                        saveTransformDataDraggableImageOnProduct={this.saveTransformDataDraggableImageOnProduct}
                        uploadingImageIsLoaded={this.uploadingImageIsLoaded}
                        onStartProductImageLoading={this.onStartProductImageLoading}
                        isScreenshotMode={false}
                        onCanvasTransformerChanges={this.onCanvasTransformerChanges}
                    />

                    {( this.state.isImageTransformer ) ? <TransformerHandler
                                                            activeProductSide={this.state.activeProductSide}
                                                            deleteUploadImageFromSide={this.deleteUploadImageFromSide}
                                                            onCanvasTransformerChanges={this.onCanvasTransformerChanges}
                                                            selectedShapeName={'draggableImageOnProduct'}/> : null }

                </Layer>

            )

        }

    }



    saveCoordinatesDraggableImageOnProduct(newX, newY) {

        // this.setState({isImageTransformer: false}, () => {

        this.setState(prevState => ({

            productImagesObject: {
                ...prevState.productImagesObject,
                [`${this.state.activeProductSide}`] : {
                    ...prevState.productImagesObject[`${this.state.activeProductSide}`],
                    coordX: newX,
                    coordY: newY
                }
            }
        }));

        // });

    }



    saveTransformDataDraggableImageOnProduct(newX, newY, newScaleX, newScaleY, newRotation) {

        this.setState(prevState => ({

            productImagesObject: {
                ...prevState.productImagesObject,
                [`${this.state.activeProductSide}`] : {
                    ...prevState.productImagesObject[`${this.state.activeProductSide}`],
                    coordX: newX,
                    coordY: newY,
                    scaleX: newScaleX,
                    scaleY: newScaleY,
                    rotation: newRotation
                }
            }
        }));

    }



    uploadingImageIsLoaded() {

        this.setState({
            // isUploadingImageLoaded: true,
            isProductImageLoaderActive: false
        });

    }



    productImageIsLoaded() {

        this.setState({
            isProductImageLoaded: true,
            isProductImageLoaderActive: false
        });

    }



    renderFileUploadButton(uploadImageObject) {

        if ( !uploadImageObject ) {

            return (

                <div className="chooseFileContainer">

                    <input
                        type="file"
                        onChange={ (e) => this.uploadArtworkToFilestack(this.state.activeProductSide, e.target.files) }
                        accept="image/png,image/jpeg,image/bmp,image/gif,image/*,application/pdf,image/vnd.adobe.photoshop,.psd,application/postscript"
                    />


                    <div className="chooseFileBody">
                        <span className="icon-select-artwork"></span>
                        <span>Select print side and upload your artwork <br /><br/> Supported file formats: jpeg, gif, png, tiff, svg, ico</span>
                    </div>

                </div>

            )

        }

    }



    handleTransformerMethodForDraggableImage = e =>  {


        if ( e.target.attrs.id === 'productImage' ) {

            this.setState({ isImageTransformer: false }, () => {

                this.productCanvasPanelRef.current.getScreenshotOfMainCanvasBySingleSide();

            });

        } else {

            this.setState({ isImageTransformer: true });

        }

    };



    renderSidesButtonsList(sidesArrayByActiveColor, localSidesScreenshotsObject) {

        // let productSidesArray = this.props.productObjectForCalculation.product_object.male_colors[0].color_sides_images;

        return sidesArrayByActiveColor.map((side, index) => {

            return (

                <li className={(this.state.activeProductSide === side.side_name) ? 'activeSideButton' : null} onClick={this.changeActiveSide.bind(this, side.side_name)} key={index}>
                    <div className="sideButtonName">{side.side_name.replace(/ .*/,'')}</div>
                    <div className="sideButtonImageContainer">
                        <img src={ (localSidesScreenshotsObject[side.side_name] ) ? localSidesScreenshotsObject[side.side_name] : side.side_image_url } alt={side.side_name} />

                        { ( this.state.productImagesObject[side.side_name].upload_image_object )
                            ? <div className="artworkBySideIcon"><img src={this.state.productImagesObject[side.side_name].upload_image_object.url}/></div>
                            : null
                        }

                    </div>
                </li>

            )

        });

    }



    renderColorsButtonsList() {

        let productSidesArray = this.props.productObjectForCalculation.product_object.available_color_styles;

        return productSidesArray.map((color, index) => {

            if ( color.is_color_available ) {

                return (


                    <li data-tip={color.color_name.trim()} onClick={this.changeActiveColor.bind(this, color.color_name, color.color_hex)} key={index} style={{backgroundColor: color.color_hex}}>
                        {(this.state.activeProductColor === color.color_name)
                            ? <span  className="activeColorButton" style={{borderColor: color.color_hex}}></span>
                            : null}
                    </li>

                )

            }

        });


    }



    renderInkDropdownOptions(side_name) {

        // switch (this.state.activeServiceType) {
        //     case 'embroidery':
        //         return (
        //             <select defaultValue={this.state.sidesColorsAmountObject[side_name]}
        //                     name={side_name.replace(/\s/g, '')}
        //                     onChange={this.changeColorsAmount.bind(this, side_name)}>
        //                 <option value={0}>0x0</option>
        //                 <option value={1}>5x5</option>
        //                 <option value={2}>10x10</option>
        //                 <option value={3}>15x15</option>
        //                 <option value={4}>20x20</option>
        //             </select>
        //         )
        //     default:
        //         return (
        //
        //             <>
        //
        //                 <Select
        //                     defaultValue ={ SCREEN_PRINTING_COLORS_AMOUNT_OPTIONS[this.state.sidesColorsAmountObject[side_name]] }
        //                     name={side_name.replace(/\s/g, '')}
        //                     className={`incColorSelect ${'flag-for-' + side_name.replace(/\s/g, "")}`}
        //                     classNamePrefix="garage-select"
        //                     isSearchable={false}
        //                     placeholder="0"
        //                     // menuIsOpen={true}
        //                     components={{ DropdownIndicator }}
        //                     styles={customStyles}
        //                     onChange={this.changeColorsAmount.bind(this, side_name)}
        //                     options={ SCREEN_PRINTING_COLORS_AMOUNT_OPTIONS }
        //                 />
        //
        //             </>
        //         )
        // }

        return (

            <>

                <Select
                    defaultValue ={ SCREEN_PRINTING_COLORS_AMOUNT_OPTIONS[this.state.sidesColorsAmountObject[side_name]] }
                    name={side_name.replace(/\s/g, '')}
                    className={`incColorSelect ${'flag-for-' + side_name.replace(/\s/g, "")}`}
                    classNamePrefix="garage-select"
                    isSearchable={false}
                    placeholder="0"
                    // menuIsOpen={true}
                    components={{ DropdownIndicator }}
                    styles={customStyles}
                    onChange={this.changeColorsAmount.bind(this, side_name)}
                    options={ SCREEN_PRINTING_COLORS_AMOUNT_OPTIONS }
                />

            </>
        )

    }



    renderArtworkGuidelinesContent() {


        switch (this.state.activeServiceType) {
            case 'embroidery':
                return (
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="guidelinesModalTitle">Artwork embroidery guidelines</div>
                            <span className="icon-close" data-dismiss="modal" aria-label="Close"></span>
                        </div>
                        <div className="modal-body">
                            <div className="guidelinesModalSubTitle">Standard limit is 5’’x5’’ and if you want bigger embroidery - you need custom quote. For inquiry please use the <a href="http://garagechamps.com/#contact" target="_blank">contact form</a></div>
                            <div className="row guidelinesModalDescription">
                                <div className="col-9">
                                    So when placing your artwork just remember that the final placement might differ and it
                                    will be confirmed by our professional designers before we finalize your order and charge the payment.
                                </div>
                                <div className="col-3">
                                    <img src={embroideryInfo} alt="embroidery-info"/>
                                </div>
                            </div>

                            <div className="iUnderstandButtonContainer"><button data-dismiss="modal" aria-label="Close">I understand</button></div>
                        </div>
                    </div>
                )
            default:
                return (
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="guidelinesModalTitle">Artwork guidelines</div>
                            <span className="icon-close" data-dismiss="modal" aria-label="Close"></span>
                        </div>
                        <div className="modal-body">
                            <div className="guidelinesModalDescription">
                                ALL of the submitted artwork, sizing and placement is not final.
                                This is just a basic representation of what your design will look like.
                                You will be sent a final art-proof from one of our professional designers with
                                exact placement and size verification before we start any order.
                            </div>

                            <div className="iUnderstandButtonContainer"><button data-dismiss="modal" aria-label="Close">I understand</button></div>
                        </div>
                    </div>
                )
        }


    }



    changeItemInkColorBySide( color_name, color_hex, side_name, array_position ) {

        let inkColorBySideArray = this.state.advancedSettingsObject.inksColorsObjectBySide[`${side_name}`];

        inkColorBySideArray[array_position] = {
            enabled: true,
            colorName: color_name,
            colorHEX: color_hex
        };

        this.setState(prevState => ({
            advancedSettingsObject: {
                ...prevState.advancedSettingsObject,
                inksColorsObjectBySide: {
                    ...prevState.advancedSettingsObject.inksColorsObjectBySide,
                    [`${side_name}`] : inkColorBySideArray
                }
            }
        }));

    }



    changeArtWidth( sideType, widthValue ) {

        console.log(widthValue.label);

        this.setState(prevState => ({
            advancedSettingsObject: {
                ...prevState.advancedSettingsObject,
                artsWidthsObjectBySide: {
                    ...prevState.advancedSettingsObject.artsWidthsObjectBySide,
                    [`${sideType}`] : {
                        label: widthValue.label,
                        value: parseInt(widthValue.value)
                    }
                }
            }
        }));

    }



    changeActiveSide(newSideName) {

        this.setState({
            activeProductSide: newSideName
        });

    }



    changeActiveColor(newColorName, newColorHEX) {

        this.setState({
            activeProductColor: newColorName,
            activeProductColorHEX: newColorHEX
        }, () => {

            // initialize content version type by screen width
            const { screenWidth } = this.state;
            const isMobileVersion = screenWidth < 992;

            if ( !isMobileVersion ) {

                this.productCanvasPanelRef.current.clearAllScreenshotOfMainCanvas();

            }

        });

    }



    changeActiveServiceType(newActiveServiceType) {

        this.setState({
            activeServiceType: newActiveServiceType
        })

    }



    changeSizeAmount(sizeType, amountValue) {

        let newAmountValue = amountValue.target.value;

        if ( newAmountValue === '' ) {

            newAmountValue = 0;

        }

        this.setState(prevState => ({
            sizeAmountObject: {
                ...prevState.sizeAmountObject,
                [`${sizeType}`] : Math.floor(newAmountValue)
            }
        }));

    }



    changeColorsAmount(sideType, amountValue) {

        // let sideAmountValue = amountValue.target.value;
        let sideAmountValue = amountValue.value;


        // генеруємо комірку ( масив ) під кожний колір sideAmountValue
        let inksColorsArrayBySide = [];

        for (let i = 0; i < sideAmountValue; i++ ) {

            inksColorsArrayBySide.push({
                enabled: false,
                colorName: null,
                colorHEX: null
            });

        }

        // console.log(inksColorsArrayBySide);


        this.setState(prevState => ({
            sidesColorsAmountObject: {
                ...prevState.sidesColorsAmountObject,
                [`${sideType}`] : parseInt(sideAmountValue)
            },
            advancedSettingsObject: {
                ...prevState.advancedSettingsObject,
                inksColorsObjectBySide: {
                    ...prevState.advancedSettingsObject.inksColorsObjectBySide,
                    [`${sideType}`] : inksColorsArrayBySide
                }
            }
        }));

    }



    uploadArtworkToFilestack(sideName, files) {

        this.onStartProductImageLoading();

        let currentUploadSizeValueElement = document.querySelector('.uploadArtworkFromTable[artwork-upload-button-for="'+sideName+'"] .currentUploadSizeValue');
        let finalUploadSizeValueElement = document.querySelector('.uploadArtworkFromTable[artwork-upload-button-for="'+sideName+'"] .finalUploadSizeValue');
        let progressBarLineElement = document.querySelector('.uploadArtworkFromTable[artwork-upload-button-for="'+sideName+'"] .filestackUploadProgressBarLine');
        let progressBarContainerElement = document.querySelector('.uploadArtworkFromTable[artwork-upload-button-for="'+sideName+'"] .filestackUploadProgressBar');
        let uploadArtworkButton = document.querySelector('.uploadArtworkFromTable[artwork-upload-button-for="'+sideName+'"]');


        // VERSION FOR TESTING WITHOUT FILESTACK

        // let imageObjectFromFilestackServer = {
        //     url: "https://cdn.filestackcontent.com/WHiDZqJR1GNEe6YrLokc",
        //     // url: "https://cdn.filestackcontent.com/U49YxVqAQ3GvPEBkbbHd",
        //     name: "Rose"
        // }
        //
        // this.setState({
        //     activeProductSide: sideName
        // }, () => {
        //     this.uploadImageFileOnCanvas(imageObjectFromFilestackServer);
        // });
        //

        // VERSION WITH FILESTACK

        // convert bytes value to readable version
        const bytesToSize = (bytes) => {

            let sizes = ['b', 'KB', 'MB', 'GB', 'TB'];

            if (bytes == 0) return '0 b';
            let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
            return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];

        }

        // show artwork upload progress bar
        const onProgress = (evt) => {

            currentUploadSizeValueElement.innerHTML = bytesToSize(evt.totalBytes);
            finalUploadSizeValueElement.innerHTML = bytesToSize(files[0].size);
            progressBarLineElement.setAttribute("style", `width: ${evt.totalPercent}%`);
            progressBarContainerElement.setAttribute("style", "display: block");
            uploadArtworkButton.setAttribute("style", "border: none !important");

        };



        filestackClient.upload(files[0], {onProgress})
            .then(res => {

                let imageObjectFromFilestackServer;

                if (res.type === "application/pdf" || res.type === "image/vnd.adobe.photoshop" || res.type === "application/postscript") {

                    let options = {
                        "output": {
                            "format": "png",
                            "secure": true
                        }
                    }

                    let transformedFileToPngURL = filestackClient.transform(res.handle, options);

                    imageObjectFromFilestackServer = {
                        url: transformedFileToPngURL,
                        name: res.filename
                    }

                } else if (res.type === "image/png" || res.type === "image/jpeg" || res.type === "image/jpg" || res.type === "image/bmp" || res.type === "image/gif" ) {

                    imageObjectFromFilestackServer = {
                        url: res.url,
                        name: res.filename
                    }

                } else {

                    this.uploadingImageIsLoaded();

                    progressBarContainerElement.setAttribute("style", "display: none");
                    uploadArtworkButton.setAttribute("style", "border: 1px solid #F1F4F8 !important");

                    $('#artworkUploadErrorModalButton')[0].click();


                }


                this.setState({
                    activeProductSide: sideName
                }, () => {
                    this.uploadImageFileOnCanvas(imageObjectFromFilestackServer);
                });



            })
            .catch(err => {

                this.uploadingImageIsLoaded();
                console.log(err);

                progressBarContainerElement.setAttribute("style", "display: none");
                uploadArtworkButton.setAttribute("style", "border: 1px solid #F1F4F8 !important");

                $('#artworkUploadErrorModalButton')[0].click();


            });



    }



    renderUploadImageInfo(sideName) {

        if ( this.state.productImagesObject[sideName].upload_image_object ) {

            return (

                <div>
                    <img src={this.state.productImagesObject[sideName].upload_image_object.url} alt="uploaded artwork" />
                    <span className="itemSideUploadedImageName">{this.state.productImagesObject[sideName].upload_image_object.name}</span>
                    <span onClick={this.deleteUploadImageFromSide.bind(this, sideName)} className="icon-close deleteUploadImageButton" title="delete image"></span>
                </div>

            )

        } else {

            return (


                <button className="uploadArtworkFromTable" artwork-upload-button-for={sideName}>

                    <span className="icon-arrow-down"></span> Upload <span className="desktop-only">artwork</span>
                    <input
                        type="file"
                        onChange={ (e) => this.uploadArtworkToFilestack(sideName, e.target.files) }
                        accept="image/png,image/jpeg,image/bmp,image/gif,image/*,application/pdf,image/vnd.adobe.photoshop,.psd,application/postscript"
                    />

                    <div className="filestackUploadProgressBar">
                        <div className="filestackUploadProgressBarLine"></div>

                        <div className="filestackUploadProgressBarSizeValue">
                            <span className="currentUploadSizeValue">0 KB</span>
                            of
                            <span className="finalUploadSizeValue">0 MB</span>
                        </div>

                    </div>

                </button>

            )

        }

    }



    deleteUploadImageFromSide(sideName) {

        this.setState(prevState => ({
            productImagesObject: {
                ...prevState.productImagesObject,
                [`${sideName}`] : {
                    ...prevState.productImagesObject[`${sideName}`],
                    upload_image_object: null,
                    // canvasScreenshotBase64: null,
                    coordX: 160,
                    coordY: 130,
                    scaleX: 1,
                    scaleY: 1,
                    rotation: 0
                }
            }
        }));

    }



    productImagesScreenshotsIsDone(productImagesScreenshotsArray) {

        // console.log(productImagesScreenshotsArray);

        this.setState({ productImagesScreenshotsArray: productImagesScreenshotsArray }, () => {

            if ( !this.state.isProductEditing ) {

                this.addToCart();

             } else {

                this.saveProductAfterEditing();

            }


        });

    }


    // TODO: Зробити більш естетичні функції для валідації поточного крока
    getProductImagesScreenshots() {

        let inputsErrorCount = 0;

        if ( this.props.activeServiceType === 'screen printing' ) {

            let productImagesObject = this.state.productImagesObject;
            // let totalProducts = this.getTotalProducts();
            // let sizesTotalNumberDOMelement = document.querySelectorAll('[name=sizes-total-number]')[0];

            // Перевірити чи є пусті селектори з кількістю кольорів при загружених артворках,
            // якщо є то я рахую в змінну inputsErrorCount їх кількість, для подальшої перевірки
            for (let key in productImagesObject) {

                if (productImagesObject.hasOwnProperty(key)) {

                    let sideColorDropdownWithArtwork = document.querySelectorAll('[name=' + key.replace(/\s/g,'') + ']')[0];

                    if ( productImagesObject[key].upload_image_object !== null ) {

                        if( parseInt(sideColorDropdownWithArtwork.value) === 0 ) {

                            inputsErrorCount++;
                            sideColorDropdownWithArtwork.classList.add('errorColorNumberInput', 'animated', 'heartBeat');

                            // scroll to error element
                            $('body,html').animate(
                                {
                                    scrollTop: $('.errorColorNumberInput').offset().top - 140

                                }, 0
                            );

                            setTimeout(function () {

                                sideColorDropdownWithArtwork.classList.remove( 'animated', 'heartBeat');

                            }, 1000);

                        } else {

                            sideColorDropdownWithArtwork.classList.remove('errorColorNumberInput', 'animated', 'heartBeat');

                        }

                    } else {

                        sideColorDropdownWithArtwork.classList.remove('errorColorNumberInput', 'animated', 'heartBeat');

                    }

                }
            }

        }

        // почати скріншот канвасів і добавлення продукту в корзину або оновлення вже існуючого
        if ( inputsErrorCount === 0 ) {

            this.setState({
                getProductImagesScreenshots: true,
                isPageLoaderActive: true
            });

        }

    }



    colorsAmountValidation() {

        for (let key in this.state.sidesColorsAmountObject) {

            if ( this.state.sidesColorsAmountObject[key] > 0 ) {

                return true;

            }
        }

        return false;

    }


    onSwitchAdvancedSettings(checked) {

        this.setState({ isAdvancedSettingsActive: checked });

    }


    onStartProductImageLoading() {

        this.setState({ isProductImageLoaderActive: true });

    }



    goToCartPage() {

        this.props.history.push('/cart');

    }



    renderProductSlider(imagesObject, isMobile) {

        if ( imagesObject !== null ) {

            let imagesArray = [];

            if ( !isMobile ) {

                imagesObject.map((image) => {

                    imagesArray.push({
                        original: image,
                        thumbnail: image
                    });

                    return null;

                });

            } else {

                imagesObject.map((image) => {

                    imagesArray.push({
                        original: image.side_image_url,
                        thumbnail: image.side_image_url
                    });

                    return null;

                });

            }

            return (

                <ImageGallery
                    showPlayButton={false}
                    showFullscreenButton={false}
                    items={imagesArray}
                />

            )

        } else {

            return null;

        }



    }


    //TODO: переробити через state як на сторінці cart , щоб не рендерилося постійно
    renderPriceBreaksTable() {

        let productBasePrice = this.props.productObjectForCalculation.product_object.product_base_price;

        return PRICE_BREAKS_QTY_ARRAY.map((item, index) => {

            let currentPrice = calculateCurrentProductPriceOfAmountForPriceBreaks(item, productBasePrice, this.state.sidesColorsAmountObject, this.state.activeServiceType);

            let minimumPcsOrderPrice = 0;
            if (this.state.activeServiceType === 'embroidery') {

                minimumPcsOrderPrice = calculateCurrentProductPriceOfAmountForPriceBreaks(MINIMUM_NUMBER_OF_PRODUCTS_FOR_EMBROIDERY, productBasePrice, this.state.sidesColorsAmountObject, this.state.activeServiceType);
            } else {
                minimumPcsOrderPrice = calculateCurrentProductPriceOfAmountForPriceBreaks(PRICE_BREAKS_QTY_ARRAY[0], productBasePrice, this.state.sidesColorsAmountObject, this.state.activeServiceType);
            }
            let savingValue =  ( (1 - ( currentPrice / minimumPcsOrderPrice ) ) * 100 ).toFixed(0);

            return (

                <tr key={index}>
                    <td>{ item }</td>
                    <td>${ calculateCurrentProductPriceOfAmountForPriceBreaks(item, productBasePrice, this.state.sidesColorsAmountObject, this.state.activeServiceType) }</td>
                    <td>{ savingValue }%</td>
                </tr>

            )

        });

    }



    getCurrentVolumeSavingByTotalOfProduct() {

        let productBasePrice = this.props.productObjectForCalculation.product_object.product_base_price;
        let currentPrice = calculateCurrentProductPriceOfAmountForPriceBreaks(this.getTotalProducts(), productBasePrice, this.state.sidesColorsAmountObject, this.state.activeServiceType);

        let minimumPiecesOrderPrice = 0;
        if (this.state.activeServiceType === 'embroidery') {
            minimumPiecesOrderPrice = calculateCurrentProductPriceOfAmountForPriceBreaks(6, productBasePrice, this.state.sidesColorsAmountObject, this.state.activeServiceType);
        } else {
            minimumPiecesOrderPrice = calculateCurrentProductPriceOfAmountForPriceBreaks(12, productBasePrice, this.state.sidesColorsAmountObject, this.state.activeServiceType);
        }


        return ( (1 - ( currentPrice / minimumPiecesOrderPrice ) ) * 100 ).toFixed(0);

    }



    renderOnModelGallery(onModelGalleryArrayByActiveColor) {


        if ( onModelGalleryArrayByActiveColor !== null && onModelGalleryArrayByActiveColor ) {

            let imagesArray = [];

            onModelGalleryArrayByActiveColor.map((image) => {

                imagesArray.push({
                    original: image.sizes.large,
                    thumbnail: image.sizes.medium
                });

                return null;

            });

            return (

                <ImageGallery
                    showPlayButton={true}
                    showFullscreenButton={true}
                    items={imagesArray}
                    lazyLoad={true}
                    autoPlay={true}
                    slideDuration={800}
                    slideInterval={5000}
                />

            )

        } else {

            return null;

        }


    }



    generateURLForSavedOrderData() {

        // show fetch loader
        this.setState({ isUrlForSavedOrderDataLoading: true })

        let urlForSavedOrderData = fetch("https://garagechamps.com/wp-json/quoteapi/saveforlater", {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                product_slug: this.props.productObjectForCalculation.product_object.slug,
                order_data: {
                    state: this.state
                }
            })
        });


        return urlForSavedOrderData
            .then(response => {

                return response.json();

            })
            .then(json => {

                this.setState({
                    urlForSavedOrderData: json,
                    isUrlForSavedOrderDataLoading: false
                });

            });

    }



    saveForLaterOnEmail() {


        let emailReg = /^(([^<>() [\]\\.,;:\s@"]+(\.[^<>() [\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;


        if ( emailReg.test(String(this.saveUrlToEmailInput.current.value).toLowerCase()) ) {

            this.setState({
                isOrderDataSaveOnEmailLoading: true
            });

            console.log(this.state.urlForSavedOrderData)
            console.log(JSON.stringify(this.state.urlForSavedOrderData))

            let testLessonObject = fetch("https://garagechamps.com/wp-json/quoteapi/saveforlater-send-email2", {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    userEmail: this.saveUrlToEmailInput.current.value,
                    savedOrderId: this.state.urlForSavedOrderData,
                    savedOrderUrl: "https://quote.garagechamps.com/saved/" + this.state.urlForSavedOrderData
                })
            });


            return testLessonObject
                .then(response => {

                    return response.json();

                })
                .then(json => {

                    this.setState({
                        isOrderDataSaveOnEmailLoading: false
                    });

                    document.querySelector('.saveUrlToEmailButton').classList.add("sending-done");

                });

        } else {

            alert('invalid email');

        }


    }



    copyToClipboard() {

        /* Select the text field */
        this.copyToClipboardInput.current.select();
        this.copyToClipboardInput.current.setSelectionRange(0, 99999); /*For mobile devices*/

        /* Copy the text inside the text field */
        document.execCommand("copy");

    }



    onCanvasTransformerChanges() {

        this.productCanvasPanelRef.current.getScreenshotOfMainCanvasBySingleSide();

    }




    render() {

        // console.log(this.state);

        // if (this.state.isUploadingImageLoaded && this.state.isProductImageLoaded) {
        //
        //     // TODO перевірка якщо загрузилися картинки
        //
        // }


        // initialize content version type by screen width
        const { screenWidth } = this.state;
        const isMobileVersion = screenWidth < 992;





        // if user open product page with undefined product id , show PageNotFound component
        if ( this.props.errorsLog.isFailFetchSingleProduct ) {

            return (

                <PageNotFound />

            )

        }



        if ( this.state.isSingleProductObjectReadyForRendering === true ) {

            let productSizesArray = this.props.productObjectForCalculation.product_object.product_sizes;
            let productObject = this.props.productObjectForCalculation.product_object.available_color_styles;
            let sidesArrayByActiveColor = [];
            let onModelGalleryArrayByActiveColor = [];
            let mainProductImageUrl;
            let productImagesObject = this.state.productImagesObject;
            let uploadImageObject = {};


            // get all product images by color from server object

            productObject.map((color) => {

                if ( this.state.activeProductColor === color.color_name ) {

                    sidesArrayByActiveColor = color.color_sides_images;
                    onModelGalleryArrayByActiveColor = color.color_model_images;

                    sidesArrayByActiveColor.map((side) => {

                        if ( this.state.activeProductSide === side.side_name ) {

                            mainProductImageUrl = side.side_image_url;

                        }

                        return null;

                    });

                }

                return null;

            });

            // get active product image by side

            for (let key in productImagesObject) {

                if ( this.state.activeProductSide === key ) {

                    uploadImageObject = productImagesObject[key].upload_image_object;

                }

            }


            // console.log(this.state);




            return (

                <div className="stepEditProduct">

                    <Helmet>
                        <title>{this.props.productObjectForCalculation.product_object.product_name} | GarageChamps</title>
                        <meta name="description" content="GarageChamps App" />
                    </Helmet>


                    { ( !isMobileVersion )

                        ?
                            // START DESKTOP VERSION ////////////////////////////////////////////////
                            <div className="container desktopVersionContainer">

                                <div className="row">

                                    <div className="singleProductInfoPart col-md-7 order-2 order-md-1">


                                        <ProductTitles
                                            productObject={this.props.productObjectForCalculation.product_object}
                                            isProductEditing={this.state.isProductEditing}
                                        />

                                        <ProductSidesPanel
                                            activeServiceType={this.state.activeServiceType}
                                            advancedSettingsObject={this.state.advancedSettingsObject}
                                            sidesArrayByActiveColor={sidesArrayByActiveColor}
                                            isAdvancedSettingsActive={this.state.isAdvancedSettingsActive}
                                            changeActiveServiceType={this.changeActiveServiceType}
                                            colorsAmountValidation={this.colorsAmountValidation}
                                            changeActiveSide={this.changeActiveSide}
                                            changeArtWidth={this.changeArtWidth}
                                            renderUploadImageInfo={this.renderUploadImageInfo}
                                            renderInkDropdownOptions={this.renderInkDropdownOptions}
                                            changeItemInkColorBySide={this.changeItemInkColorBySide}
                                            onSwitchAdvancedSettings={this.onSwitchAdvancedSettings}
                                            //generateURLForSavedOrderData={this.generateURLForSavedOrderData}
                                        />


                                        <div className="row productOptionsContainer spr-panel">

                                            <ProductColorsPanel
                                                activeProductColor={this.state.activeProductColor}
                                                activeProductColorHEX={this.state.activeProductColorHEX}
                                                renderColorsButtonsList={this.renderColorsButtonsList}
                                            />

                                            <ProductQuantityAndSizePanel
                                                activeServiceType={this.state.activeServiceType}
                                                productSizesArray={productSizesArray}
                                                renderSizesInput={this.renderSizesInput}
                                                getTotalProducts={this.getTotalProducts}
                                            />

                                        </div>


                                        <div className="row">

                                            <ProductPricePerItemPanel
                                                activeServiceType={this.state.activeServiceType}
                                                getTotalProducts={this.getTotalProducts}
                                                colorsAmountValidation={this.colorsAmountValidation}
                                                getSingleProductPrice={this.getSingleProductPrice}
                                            />

                                            {(this.state.activeServiceType==="embroidery")?
                                                <div className="embroideryPriceNotice">*Pricing is based on a maximum design size of 5” x 5”.  For larger designs please inquire <a href="https://garagechamps.com/#contact" target="_blank">here</a>.</div>
                                                : ""
                                            }

                                            <ProductTotalPricePanel
                                                activeServiceType={this.state.activeServiceType}
                                                isProductEditing={this.state.isProductEditing}
                                                deliveryTypeDate={this.props.deliveringTypeObject.chosen_delivery_type_date_estimate}
                                                deliveryTypeCoefficient={this.props.deliveringTypeObject.delivering_coefficient}
                                                colorsAmountValidation={this.colorsAmountValidation}
                                                getTotalProducts={this.getTotalProducts}
                                                getTotalProductsPrice={this.getTotalProductsPrice}
                                                getCurrentVolumeSavingByTotalOfProduct={this.getCurrentVolumeSavingByTotalOfProduct}
                                                getProductImagesScreenshots={this.getProductImagesScreenshots}
                                                cancelEditingProduct={this.cancelEditingProduct}
                                            />

                                        </div>

                                    </div>

                                    <div className="singleProductCanvasPart col-md-5 order-1 order-md-2 stepEditRightPart">

                                        <ProductCanvasPanel
                                            ref={this.productCanvasPanelRef}
                                            activeProductSide={this.state.activeProductSide}
                                            activeProductColor={this.state.activeProductColor}
                                            sidesArrayByActiveColor={sidesArrayByActiveColor}
                                            uploadImageObject={uploadImageObject}
                                            mainProductImageUrl={mainProductImageUrl}
                                            uploadImageObject={uploadImageObject}
                                            isProductImageLoaderActive={this.state.isProductImageLoaderActive}
                                            renderSidesButtonsList={this.renderSidesButtonsList}
                                            renderFileUploadButton={this.renderFileUploadButton}
                                            handleTransformerMethodForDraggableImage={this.handleTransformerMethodForDraggableImage}
                                            productImageIsLoaded={this.productImageIsLoaded}
                                            onStartProductImageLoading={this.onStartProductImageLoading}
                                            renderDraggableImageOnProductComponent={this.renderDraggableImageOnProductComponent}
                                            generateURLForSavedOrderData={this.generateURLForSavedOrderData}
                                        />

                                        <button className="artworkGuidelinesButton" data-toggle="modal" data-target="#artworkGuidelinesModal">Artwork guidelines</button>

                                    </div>


                                </div>

                            </div>
                            // END DESKTOP VERSION //////////////////////////////////////////////////

                        :
                            // START MOBILE VERSION /////////////////////////////////////////////////
                            <div className="container mobileVersionContainer">

                                <div className="row">

                                    <div className="col-12">

                                        <div className="row">
                                            <ProductTitles
                                                productObject={this.props.productObjectForCalculation.product_object}
                                                isProductEditing={this.state.isProductEditing}
                                            />
                                        </div>

                                        <div className="row">
                                            <ProductSidesSlider
                                                sidesArrayByActiveColor={sidesArrayByActiveColor}
                                                isProductImageLoaderActive={this.state.isProductImageLoaderActive}
                                                renderSidesButtonsList={this.renderSidesButtonsList}
                                                renderFileUploadButton={this.renderFileUploadButton}
                                                productImageIsLoaded={this.productImageIsLoaded}
                                                onStartProductImageLoading={this.onStartProductImageLoading}
                                                generateURLForSavedOrderData={this.generateURLForSavedOrderData}
                                            />
                                        </div>

                                        <div className="row">
                                            <ProductColorsPanel
                                                activeProductColor={this.state.activeProductColor}
                                                activeProductColorHEX={this.state.activeProductColorHEX}
                                                renderColorsButtonsList={this.renderColorsButtonsList}
                                            />
                                        </div>

                                        <div className="row">
                                            <ProductSidesPanel
                                                activeServiceType={this.state.activeServiceType}
                                                advancedSettingsObject={this.state.advancedSettingsObject}
                                                sidesArrayByActiveColor={sidesArrayByActiveColor}
                                                isAdvancedSettingsActive={this.state.isAdvancedSettingsActive}
                                                changeActiveServiceType={this.changeActiveServiceType}
                                                colorsAmountValidation={this.colorsAmountValidation}
                                                changeActiveSide={this.changeActiveSide}
                                                changeArtWidth={this.changeArtWidth}
                                                renderUploadImageInfo={this.renderUploadImageInfo}
                                                renderInkDropdownOptions={this.renderInkDropdownOptions}
                                                changeItemInkColorBySide={this.changeItemInkColorBySide}
                                                onSwitchAdvancedSettings={this.onSwitchAdvancedSettings}
                                            />
                                        </div>

                                        <div className="row">
                                            <ProductQuantityAndSizePanel
                                                activeServiceType={this.state.activeServiceType}
                                                productSizesArray={productSizesArray}
                                                renderSizesInput={this.renderSizesInput}
                                                getTotalProducts={this.getTotalProducts}
                                            />
                                        </div>

                                        <div className="row">
                                            <ProductPricePerItemPanel
                                                activeServiceType={this.state.activeServiceType}
                                                getTotalProducts={this.getTotalProducts}
                                                colorsAmountValidation={this.colorsAmountValidation}
                                                getSingleProductPrice={this.getSingleProductPrice}
                                            />
                                        </div>

                                        <div className="row">
                                            <ProductTotalPricePanel
                                                activeServiceType={this.state.activeServiceType}
                                                isProductEditing={this.state.isProductEditing}
                                                deliveryTypeDate={this.props.deliveringTypeObject.chosen_delivery_type_date_estimate}
                                                deliveryTypeCoefficient={this.props.deliveringTypeObject.delivering_coefficient}
                                                colorsAmountValidation={this.colorsAmountValidation}
                                                getTotalProducts={this.getTotalProducts}
                                                getTotalProductsPrice={this.getTotalProductsPrice}
                                                getCurrentVolumeSavingByTotalOfProduct={this.getCurrentVolumeSavingByTotalOfProduct}
                                                getProductImagesScreenshots={this.getProductImagesScreenshots}
                                                cancelEditingProduct={this.cancelEditingProduct}
                                            />
                                        </div>

                                    </div>

                                </div>

                            </div>
                            // END MOBILE VERSION /////////////////////////////////////////////////

                    }






                    {/*{ SAVE PRODUCT MODAL START }*/}
                    <div className="modal fade messageModal" id="saveProductModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <div className="afterAddedToCartModalTitle">Warning!</div>
                                    <span className="icon-close" data-dismiss="modal" aria-label="Close"></span>
                                </div>
                                <div className="modal-body deleteModalBody">
                                    Data will be lost if you do not save !
                                </div>
                                <div className="modal-footer deleteModalButtonsContainer">
                                    <button className="saveModalCancelButton" onClick={this.cancelEditingProduct} type="button" data-dismiss="modal">Cancel Editing</button>
                                    <button className="saveModalSavButton" onClick={this.getProductImagesScreenshots} type="button" data-dismiss="modal">Save product</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*{ SAVE PRODUCT MODAL END }*/}


                    {/* MODAL PRICE BREAKS START */}
                    <div className="modal fade" id="priceBreaksModal" tabIndex="-1" role="dialog" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <div className="priceBreaksModalTitle">Order big, save even bigger!</div>
                                    <span className="icon-close" data-dismiss="modal" aria-label="Close"></span>
                                </div>
                                <div className="modal-body">
                                    <div className="priceBreaksModalDescription">
                                        Based on your currently selected ink color amounts, here's how much a single unit will cost depending on how many of them you order.
                                    </div>

                                    <table className="priceBreaksModalTable">
                                        <thead>
                                        <tr>
                                            <th>Units</th>
                                            <th>Cost (Each)</th>
                                            <th>Saving</th>
                                        </tr>
                                        </thead>
                                        <tbody>

                                        { this.renderPriceBreaksTable() }

                                        </tbody>
                                    </table>

                                    <div className="priceBreaksAccordionTitle">Money saving tips:</div>
                                    <div className="accordion" id="priceBreaksAccordion">
                                        <div className="card">
                                            <div className="card-header" id="heading_1">
                                                <h2 className="mb-0">
                                                    <button className="btn btn-link collapsed" type="button"
                                                            data-toggle="collapse" data-target="#collapse_1"
                                                            aria-expanded="false" aria-controls="collapse_1">
                                                        <span className="accordionPlusIndicator"></span>
                                                        Design for a single print location
                                                    </button>
                                                </h2>
                                            </div>

                                            <div id="collapse_1" className="collapse"
                                                 aria-labelledby="heading_1" data-parent="#priceBreaksAccordion">
                                                <div className="card-body">
                                                    The best way to save on cost is by sticking to a single print location. This usually means a front design only with nothing on the back or sleeves.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card">
                                            <div className="card-header" id="heading_2">
                                                <h2 className="mb-0">
                                                    <button className="btn btn-link collapsed" type="button"
                                                            data-toggle="collapse" data-target="#collapse_2"
                                                            aria-expanded="false" aria-controls="collapse_2">
                                                        <span className="accordionPlusIndicator"></span>
                                                        Reduce the amount of colors in your design
                                                    </button>
                                                </h2>
                                            </div>
                                            <div id="collapse_2" className="collapse" aria-labelledby="heading_2"
                                                 data-parent="#priceBreaksAccordion">
                                                <div className="card-body">
                                                    Aside from print locations, ink colors is the next biggest
                                                    variable when it comes to print cost.  The more colors we use,
                                                    the longer it takes to set up and print your job.
                                                    Single ink color designs will be the most cost effective
                                                    way to design your garments.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card">
                                            <div className="card-header" id="heading_3">
                                                <h2 className="mb-0">
                                                    <button className="btn btn-link collapsed" type="button"
                                                            data-toggle="collapse" data-target="#collapse_3"
                                                            aria-expanded="false" aria-controls="collapse_3">
                                                        <span className="accordionPlusIndicator"></span>
                                                        Pick the right garment.
                                                    </button>
                                                </h2>
                                            </div>
                                            <div id="collapse_3" className="collapse"
                                                 aria-labelledby="heading_3" data-parent="#priceBreaksAccordion">
                                                <div className="card-body">
                                                    We've sorted and labeled the most cost effective garments using our $$$ system. Try choosing a garment with two dollar signs ($$) or less.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card">
                                            <div className="card-header" id="heading_4">
                                                <h2 className="mb-0">
                                                    <button className="btn btn-link collapsed" type="button"
                                                            data-toggle="collapse" data-target="#collapse_4"
                                                            aria-expanded="false" aria-controls="collapse_4">
                                                        <span className="accordionPlusIndicator"></span>
                                                        Don't procrastinate
                                                    </button>
                                                </h2>
                                            </div>
                                            <div id="collapse_4" className="collapse"
                                                 aria-labelledby="heading_4" data-parent="#priceBreaksAccordion">
                                                <div className="card-body">
                                                    The best way to avoid any additional costs is to place your order 10 business days before your due date.  By getting the order in early you will avoid any last minute changes or rush fees.
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    {/* MODAL PRICE BREAKS END */}


                    {/* MODAL ARTWORK GUIDE START */}
                    <div className="modal fade" id="artworkGuidelinesModal" tabIndex="-1" role="dialog" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered" role="document">

                            { this.renderArtworkGuidelinesContent() }

                        </div>
                    </div>
                    {/* MODAL ARTWORK GUIDE END */}


                    {/* MODAL ARTWORK UPLOAD ERROR START */}
                    <button id="artworkUploadErrorModalButton" className="hiddenElement" data-toggle="modal" data-target="#artworkUploadErrorModal">-</button>

                    <div className="modal fade" id="artworkUploadErrorModal" tabIndex="-1" role="dialog" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered" role="document">

                            <div className="modal-content">
                                <div className="modal-header">
                                    <div className="guidelinesModalTitle modalErrorTitle">Oops, that didn't work</div>
                                    <span className="icon-close" data-dismiss="modal" aria-label="Close"></span>
                                </div>
                                <div className="modal-body">
                                    <div className="guidelinesModalDescription modalErrorDescription">
                                        <p>The preview mode doesn't support this picture format.<br/>
                                            It supports other files, such as
                                            <span> jpg, png, bmp, gif, pdf, psd, ai, eps</span>
                                        </p>
                                        <p>
                                            You will be able to upload any extra files you want us to see
                                            before submitting your order in the next steps
                                        </p>
                                    </div>

                                    <div className="iUnderstandButtonContainer"><button data-dismiss="modal" aria-label="Close">I understand</button></div>
                                </div>
                            </div>

                        </div>
                    </div>
                    {/* MODAL ARTWORK UPLOAD ERROR END */}


                    {/* MODAL AFTER ADDED PRODUCT TO CART START */}
                    <button id="afterAddedToCartModalButton" className="hiddenElement" data-toggle="modal" data-target="#afterAddedToCartModal">-</button>

                    <div className="modal fade" id="afterAddedToCartModal" tabIndex="-1" role="dialog" aria-hidden="true" data-backdrop="true" data-keyboard="true">
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                                <div className="modal-body">

                                    <span className="icon-close" data-dismiss="modal" aria-label="Close"></span>

                                    <div className="afterAddedToCartModalTitle">
                                        <span className="icon-ok"></span> Your shirt added to cart
                                    </div>

                                    <div className="afterAddedToCartModalBodyContainer">

                                        <div className="afterAddedToCartModalBody">

                                            <div id="afterAddedToCartModalSlider" className="previewProductSliderContainer">

                                                {   (!isMobileVersion)

                                                    ? this.renderProductSlider(this.state.productImagesScreenshotsArray, false)
                                                    : this.renderProductSlider(sidesArrayByActiveColor, true)
                                                }

                                            </div>

                                            <div className="afterAddedToCartModalInfoBox">

                                                <table className="afterAddedToCartModalTable">
                                                    <tbody>
                                                    <tr>
                                                        <td>Item:</td>
                                                        <td><strong>{this.props.productObjectForCalculation.product_object.product_name}</strong></td>
                                                    </tr>
                                                    <tr>
                                                        <td>Color:</td>
                                                        <td><span data-tip={this.state.activeProductColor} style={{backgroundColor: this.state.activeProductColorHEX}}></span>{this.state.activeProductColor}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Quantity:</td>
                                                        <td>{this.getTotalProducts()} pcs</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Price:</td>
                                                        <td>${this.getSingleProductPrice(this.state.activeServiceType).toFixed(2)}/shirt</td>
                                                    </tr>
                                                    </tbody>
                                                </table>

                                            </div>

                                        </div>

                                        <button data-dismiss="modal" aria-label="Close" onClick={this.goToCartPage.bind()} className="moveToCartInModal">
                                            <span className="icon-cart"></span> Checkout
                                        </button>
                                        {/*<button data-dismiss="modal" aria-label="Close" className="closeAfterAddedToCartModal"><span className="icon-plus"></span> Stay here to add another<br/> color with the same design</button>*/}

                                    </div>

                                </div>
                            </div>
                        </div>

                        {/* CART INDICATOR */}
                        <div className="container cartIndicatorModalContainer">
                            <div data-dismiss="modal" aria-label="Close" className="cartIndicatorModal" onClick={this.goToCartPage.bind()}>
                                <label className="my-btn-secondary active">
                                    <div className="stepNavItemCircle">3</div>
                                    <div className="stepNavItemName">CART</div>
                                </label>
                                <span className="cartIndicatorModalCircle"></span>
                            </div>
                        </div>

                    </div>
                    {/* MODAL AFTER ADDED PRODUCT TO CART START */}


                    {/* TODO: зробити перевірку загрузки галереї лише при відкритті модалки а не при рендері постійно */}
                    {/* MODAL ON MODEL GALLERY START */}
                    <div className="modal fade" id="onModelGalleryModal" tabIndex="-1" role="dialog" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <div className="priceBreaksModalTitle">Model Gallery</div>
                                    <span className="icon-close" data-dismiss="modal" aria-label="Close"></span>
                                </div>
                                <div className="modal-body">

                                    { this.renderOnModelGallery(onModelGalleryArrayByActiveColor) }

                                </div>
                            </div>
                        </div>
                    </div>
                    {/* MODAL ON MODEL GALLERY END */}



                    {/* MODAL PRODUCT DESCRIPTION START */}
                    <div className="modal fade" id="productSpecificationsModal" tabIndex="-1" role="dialog" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <div className="priceBreaksModalTitle">Product specifications</div>
                                    <span className="icon-close" data-dismiss="modal" aria-label="Close"></span>
                                </div>
                                <div className="modal-body">

                                    <p
                                        dangerouslySetInnerHTML={{__html:
                                                DOMPurify.sanitize(this.props.productObjectForCalculation.product_object.product_description)}}
                                    ></p>

                                </div>
                            </div>
                        </div>
                    </div>
                    {/* MODAL PRODUCT DESCRIPTION END */}


                    {/* MODAL SAVE FOR LATER START */}
                    <div className="modal fade" id="saveForLaterModal" tabIndex="-1" role="dialog" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered" role="document">

                            <div className="modal-content">
                                <div className="modal-header">
                                    <div className="guidelinesModalTitle saveForLaterModalTitle">Save for later</div>
                                    <span className="icon-close" data-dismiss="modal" aria-label="Close"></span>
                                </div>
                                <div className="modal-body">

                                    <div className="saveForLaterModalBody">

                                        <div className="saveForLaterImageBlock">
                                            <img src={saveForLaterImg} alt="save for later" />
                                        </div>

                                        <div className="saveForLaterSubTitle">Let’s save your order for later!</div>
                                        <div className="saveForLaterDescription">
                                            Saving a design for later makes it easier to start where you left.
                                            Share an email address, and we’ll send you a link, or copy a link to share with others!
                                        </div>


                                        { ( !this.state.isUrlForSavedOrderDataLoading )

                                            ?

                                                <div className="saveForLaterInputsRow row">
                                                <div className="col-6">
                                                    <div className="saveForLaterInputTitle">Send link to:</div>
                                                    <div className="saveForLaterInputBlock">

                                                        <input
                                                            className="saveUrlToEmailInput"
                                                            type="email"
                                                            placeholder="Insert mail adress.."
                                                            ref={this.saveUrlToEmailInput}
                                                        />

                                                        <button onClick={this.saveForLaterOnEmail} className={`saveUrlToEmailButton ${ (this.state.isOrderDataSaveOnEmailLoading) ? 'is-sending' : null }`}>

                                                            <span>
                                                                <span className="sv-send">Send</span>
                                                                <span className="sv-done">Done</span>
                                                            </span>

                                                            <div className="saveOrderOnEmailLoader">
                                                                <Loader
                                                                    type="ThreeDots"
                                                                    color="#FFFFFF"
                                                                    height="30"
                                                                    width="30"
                                                                />
                                                            </div>

                                                        </button>

                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="saveForLaterInputTitle">Copy link:</div>
                                                    <div className="saveForLaterInputBlock copyToClipboardButtonBlock">
                                                        <input
                                                            className="copyToClipboardInput"
                                                            readOnly={true}
                                                            type="text"
                                                            ref={this.copyToClipboardInput}
                                                            onFocus={this.onFocusAllContentsOfInput}
                                                            value={"https://quote.garagechamps.com/saved/" + this.state.urlForSavedOrderData}
                                                        />
                                                        <button onClick={this.copyToClipboard} className="copyToClipboardButton">Copy to clipboard
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>

                                            :

                                                <div className="saveForLaterLoader">

                                                    <Loader
                                                        type="Oval"
                                                        color="#ED0084"
                                                        height="50"
                                                        width="50"
                                                    />

                                                </div>

                                        }

                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                    {/* MODAL SAVE FOR LATER END */}










                    {/*COMPONENT FOR SCREENSHOTTING PRODUCT IMAGES */}
                    { (this.state.getProductImagesScreenshots) ? <ProductsImagesScreenshot
                        productObject={productObject}
                        productImagesObject={this.state.productImagesObject}
                        activeProductColor={this.state.activeProductColor}
                        //activeProductGender={this.state.activeProductGender}
                        productImagesScreenshotsIsDone={this.productImagesScreenshotsIsDone}
                    /> : null }


                    {/*PAGE LOADER*/}
                    { (this.state.isPageLoaderActive) ? <div className="bigLoader">
                        <Loader
                            type="Oval"
                            color="#ED0084"
                            height="150"
                            width="150"
                        />
                        <div className="addToCartProgressBar">
                            Loading: <span>0%</span>
                        </div>
                    </div> : null }

                </div>

            )

        } else {

            return (

                <div className="stepEditProductLoader">

                    <Loader
                        type="Oval"
                        color="#ED0084"
                        height="50"
                        width="50"
                    />

                </div>

            )

        }


    }


}



export default withRouter(connect(
    state => ({
        errorsLog: state.errors_log,
        productsInCart: state.cart,
        productObjectForCalculation: state.product_option.product_object_for_calculation,
        productObjectForEditing: state.product_option.product_object_for_editing,
        deliveringTypeObject: state.delivering_options,
        productObjectFromAPI: state.product_option.product_object_from_API
    }),
    dispatch => ({
        onAddToCart: (productObject) => {
            dispatch(addToCart(productObject));
        },
        onSaveProductAfterEditing: (newCartList) => {
            dispatch(saveProductAfterEditing(newCartList));
        },
        onCancelEditingProduct: () => {
            dispatch(cancelEditingProduct());
        },
        onFetchSingleProduct: (slug) => {
            dispatch(fetchSingleProduct(slug));
        },
        onIsFailFetchSingleProduct: (boolean) => {
            dispatch(isFailFetchSingleProduct(boolean));
        }
    })
)(StepEditProduct));
