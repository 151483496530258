import {
    CHANGE_DELIVERING_TYPE
} from "../actions/actions";

let moment = require('moment-business-days');

const initialState = {
    delivering_coefficient: 1,
    delivering_type_name: 'Standard',
    delivering_type_days_amount: 10,
    chosen_delivery_type_date_estimate: moment(new Date()).businessAdd(10).format('MMMM D')
};



export function delivering_reducer(state = initialState, action) {

    switch (action.type) {
        case CHANGE_DELIVERING_TYPE :

            return {
                ...state,
                delivering_type_name: action.payload.delivering_type_name,
                delivering_coefficient: action.payload.delivering_type_coefficient,
                delivering_type_days_amount: action.payload.delivering_type_days_amount,
                chosen_delivery_type_date_estimate: moment(new Date()).businessAdd(action.payload.delivering_type_days_amount).format('MMMM D'),
            };

        default:

            return state
    }
}
