import React, { Component } from 'react';
import {Link} from "react-router-dom";





class GoToStartStepButton extends Component {


    render() {

        return (

            <div className="container">

                <div className="addNewProductButtonContainer row">
                    <Link to="/">
                        <div className="cartAddNewProductButton"><span className="icon-plus"></span> Add product with a new design</div>
                    </Link>
                </div>

            </div>

        )

    }


}

export default GoToStartStepButton;
