import {
    ADD_PRODUCT_FOR_CALCULATION,
    EDIT_PRODUCT_FROM_CART,
    SAVE_PRODUCT_AFTER_EDITING,
    CANCEL_EDITING_PRODUCT,
    FETCH_PRODUCTS,
    FETCH_SINGLE_PRODUCT
} from "../actions/actions";


const initialState = {
    product_object_from_API: null,
    product_object_for_calculation: null,
    product_object_for_editing: null
};



export function product_reducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_PRODUCTS :

            return { ...state, product_object_from_API: action.payload };

        case FETCH_SINGLE_PRODUCT :

            return { ...state, product_object_for_calculation: action.payload };

        case ADD_PRODUCT_FOR_CALCULATION :

            return { ...state, product_object_for_calculation: action.payload };

        case EDIT_PRODUCT_FROM_CART :

            return {
                ...state,
                product_object_for_calculation: action.payload.productMainData,
                product_object_for_editing: action.payload.productDataFromCart
            };

        case SAVE_PRODUCT_AFTER_EDITING :

            return {
                ...state,
                product_object_for_editing: null
            };

        case CANCEL_EDITING_PRODUCT :

            return {
                ...state,
                product_object_for_calculation: null,
                product_object_for_editing: null
            };

        default:

            return state
    }
}
