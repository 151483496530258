import React, { Component, useState, useEffect, useRef } from 'react';
import { useAuth0 } from "../../react-auth0-spa";
import './UserAccountHeaderPanel.css';
import {Link, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import { saveUserAccountData } from "../../actions/actions";
import { generateUserProfileAvatar } from "../../Helpers";

let sha256 = require('js-sha256');



function usePreviousUserAccountDataInStore(userAccountData) {

    const ref = useRef();

    useEffect(() => {

        ref.current = userAccountData;

    });

    return ref.current;

}



function UserAccountHeaderPanel(props) {

    const { user } = useAuth0();
    // const prevUserAccountDataInStore = usePreviousUserAccountDataInStore(props.userAccountData);


    useEffect(() => {


        if ( user ) {

            if ( !props.userAccountData ) {

                // TODO: два рази заходить сюди, чому ?

                let userAccountDataFormWp = fetch('https://garagechamps.com/wp-json/quoteapi/get-userprofile', {
                    method: "POST",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        "user_hash": sha256(user.email)
                    })
                });

                userAccountDataFormWp
                    .then((response) => {

                        return response.json();

                    })
                    .then((data) => {

                        //TODO: добавити лоавдер і перевірку
                        props.onSaveUserAccountData(data);

                    });

            }

        }

    });




    return (


        <div className="userAccountHeaderPanel">

            {(user)
                ?
                <div className="headerUserInformationBlock">
                    <div className="headerUserInformationBody">
                        <div className="headerUserInformationImage">
                            <Link to="/my-account"><img src={ generateUserProfileAvatar(user, props.userAccountData) } alt={user.name}/></Link>
                        </div>
                        <div className="headerUserInformationText">
                            <div className="headerUserInformationName" title={ ( props.userAccountData ) ? props.userAccountData.customer_first_name : "loading..." }>{ ( props.userAccountData ) ? "Hi, " + props.userAccountData.customer_first_name : "loading..." }</div>
                            <div className="headerLinkToMyOrders">
                                <Link to="/my-account">My Orders <span className="icon-right-big-arrow"></span></Link>
                            </div>
                        </div>
                    </div>
                </div>

                : <div className="headerUserInformationBlock not-authenticated">
                    <div className="desktopNotAuthBlock"><Link to="/my-account?triggerLogin=true">Log In</Link> | <Link to="/my-account/signup">Sign Up <span className="icon-arrow-big-right"></span></Link></div>
                    <Link to="/my-account?triggerLogin=true"><div className="mobileNotAuthBlock"><span className="icon-user"></span></div></Link>
                </div>
            }


        </div>

    )


}


export default withRouter(connect(
    state => ({
        userAccountData: state.user_account.user_account_data_object
    }),
    dispatch => ({
        onSaveUserAccountData: (newUserAccountDataObject) => {

            dispatch(saveUserAccountData(newUserAccountDataObject));

        }
    })
)(UserAccountHeaderPanel));
