import React, { Component, useState, useEffect } from 'react';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import '../../../node_modules/react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import './UserAccount.css';
import {Link, withRouter, useHistory} from "react-router-dom";
import { useAuth0 } from "../../react-auth0-spa";
import NavBar from './NavBar';
import checkYouOrderImg from '../../images/check-you-order.svg';
import Loader from "react-loader-spinner";
import { generateUserProfileAvatar } from "../../Helpers";
import UserProfileForm from "./UserProfileForm";
import {connect} from "react-redux";
import {saveUserAccountData} from "../../actions/actions";

// import '../../../node_modules/react-bootstrap-modal/lib/css/rbm-patch.css';
// let Modal = require('react-bootstrap-modal');

let sha256 = require('js-sha256');
let moment = require('moment');
const queryString = require('query-string');



function UserAccount(props) {

    const { loading, user, isAuthenticated, loginWithRedirect } = useAuth0();
    let history = useHistory();

    // init local state
    const [ordersArray, setOrdersArray] = useState();
    const [isOrdersArrayEmpty, setIsOrdersArrayEmpty] = useState(false);
    const parsedUrlParameters = queryString.parse(props.location.search);



    // imitation of componentDidUpdate()
    useEffect(() => {

        // IF USER COME FROM SETUP PAGE AND NOT AUTHENTICATED -> CALL LOGIN PAGE
        if ( !loading ) {

            if ( parsedUrlParameters.triggerLogin ) {

                loginWithRedirect();

            }

        }



        // IF USER IS AUTHENTICATED BUT NOT SETUP/SIGN UP -> REDIRECT TO SETUP PAGE
        if ( isAuthenticated && user && props.userAccountData ) {

            if ( props.userAccountData === '404, didn\'t find such a user' ) {

                console.log(user.email);

                //  TODO: доробити важливий функціонал
                // setTimeout(function () {
                //
                //     history.push({
                //         pathname: '/my-account/setup',
                //         search: '?hash='+sha256(user.email),
                //     });
                //
                // }, 1000);


            }

        }



        if (user && !ordersArray) {

            //TODO: тимчасова очистка URL після авторизації, переробити на слухання ?code
            window.history.pushState({}, document.title, "/" + "my-account");

            async function multipleFetchForGettingAllOrders(ordersIdArray) {

                console.log(ordersIdArray);

                const fetchText = url => fetch(url, {
                    method: "GET"
                })
                    .then((response) => {

                        return response.text().then(function(text) {
                            return text ? JSON.parse(text) : {}
                        })

                });



                let ordersDataArray = [];

                ordersIdArray.forEach((orderId) => {

                    ordersDataArray.push(fetchText('https://garagechamps.com/wp-json/quoteapi/fetch-order/' + orderId))

                });

                console.log(ordersDataArray);

                ordersIdArray = await Promise.all(ordersDataArray);

                setOrdersArray(ordersIdArray);

            }




            let ordersIdList = fetch('https://garagechamps.com/wp-json/quoteapi/get-orders', {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "user_hash": sha256(user.email) //user.email
                })
            });

            ordersIdList
                .then((response) => {

                    return response.json();

                })
                .then((data) => {

                    if ( data.length > 0 ) {

                        setIsOrdersArrayEmpty(false);
                        multipleFetchForGettingAllOrders(data);

                    } else {

                        setIsOrdersArrayEmpty(true);

                    }


                });

        }


    });



    function goToOrderPage(url) {

        window.open(url, '_blank');

    }




    function RenderOrdersTable() {

        if (ordersArray) {

            console.log(ordersArray);

            let ordersList = ordersArray.map((order, index) => {

                if (Object.keys(order).length !== 0) {

                    return (

                        <Tr key={index} onClick={goToOrderPage.bind(this, order.public_url)}>

                            <Td className="td-id">
                                <div>{order.visual_id}</div>
                                <div className="td-name" title="asdasda sd asd asd asd ">
                                    <span>{order.order_nickname}</span></div>
                            </Td>

                            {/*<Td className="td-name">*/}
                            {/*    */}
                            {/*</Td>*/}

                            <Td className="td-customer-name">
                                {order.customer.full_name}
                            </Td>

                            <Td className="td-cdate">
                                {moment(order.due_date).format("MMM D")}
                            </Td>

                            <Td className="td-outstanding">
                                <div>${order.amount_outstanding} | <span>${order.order_total.toFixed(2)}</span></div>
                            </Td>

                            <Td className="td-status">
                                <span className="order-status">{order.orderstatus.name}</span>
                                {(order.stats.paid) ? <span className="is-paid">PAID</span> :
                                    <span className="no-paid">NOT PAID</span>}
                            </Td>

                        </Tr>

                    )

                } else {

                    console.log('упсс json пролетів');

                }

            });


            return ordersList;


        } else {

            return null;

        }

    }




    return (



        <div className="userAccount">


            <div className="container">

                <div className="deliveringBreadcrumbsContainer userAccountBreadcrumbs">
                    <div className="deliveringBreadcrumbsName">MY ACCOUNT</div>
                    <Link to="/" className="backToCartButton"><span className="icon-arrow-right"></span> Back to catalog
                    </Link>
                </div>


                { ( loading )

                    ?

                        <div className="isUserAuthLoaderContainer row">

                            <div className="col-12">
                                <div className="authLoader">

                                    <span>Filling up the ink containers...</span>

                                    <Loader
                                        type="Oval"
                                        color="#ED0084"
                                        height="50"
                                        width="50"
                                    />

                                </div>

                            </div>

                        </div>

                    :

                        <div className="row">

                            <div className="col-3">

                                <div className="userAccountLeftPanel">

                                    { ( user )
                                        ?
                                        <div className="userInformationBlock">
                                            <div className="userInformationImage"><img src={ generateUserProfileAvatar(user, props.userAccountData) } alt={user.name}/></div>
                                            <div className="userInformationName">{ ( props.userAccountData ) ? props.userAccountData.customer_first_name+' '+props.userAccountData.customer_last_name : "loading..." }</div>


                                            { ( props.userAccountData )

                                                ?

                                                    <div className="userInformationBody">

                                                        <div className="editProfileBlock">
                                                            <button data-toggle="modal" data-target="#editProfileTypeModal">Edit Profile</button>
                                                        </div>

                                                        <div className="userInformationElement">
                                                            <span className="icon-mail"></span> { ( props.userAccountData ) ? props.userAccountData.customer_email : "loading..." }
                                                        </div>
                                                        <div className="userInformationElement">
                                                            <span className="icon-phone"></span> { ( props.userAccountData ) ? props.userAccountData.customer_phone_number : "loading..." }
                                                        </div>
                                                        <div className="userInformationElement">
                                                            <span className="icon-delivery"></span>
                                                            {

                                                                ( props.userAccountData )
                                                                    ?
                                                                    props.userAccountData.customer_address+' '+
                                                                    props.userAccountData.customer_city+', '+
                                                                    props.userAccountData.customer_state+' '+
                                                                    props.userAccountData.customer_zip_number
                                                                    :
                                                                    "loading..."

                                                            }

                                                        </div>

                                                    </div>

                                                :
                                                    <div className="userInformationBody">

                                                        <div className="authLoader">
                                                            <Loader
                                                                type="Oval"
                                                                color="#ED0084"
                                                                height="50"
                                                                width="50"
                                                            />
                                                        </div>

                                                    </div>

                                            }


                                        </div>

                                        : null
                                    }
                                    <NavBar/>

                                </div>

                            </div>


                            <div className="col-9">
                                <div className="userAccountBody">

                                    { (user && !isOrdersArrayEmpty)

                                        ?

                                            (ordersArray)

                                                ?

                                                <div>
                                                    <Table className="table">

                                                        <Thead className="thead-light">
                                                            <Tr>
                                                                <Th>ID | Name</Th>
                                                                {/*<Th>Name</Th>*/}
                                                                <Th>Customer</Th>
                                                                <Th>Due Date</Th>
                                                                <Th className="td-outstanding">Outstanding | <span>Total</span></Th>
                                                                <Th>Status</Th>
                                                            </Tr>
                                                        </Thead>

                                                        <Tbody>

                                                            {RenderOrdersTable()}

                                                        </Tbody>

                                                    </Table>
                                                </div>
                                                :
                                                <div className="authLoader">
                                                    <Loader
                                                        type="Oval"
                                                        color="#ED0084"
                                                        height="50"
                                                        width="50"
                                                    />
                                                </div>

                                            :

                                                <div className="noAuthenticatedBlock">
                                                    <img src={checkYouOrderImg} alt="Check You Order"/>
                                                    <div className="noAuthenticatedBlockTitle">
                                                        {
                                                            (isOrdersArrayEmpty)

                                                                ?
                                                                    <span>You don't have orders yet!<br/> If you believe this is a mistake <a target="_blank" href="https://garagechamps.com/#contact">contact</a> us or call  <a href="tel:+19166318470">916 - 631 - 8470</a>.</span>

                                                                :

                                                                    "Log in to see your orders!"
                                                        }
                                                    </div>
                                                </div>

                                    }

                                </div>
                            </div>

                        </div>

                }


            </div>




            {/* MODAL EDIT PROFILE START */}
            <div className="modal fade" id="editProfileTypeModal" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">

                        <div className="modal-header">
                            <div className="deliveringModalTitle">Edit Profile!</div>
                            <span className="icon-close" data-dismiss="modal" aria-label="Close"></span>
                        </div>

                        <div className="modal-body">

                            <UserProfileForm userAccountData={ props.userAccountData } onSaveUserAccountData={ props.onSaveUserAccountData }  formType="update-profile-data" />

                        </div>

                    </div>
                </div>
            </div>
            {/* MODAL EDIT PROFILE END */}

        </div>

    )


}



export default connect(
    state => ({
        userAccountData: state.user_account.user_account_data_object
    }),
    dispatch => ({
        onSaveUserAccountData: (newUserAccountDataObject) => {

            dispatch(saveUserAccountData(newUserAccountDataObject));

        }
    })
)(UserAccount);


