import React, { Component } from 'react';
import {
    MINIMUM_NUMBER_OF_PRODUCTS_FOR_EMBROIDERY,
    MINIMUM_NUMBER_OF_PRODUCTS_FOR_SCREEN_PRINTING
} from "../../../../../properties";



class ProductQuantityAndSizePanel extends Component {


    constructor(props) {

        super(props);

    }


    render() {



        return (

            <div className="productQuantityAndSizePanel">

                <div className="col-12 singleProductSizesAmountBlock">

                    <div className="singleProductSizesAmountTitle">QUANTITY AND SIZE:</div>

                    <div className="productSizeList">

                        { this.props.renderSizesInput() }

                        <div className="productSizeItem" style={{width: 100 / ( this.props.productSizesArray.length + 1 ) + '%' }}>

                            <label className="totalTitle">Total</label>

                            {
                                // перевіряємо тип друку
                                (this.props.activeServiceType === 'screen printing')

                                    ?   // якщо це "screen printing"

                                        <span name="sizes-total-number" className={(this.props.getTotalProducts() < MINIMUM_NUMBER_OF_PRODUCTS_FOR_SCREEN_PRINTING) ? 'errorColorNumberInput' : null }>

                                            { this.props.getTotalProducts() }
                                            <div className="totalPriceErrorMessage message-box-style">Minimum order is { MINIMUM_NUMBER_OF_PRODUCTS_FOR_SCREEN_PRINTING } products</div>

                                        </span>

                                    :   // якщо це "embroidery"

                                        <span name="sizes-total-number" className={(this.props.getTotalProducts() < MINIMUM_NUMBER_OF_PRODUCTS_FOR_EMBROIDERY) ? 'errorColorNumberInput' : null }>

                                            { this.props.getTotalProducts() }
                                            <div className="totalPriceErrorMessage message-box-style">Minimum order is { MINIMUM_NUMBER_OF_PRODUCTS_FOR_EMBROIDERY } products</div>

                                        </span>

                            }

                        </div>

                    </div>

                </div>

            </div>

        )

    }


}

export default ProductQuantityAndSizePanel;
