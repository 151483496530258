import {
    SAVE_USER_ACCOUNT_DATA
} from "../actions/actions";


const initialState = {
    user_account_data_object: null
};



export function user_account_reducer(state = initialState, action) {
    switch (action.type) {
        case SAVE_USER_ACCOUNT_DATA :

            return { ...state, user_account_data_object: action.payload };

        default:

            return state
    }
}
