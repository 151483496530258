import React, { Component } from 'react';
import './PageNotFound.css';
import { Link } from 'react-router-dom';
import GoToStartStepButton from "../StepsBody/GoToStartStepButton";


class PageNotFound extends Component {



    render() {


        return (


            <div className="pageNotFound">

                <div className="container">

                    <div className="pageNotFoundTitle">Opps, 404</div>
                    <div className="pageNotFoundSubitle">Page not found!</div>

                    <GoToStartStepButton />

                </div>

            </div>

        )

    }


}

export default PageNotFound;

