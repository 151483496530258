import reducer from "../reducers";
import {composeWithDevTools} from "redux-devtools-extension";
import {applyMiddleware, createStore} from "redux";
import thunk from "redux-thunk";


const store = createStore(reducer, composeWithDevTools(applyMiddleware(thunk)));

export default store;


