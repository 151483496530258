import React, { Component } from 'react';
import ReactTooltip from "react-tooltip";



class ProductColorsPanel extends Component {


    constructor(props) {

        super(props);

    }


    render() {



        return (

            <div className="productColorsPanel">

                <div className="col-12 colorsButtonsList">

                    <div className="colorsButtonsListTitle">COLOR SHOWN:
                        <div title={this.props.activeProductColor} className="activeColorButtonName">
                            <span style={{backgroundColor: this.props.activeProductColorHEX }}></span>
                            {this.props.activeProductColor}
                        </div>
                    </div>

                    <ul>
                        { this.props.renderColorsButtonsList() }
                    </ul>

                    <ReactTooltip place="top" type="light" effect="float"/>

                </div>

            </div>

        )

    }


}

export default ProductColorsPanel;
