// src/components/UserAccount/NavBar.js

import React from "react";
import { useAuth0 } from "../../react-auth0-spa";
import {Link} from "react-router-dom";

const NavBar = () => {
    const { isAuthenticated, loginWithRedirect, logout } = useAuth0();

    return (
        <div className="navBarContainer">
            {!isAuthenticated && (
                <ul className="userAccountNavigationList">
                    <li>
                        <button className="myAccountLogInButton" onClick={() => loginWithRedirect({})}>Log In</button>
                        <Link to="/my-account/signup"><button className="myAccountSignUpButton">Sign up</button></Link>
                    </li>
                </ul>
            )}

            {isAuthenticated &&
            <ul className="userAccountNavigationList">
                {/*<li className="active">My Orders</li>*/}
                <li>
                    <button className="authLogOutButton" onClick={() => logout()}>Log out</button>
                </li>
            </ul>}
        </div>
    );
};

export default NavBar;

{/*<button onClick={() => loginWithRedirect({})}>Log in</button>*/}
// {isAuthenticated && <button onClick={() => logout()}>Log out</button>}
