import React, { Component } from 'react';
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Loader from "react-loader-spinner";



class ProductSidesSlider extends Component {


    constructor(props) {

        super(props);

        this.renderProductSidesSliderItem = this.renderProductSidesSliderItem.bind(this);

    }



    componentDidUpdate(prevProps, prevState) {



    }


    renderProductSidesSliderItem() {

        return this.props.sidesArrayByActiveColor.map((item_side, index) => {

            return (

                <div className="productSidesSliderItem" key={index}>
                    <img src={item_side.side_image_url} alt={item_side.side_name} />
                </div>

            )

        });

    }




    render() {

        let settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 0
        };

        return (

            <div className="productSidesSliderPanel productCanvasPanel">

                <button onClick={this.props.generateURLForSavedOrderData} data-toggle="modal" data-target="#saveForLaterModal" className="saveForLaterButton">
                    <span className="icon-save"></span> Save order
                </button>

                <Slider className="productSidesSlider" {...settings}>

                    { this.renderProductSidesSliderItem() }

                </Slider>

                <div className="showOnModelModalButtonMob" data-toggle="modal" data-target="#onModelGalleryModal">
                    <span className="showOnModelModalButtonMobText">On model</span>
                    <span className="icon-arrow-right"></span>
                </div>

            </div>

        )

    }


}

export default ProductSidesSlider;
