import React from "react";
import { Stage, Transformer, Circle, Path } from "react-konva";



class TransformerHandler extends React.Component {

    componentDidMount() {

        this.checkNode();


    }


    componentDidUpdate() {

        this.checkNode();

    }


    checkNode() {

        const stage = this.transformer.getStage();
        const { selectedShapeName } = this.props;
        const selectedNode = stage.findOne("." + selectedShapeName);



        if (selectedNode) {

            this.transformer.attachTo(selectedNode);

        } else {

            this.transformer.detach();

        }

        this.transformer.getLayer().batchDraw();

    }


    clickDeleteArtworkButton = e => {

        this.props.deleteUploadImageFromSide(this.props.activeProductSide);

    };


    mouseOverDeleteArtworkButton = e => {

        document.body.style.cursor = 'pointer';
        this.transformerDeleteCircle.to({
            scaleX: 1.3,
            scaleY: 1.3,
            fill: "#051F59",
            duration: 0.2
        });

    };


    mouseOutDeleteArtworkButton = e => {

        if ( e.target.parent ) {

            document.body.style.cursor = 'default';
            this.transformerDeleteCircle.to({
                scaleX: 1.1,
                scaleY: 1.1,
                fill: "#F20086",
                duration: 0.2
            });

        }

    };


    onTransformEnd = e => {

        this.transformer.detach();
        this.props.onCanvasTransformerChanges();

    };



    render() {

        return (
            <Transformer
                ref={node => {
                    this.transformer = node;
                }}
                onTransformStart={this.onTransformStart}
                onTransformEnd={this.onTransformEnd}
                centeredScaling={true}
                borderStroke="#ED0084"
                anchorStroke="#ED0084"
                rotationSnaps={[0, 90, 180, 270]}
                enabledAnchors={['top-right', 'bottom-left', 'bottom-right']}
                boundBoxFunc={(function(oldBox, newBox) {
                    // width and height of the boxes are corresponding to total absolute width and height of all nodes combined
                    // so it includes scale of the node.
                    if (newBox.width < 20) {
                        return oldBox;
                    }
                    return newBox;
                })}
            >
                <Circle
                    ref={node => {
                        this.transformerDeleteCircle = node;
                    }}
                    onMouseDown={this.clickDeleteArtworkButton}
                    onMouseOver={this.mouseOverDeleteArtworkButton}
                    onMouseLeave={this.mouseOutDeleteArtworkButton}
                    x={0}
                    y={0}
                    radius={10} fill="#ED0084" />
                <Path
                    onMouseDown={this.clickDeleteArtworkButton}
                    onMouseOver={this.mouseOverDeleteArtworkButton}
                    onMouseLeave={this.mouseOutDeleteArtworkButton}
                    x={-5}
                    y={-5}
                    scaleX={1.1}
                    scaleY={1.1}
                    fill="white"
                    data={"M4.5 2.97866L7.16521 0.31296C7.58406 -0.105971 8.26287 -0.103781 8.6829 0.316324C9.10585 0.739359 9.10443 1.41605 8.68626 1.8343L6.02105 4.5L8.68626 7.1657C9.10443 7.58395 9.10585 8.26064 8.6829 8.68368C8.26287 9.10378 7.58406 9.10597 7.16521 8.68704L4.5 6.02134L1.83479 8.68704C1.41594 9.10597 0.737131 9.10378 0.317104 8.68368C-0.105851 8.26064 -0.104429 7.58395 0.313741 7.1657L2.97895 4.5L0.313741 1.8343C-0.104429 1.41605 -0.105851 0.739359 0.317104 0.316324C0.737131 -0.103781 1.41594 -0.105971 1.83479 0.31296L4.5 2.97866Z"}
                ></Path>

            </Transformer>
        );
    }

}


export default TransformerHandler;
