import {
    UPDATE_ARRAYS_UPLOADED_FILES,
    UPDATE_OBJECT_USER_ORDER_DATA
} from "../actions/actions";


const initialState = {
    uploaded_files_array: null,
    user_order_data: null
};



export function order_reducer(state = initialState, action) {
    switch (action.type) {
        case UPDATE_ARRAYS_UPLOADED_FILES :

            return { ...state, uploaded_files_array: action.payload };

        case UPDATE_OBJECT_USER_ORDER_DATA :

            return { ...state, user_order_data: action.payload };

        default:

            return state
    }
}
