import React, { Component } from 'react';
import './StepCart.css';
import Files from 'react-files';
import { connect } from "react-redux";
import * as filestack from 'filestack-js';
import $ from "jquery";
import { calculateCurrentProductPriceOfAmountForPriceBreaks } from "../../../Helpers";
import {
    deleteProductFromCart,
    editProductFromCart,
    changeDeliveringType,
    updateArraysUploadedFiles,
    updateObjectUserOrderData,
    resetCartList
} from "../../../actions/actions";
import ImageGallery from 'react-image-gallery';
import {Link, withRouter} from 'react-router-dom';

import {
    STANDARD_DELIVERING_COEFFICIENT,
    STANDARD_DELIVERING_DAYS_AMOUNT,
    RAPID_DELIVERING_COEFFICIENT,
    RAPID_DELIVERING_DAYS_AMOUNT,
    FULL_THROTTLE_DELIVERING_COEFFICIENT,
    FULL_THROTTLE_DELIVERING_DAYS_AMOUNT,
    PRICE_BREAKS_QTY_ARRAY
} from "../../../properties";

import "react-image-gallery/styles/css/image-gallery.css";
import Loader from "react-loader-spinner";
import DOMPurify from 'dompurify';
import GoToStartStepButton from "../GoToStartStepButton";
import ReactTooltip from "react-tooltip";
import {Helmet} from "react-helmet/es/Helmet";

const deepClone = require('deepclonejs');
const filestackClient = filestack.init('ADrblcnafTIuIUCrkNTrNz');




class UploadFiles extends Component {


    constructor(props) {

        super(props);

        let attachedFilesArray;

        if ( this.props.orderUploadedFilesArray ) {

            attachedFilesArray = this.props.orderUploadedFilesArray;

        } else {

            attachedFilesArray = [];

        }

        this.state = {
            isFileUploading: false,
            attachedFilesArray: attachedFilesArray
        };

    }



    deleteAttachFileFromList(fileID) {

        let newAttachedFilesArray = this.state.attachedFilesArray;

        for ( let i = 0; i < newAttachedFilesArray.length; i++ ) {

            if ( newAttachedFilesArray[i].id === fileID ) {

                newAttachedFilesArray.splice(i, 1);

            }

        }

        this.setState({ attachedFilesArray: newAttachedFilesArray },
            () => {

                this.props.onUpdateArraysUploadedFiles(this.state.attachedFilesArray);

            });

    }



    renderAttachFilesList() {

        if(this.state.attachedFilesArray) {

            return this.state.attachedFilesArray.map((file, index) => {

                return  <li key={index} id={file.id} className="attachItemFile"><span className="icon-file"></span> {file.name} <span onClick={this.deleteAttachFileFromList.bind(this, file.id)} className="icon-close"></span></li>

            });
        }

    }



    uploadFilesToFilestack(files) {

        this.setState({
            isFileUploading: true
        });

        let filesTotalSizeAmount = 0;
        let currentUploadSizeValueElement = document.querySelector('.cartFilestackUploader .currentUploadSizeValue');
        let finalUploadSizeValueElement = document.querySelector('.cartFilestackUploader .finalUploadSizeValue');
        let progressBarLineElement = document.querySelector('.cartFilestackUploader .filestackUploadProgressBarLine');
        let progressBarContainerElement = document.querySelector('.cartFilestackUploader');

        // get total size of all files
        Object.values(files).forEach((file) => {

            filesTotalSizeAmount += file.size;

        });

        // convert bytes value to readable version
        const bytesToSize = (bytes) => {

            let sizes = ['b', 'KB', 'MB', 'GB', 'TB'];

            if (bytes == 0) return '0 b';
            let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
            return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];

        }

        // show artwork upload progress bar
        const onProgress = (evt) => {

            currentUploadSizeValueElement.innerHTML = bytesToSize(evt.totalBytes);
            finalUploadSizeValueElement.innerHTML = bytesToSize(filesTotalSizeAmount);
            progressBarLineElement.setAttribute("style", `width: ${evt.totalPercent}%`);
            progressBarContainerElement.setAttribute("style", "display: block");

        };

        // відправлення нових файлів на filestack
        filestackClient.multiupload(files, {onProgress})
            .then(res => {

                progressBarContainerElement.setAttribute("style", "display: none");

                let attachedFilesArray = this.state.attachedFilesArray;


                // перезапаковка отриманого результату у більш зручний з відібраною інформацією
                // яка буле використовуватися у майбутньому
                res.forEach((item) => {

                    if ( item.status === "Failed" ) {

                        $('#artworkUploadErrorModalButton')[0].click();

                    } else {

                        attachedFilesArray.push({
                            id: Math.floor(Math.random() * Math.floor(99999)),
                            name: item.filename,
                            url: item.url
                        });

                    }

                });


                // збереження усіх новостворених масивів у state
                this.setState({
                    isFileUploading: false,
                    attachedFilesArray: attachedFilesArray
                }, () => {

                    this.props.onUpdateArraysUploadedFiles(this.state.attachedFilesArray);

                });


            })
            .catch(err => {

                $('#artworkUploadErrorModalButton')[0].click();
                console.log(err);

            });

    }




    render() {

        return (
            <div className="attachFilesPart">

                <div className="attachFilesPartTitle">
                    This is a good time to submit the files with artwork to be printed for your order.
                    You can submit as many files as you want.
                    Preferred files will be in vector format and uploaded as (.Ai, .Eps., .Pdf., .Svg.). You can also send archives as .zip .rar etc.<br/>
                    If you don't have files now, you can always email us later.
                </div>

                <div className="files-dropzone">

                    <input
                        type="file"
                        name="file"
                        multiple
                        onChange={ (e) => this.uploadFilesToFilestack(e.target.files) }
                    />

                    <div className="attachFileButton">

                        <div className="cartFilestackUploader filestackUploadProgressBar">
                            <div className="filestackUploadProgressBarLine"></div>

                            <div className="filestackUploadProgressBarSizeValue">
                                <span className="currentUploadSizeValue">0 KB</span>
                                of
                                <span className="finalUploadSizeValue">0 MB</span>
                            </div>

                        </div>


                        <span className="icon-attach"></span> Attach or drag&drop file(s) here
                    </div>

                </div>


                <ul>
                    { this.renderAttachFilesList() }
                </ul>

                { (this.state.isFileUploading) ? <div className="imageLoader"></div> : null }

            </div>
        )

    }
}







class StepCart extends Component {

    constructor(props) {
        super(props);

        let deliveringFormValuesRequired;
        let deliveringFormValuesOptional;

        if ( this.props.userOrderData ) {

            deliveringFormValuesRequired = this.props.userOrderData.deliveringFormValuesRequired;
            deliveringFormValuesOptional = this.props.userOrderData.deliveringFormValuesOptional;

        } else {

            deliveringFormValuesRequired = {
                name: (this.props.userAccountData) ? this.props.userAccountData.customer_full_name : null,
                phone: (this.props.userAccountData) ? this.props.userAccountData.customer_phone_number : null,
                email: (this.props.userAccountData) ? this.props.userAccountData.customer_email : null,
                address: (this.props.userAccountData) ? this.props.userAccountData.customer_address : null,
                zip: (this.props.userAccountData) ? this.props.userAccountData.customer_zip_number : null,
                state: (this.props.userAccountData) ? this.props.userAccountData.customer_state : null,
                city: (this.props.userAccountData) ? this.props.userAccountData.customer_city : null
            };
            deliveringFormValuesOptional = { comment: null };

        }

        this.state = {
            productIDForDeleting: 0,
            isPageLoaderActive: false,
            activeCartStepName: 'cart-order',
            errorEventOfShipping: false,
            isShippingAddressFromVisible: false,
            activeProductBasePrice: null,
            activeSidesColorsAmountObject: null,
            shippingType: null,
            promoCodeСoefficient: null,
            promoCodeValue: null,
            isPromoCodeLoading: false,
            promoCodeValueErrorMessage: false,
            deliveringFormValuesRequired: deliveringFormValuesRequired,
            deliveringFormValuesOptional: deliveringFormValuesOptional,
            screenWidth: window.innerWidth,
            mobileOptionsButtonFlag: {
                check: false,
                product_id: null
            }
        };

        this.deleteProductFromCart = this.deleteProductFromCart.bind(this);
        this.renderPriceBreaksTable = this.renderPriceBreaksTable.bind(this);
        this.confirmOrder = this.confirmOrder.bind(this);
        this.backToCart = this.backToCart.bind(this);
        this.getTotalProductsPriceInCartWithDeliveringCoefficient = this.getTotalProductsPriceInCartWithDeliveringCoefficient.bind(this);
        this.renderOrderPriceList = this.renderOrderPriceList.bind(this);
        this.handleChangeShippingType = this.handleChangeShippingType.bind(this);
        this.onPlaceAnOrder = this.onPlaceAnOrder.bind(this);
        this.handleChangeFormRequiredData = this.handleChangeFormRequiredData.bind(this);
        this.handleChangeFormOptionalData = this.handleChangeFormOptionalData.bind(this);
        this.fetchAfterPlaceAnOrder = this.fetchAfterPlaceAnOrder.bind(this);
        this.handleOnBlurOrderInput = this.handleOnBlurOrderInput.bind(this);
        this.handleProductOptionsButtonMobile = this.handleProductOptionsButtonMobile.bind(this);
        this.handleWindowSizeChange = this.handleWindowSizeChange.bind(this);

        this.usePromoCode = this.usePromoCode.bind(this);
        this.promoCodeInput = React.createRef();
        this.promoCodeModalRef = React.createRef();
    }



    componentDidMount () {


        // listener for app version switcher (desktop/mobile)
        window.addEventListener('resize', this.handleWindowSizeChange);


        // get the sticky element
        const stickyElm = document.getElementsByClassName('stickyBreakpointContainer')[0];

        if ( stickyElm ) {

            const observer = new IntersectionObserver(
                ([e]) => e.target.classList.toggle('isSticky', e.intersectionRatio < 1),
                {threshold: [1]}
            );

            observer.observe(stickyElm);

        }


    }


    // make sure to remove the listener
    // when the component is not mounted anymore
    componentWillUnmount() {

        window.removeEventListener('resize', this.handleWindowSizeChange);

    }



    componentDidUpdate(prevProps) {


        if (prevProps.productObjectForEditing !== this.props.productObjectForEditing) {

            this.props.history.push('product/'+this.props.productObjectForEditing.productSlug+'?isEditing=1');

        }


        if (prevProps.productList !== this.props.productList) {

            window.localStorage.setItem('product_list', JSON.stringify(this.props.productList));

        }


        if ( prevProps.userAccountData !== this.props.userAccountData && this.props.userAccountData ) {

            this.setState({

                deliveringFormValuesRequired: {
                    name: (this.props.userAccountData) ? this.props.userAccountData.customer_full_name : null,
                    phone: (this.props.userAccountData) ? this.props.userAccountData.customer_phone_number : null,
                    email: (this.props.userAccountData) ? this.props.userAccountData.customer_email : null,
                    address: (this.props.userAccountData) ? this.props.userAccountData.customer_address : null,
                    zip: (this.props.userAccountData) ? this.props.userAccountData.customer_zip_number : null,
                    state: (this.props.userAccountData) ? this.props.userAccountData.customer_state : null,
                    city: (this.props.userAccountData) ? this.props.userAccountData.customer_city : null
                }

            });

        }


    }



    usePromoCode() {

        this.setState({ isPromoCodeLoading: true });

        let urlForSavedOrderData = fetch("https://garagechamps.com/wp-json/quoteapi/coupon", {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                code: this.promoCodeInput.current.value
            })
        });


        return urlForSavedOrderData
            .then(response => {

                return response.json();

            })
            .then(json => {

                if (typeof json !== "object") {

                    this.setState({
                        promoCodeСoefficient: parseInt(json),
                        promoCodeValue: this.promoCodeInput.current.value,
                        promoCodeValueErrorMessage: null,
                        isPromoCodeLoading: false
                    }, () => {

                        this.promoCodeModalRef.current = null;
                        ReactTooltip.hide();

                    });

                }

                 else {

                    this.setState({
                        promoCodeСoefficient: null,
                        promoCodeValue: null,
                        promoCodeValueErrorMessage: json.message,
                        isPromoCodeLoading: false
                    });

                }

            });


    }



    calculateDiscountByPromoCode() {

        let totalPrice = this.getTotalProductsPriceInCartWithDeliveringCoefficient();

        if ( this.state.promoCodeСoefficient ) {

            return ( totalPrice - ( totalPrice * ( this.state.promoCodeСoefficient / 100 ) ) ).toFixed(2);

        } else {

            return null

        }

    }



    editProductFromCart(product_id) {

        let newCartList = deepClone( this.props.productList );

        for ( let i = 0; i < newCartList.length; i++ ) {

            if ( newCartList[i].id === product_id ) {

                let productMainData = {

                    product_app_id: newCartList[i].id,
                    product_object: newCartList[i].product_main_data

                };

                this.props.onEditProductFromCart( productMainData, newCartList[i].product_after_calculate_data );

            }

        }

    }



    saveProductIdForDeleting(product_id) {

        this.setState({
            productIDForDeleting: product_id
        });

    }



    deleteProductFromCart() {

        let newCartList = deepClone( this.props.productList );

        for ( let i = 0; i < newCartList.length; i++ ) {

            if ( newCartList[i].id === this.state.productIDForDeleting ) {

                newCartList.splice(i, 1);

            }

        }

        this.props.onDeleteProductFromCart(newCartList);


    }



    getTotalProductsPriceInCartWithDeliveringCoefficient() {

        let totalPriceProductsInCart = 0;

        if ( this.props.productList.length !== 0 ) {

            this.props.productList.forEach((product) => {

                 totalPriceProductsInCart += parseFloat(product.product_after_calculate_data.total_price);

            });

            return (totalPriceProductsInCart * this.props.deliveringTypeObject.delivering_coefficient).toFixed(2);

        } else {

            return 0;

        }

    }


    handleWindowSizeChange() {

        this.setState({ screenWidth: window.innerWidth });

    }


    handleChangeShippingType(event) {

        if (event.target.value === 'shipping-address') {

            this.setState({
                isShippingAddressFromVisible: true,
                shippingType: 'address',
                errorEventOfShipping: false
            });

        } else {
            this.setState({
                isShippingAddressFromVisible: false,
                shippingType: 'pickup',
                errorEventOfShipping: false
            });

        }

    }



    handleChangeFormOptionalData(event) {

        let inputName = event.target.name;
        let inputValue = event.target.value;

        this.setState(prevState => ({
            deliveringFormValuesOptional: {
                ...prevState.deliveringFormValuesOptional,
                [`${inputName}`]: inputValue
            }
        }));

    }



    handleChangeFormRequiredData(event) {

        let inputName;
        let inputValue;
        let parentElementClassList;

        if ( event.target !== undefined ) {

            inputName = event.target.name;
            inputValue = event.target.value;
            parentElementClassList = event.target.parentElement.classList;

        } else {

            inputName = event.name;
            inputValue = event.value;
            parentElementClassList = event.parentElement.classList;

        }


        if ( inputValue.trim().length > 0 ) {

            parentElementClassList.add('successInputMessage');
            parentElementClassList.remove('errorInputMessage');


            if ( inputName === 'email' ) {

                let emailReg = /^(([^<>() [\]\\.,;:\s@"]+(\.[^<>() [\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

                if ( emailReg.test(String(inputValue).toLowerCase()) ) {

                    parentElementClassList.add('successInputMessage');
                    parentElementClassList.remove('wrongFormatMessage');

                } else {

                    parentElementClassList.add('wrongFormatMessage');
                    parentElementClassList.remove('successInputMessage');

                }

            }

            if ( inputName === 'phone' ) {

                let phoneReg = /^[-()\s +/0-9]+$/;

                if ( phoneReg.test(String(inputValue).toLowerCase()) ) {

                    parentElementClassList.add('successInputMessage');
                    parentElementClassList.remove('wrongFormatMessage');

                } else {

                    parentElementClassList.add('wrongFormatMessage');
                    parentElementClassList.remove('successInputMessage');

                }

            }


        } else {

            parentElementClassList.add('errorInputMessage');
            parentElementClassList.remove('successInputMessage', 'wrongFormatMessage');

            // scroll to error element
            $('body,html').animate(
                {
                    scrollTop: $('.errorInputMessage').offset().top - 200

                }, 0
            );


        }

        this.setState(prevState => ({
            deliveringFormValuesRequired: {
                ...prevState.deliveringFormValuesRequired,
                [`${inputName}`]: inputValue
            }
        }));

    }



    handleOnBlurOrderInput() {

        this.props.onUpdateUserOrderData(this.state.deliveringFormValuesRequired, this.state.deliveringFormValuesOptional);

    }



    handleProductOptionsButtonMobile(product_id) {

        console.log(product_id);

        this.setState(prevState => ({
            mobileOptionsButtonFlag: {
                check: (prevState.mobileOptionsButtonFlag.product_id === product_id ) ? !prevState.mobileOptionsButtonFlag.check : true,
                product_id: product_id
            }
        }));

    }



    fetchAfterPlaceAnOrder() {


        this.setState({ isPageLoaderActive: true });


        let testLessonObject = fetch("https://garagechamps.com/wp-json/quoteapi/submit-order", {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                deliveringOptions: this.props.deliveringTypeObject,
                cartData: this.props.productList,
                orderFormData: {
                    shippingType: this.state.shippingType,
                    requiredData: this.state.deliveringFormValuesRequired,
                    optionalsData: {
                        comment: this.state.deliveringFormValuesOptional.comment,
                        uploadedFiles: this.props.orderUploadedFilesArray
                    }
                },
                totalPrice: {
                    promoCode: this.state.promoCodeValue,
                    discount: this.state.promoCodeСoefficient,
                    totalPrice: this.getTotalProductsPriceInCartWithDeliveringCoefficient(),
                    totalPriceWithPromoCode: this.calculateDiscountByPromoCode()
                }
            })
        });


        return testLessonObject
            .then(response => {

                return response.json();

            })
            .then(json => {

                console.log(json);


                // clear local storage after fetching order
                window.localStorage.removeItem('product_list');

                // clear cart list after fetching order
                // this.props.onResetCartList();

                // TODO: Зробити перевірку на поммлку, і показувати модальне вікно
                this.setState({ isPageLoaderActive: false });
                this.props.history.push('/cart/thankyou');

            });

    }



    onPlaceAnOrder() {

        let formData = this.state.deliveringFormValuesRequired;
        let wrongInputsList = document.querySelectorAll('.wrongFormatMessage');
        let inputsNamesArray = Object.keys(this.state.deliveringFormValuesRequired);

        /* Біжимо циклом по усім input форми і викликаємо до кожного функцію handleChangeFormRequiredData() з перевіркою на стан кожного елемента,
           і закрашуємо у відповідні статуси. */
        inputsNamesArray.forEach(inputName => {

            this.handleChangeFormRequiredData(document.querySelectorAll('[name=' + inputName + ']')[0]);

        });



        // Починається перевірка на валідність форми у різних випадках типу доставки
        switch ( this.state.shippingType ) {

            case 'pickup':


                if (!formData.name || !formData.phone || !formData.email || wrongInputsList.length > 0) {

                    // alert('перевірте дані в полях з червоним контуром');

                } else {

                    this.fetchAfterPlaceAnOrder();

                }

                break;


            case 'address':

                if (!formData.name || !formData.phone || !formData.email || !formData.address || !formData.zip || !formData.state || !formData.city || wrongInputsList.length > 0) {

                    // alert('перевірте дані в полях з червоним контуром');

                } else {

                    this.fetchAfterPlaceAnOrder();
                    // alert('красавчик, форма полетіла, доставка до ' + formData.address);

                }

                break;


            default:

                this.setState({
                    errorEventOfShipping: true
                });

                break;

        }


    }



    renderProductSlider(imagesObject) {


        if ( imagesObject !== null ) {

            let imagesArray = [];

            imagesObject.map((image) => {

                imagesArray.push({
                    original: image,
                    thumbnail: image
                });

                return null;

            });

            return (

                <ImageGallery
                    showPlayButton={false}
                    showFullscreenButton={false}
                    items={imagesArray}
                />

            )

        } else {

            return null;

        }



    }



    renderProductsList() {

        // initialize content version type by screen width
        const { screenWidth } = this.state;
        const isMobileVersion = screenWidth < 992;


        function renderArtworksList(product) {

            let sideObject = product.product_after_calculate_data.productImagesObject;

            return Object.keys(sideObject).map((key, index) => {

                if ( sideObject[key].upload_image_object ) {

                    return (

                        <li key={index}><img src={sideObject[key].upload_image_object.url} alt={sideObject[key].upload_image_object.name}/></li>

                    )

                } else {

                    return null;

                }

            });

        }

        function getArtworksListLength(product) {

            let sideObject = product.product_after_calculate_data.productImagesObject;
            let artwork_count = 0;

            Object.keys(sideObject).map((key, index) => {

                if ( sideObject[key].upload_image_object ) {

                    artwork_count++;

                }

            });

            return artwork_count;

        }


        if ( this.props.productList.length !== 0 ) {

            return this.props.productList.map((product, index) => {

                let active_product_color = product.product_after_calculate_data.activeProductColor;
                let available_color_styles = product.product_main_data.available_color_styles;
                let active_color_object = available_color_styles.filter(word => word.color_name === active_product_color);
                let active_color_model_img = active_color_object[0].color_model_images[0].url;


                return (

                    <div className="cartDesignItem" key={index}>

                        {(!isMobileVersion)

                            ?

                                <div className="cartDesignItemBody desktopVersionContainer">

                                <div className="artDesignIteArtworkBlock">
                                    <div className="cartDesignItemName">Artwork:</div>
                                    <div className="cartDesignItemArtworkList">
                                        <ul>
                                            {renderArtworksList(product)}
                                        </ul>
                                    </div>
                                </div>

                                <div className="cartProductList">

                                    <div className="cartProductItem" key={index}>
                                        <div className="row no-gutters">
                                            <div className="col-4 previewProductSliderContainer">
                                                {this.renderProductSlider(product.product_after_calculate_data.productImagesScreenshotsArray)}
                                            </div>
                                            <div className="col-8">
                                                <div className="cartProductItemBody">

                                                    <div className="cartProductItemOptionsButton">
                                                        <button type="button" className="cartProductItemEditButton"
                                                                onClick={this.editProductFromCart.bind(this, product.id)}>Edit <span
                                                            className="icon-edit"></span></button>
                                                        <button data-toggle="modal" data-target="#deleteProductModal"
                                                                type="button" className="cartProductItemDeleteButton"
                                                                onClick={this.saveProductIdForDeleting.bind(this, product.id)}>Delete <span
                                                            className="icon-close"></span></button>
                                                    </div>

                                                    <div
                                                        className={`cartProductName ${(product.product_after_calculate_data.activeServiceType === 'screen printing') ? "printingType" : null}`}>
                                                        <h5>{product.product_main_data.product_name}</h5>
                                                        <span
                                                            className="cartProductServiceType">{product.product_after_calculate_data.activeServiceType}</span>
                                                    </div>

                                                    <p>
                                                    <span
                                                        dangerouslySetInnerHTML={{
                                                            __html:
                                                                DOMPurify.sanitize(product.product_main_data.product_short_description)
                                                        }}
                                                    ></span>
                                                    </p>

                                                    <div className="cartProductSizesAmountListContainer row">

                                                        <div className="cartProductSizesAmountList">

                                                            {

                                                                product.product_main_data.product_sizes.map((size, index) => {

                                                                    return (

                                                                        <div key={index} className="productSizeItem"
                                                                             style={{width: 100 / (product.product_main_data.product_sizes.length + 1) - 2 + '%'}}>
                                                                            <label>{size.size_label}</label>
                                                                            <input readOnly type="number" min={0}
                                                                                   defaultValue={product.product_after_calculate_data.sizeAmountObject[size.size_label]}/>
                                                                        </div>

                                                                    )

                                                                })

                                                            }

                                                            <div className="productSizeItem"
                                                                 style={{width: 100 / (product.product_main_data.product_sizes.length + 1) + '%'}}>
                                                                <label className="totalTitle">Total</label>
                                                                <span>{product.product_after_calculate_data.total_products}</span>
                                                            </div>
                                                        </div>

                                                        <div className="cartProductItemPriceBreaksBlock">
                                                            <button
                                                                className="cartPriceBreaksButton"
                                                                onClick={this.saveParametersForPriceBreaks.bind(this, product.product_main_data.product_base_price, product.product_after_calculate_data.sidesColorsAmountObject)}
                                                                data-toggle="modal"
                                                                data-target="#priceBreaksModal"
                                                            >
                                                                Price breaks
                                                            </button>
                                                        </div>

                                                    </div>

                                                    <div className="cartProductItemColorPriceBlock row">
                                                        <div className="cartProductItemColorBlock col-7">
                                                            <div className="cartProductItemColorCircle"
                                                                 data-tip={product.product_after_calculate_data.activeProductColor}
                                                                 style={{backgroundColor: product.product_after_calculate_data.activeProductColorHEX}}></div>
                                                            <span
                                                                className="cartProductItemColorName">{product.product_after_calculate_data.activeProductColor}</span>
                                                            {/*<button><span className="icon-plus"></span> Add same product in another color</button>*/}
                                                        </div>
                                                        <div className="cartProductItemPriceBlock col-5"><span>Price per product:</span> ${product.product_after_calculate_data.single_price}
                                                        </div>
                                                    </div>

                                                    <div className="cartProductItemFooter row">
                                                        <div
                                                            className="cartProductItemFooterLeft col-6 productionCompletedDate">Production
                                                            Completed
                                                            By: {this.props.deliveringTypeObject.chosen_delivery_type_date_estimate}
                                                            <span data-toggle="modal"
                                                                  data-target="#deliveringTypeModal">Change</span></div>
                                                        {/*<span>Change</span>*/}
                                                        <div className="cartProductItemFooterRight col-6"><span>TOTAL PRICE:</span> ${product.product_after_calculate_data.total_price}
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>

                            :

                                <div className="cartDesignItemBody mobileVersionContainer">

                                    <div className="cartProductList">

                                        <div className="cartProductItem" key={index}>
                                            <div className="row no-gutters">

                                                <div className="col-2 cartProductItemImgCol">
                                                    <img src={active_color_model_img} alt={active_product_color} />
                                                </div>

                                                <div className="col-10 cartProductItemTitleCol">
                                                    <div className="cartProductItemTitle">

                                                        <div className={`cartProductName ${(product.product_after_calculate_data.activeServiceType === 'screen printing') ? "printingType" : null}`}>
                                                            <h5>{product.product_main_data.product_name}</h5>
                                                            <span className="cartProductServiceType">{product.product_after_calculate_data.activeServiceType}</span>
                                                        </div>

                                                        <div className={`cartProductItemOptionsButton ${(this.state.mobileOptionsButtonFlag.check && this.state.mobileOptionsButtonFlag.product_id === product.id) ? 'is-visible' : null }`}>
                                                            <button type="button"
                                                                    className="cartProductItemEditButton"
                                                                    onClick={this.editProductFromCart.bind(this, product.id)}>
                                                                Edit <span className="icon-edit"></span>
                                                            </button>
                                                            <button data-toggle="modal"
                                                                    data-target="#deleteProductModal"
                                                                    type="button"
                                                                    className="cartProductItemDeleteButton"
                                                                    onClick={this.saveProductIdForDeleting.bind(this, product.id)}>
                                                                Delete <span className="icon-close"></span>
                                                            </button>
                                                        </div>

                                                        <div className={`cartProductItemOptionsButtonIndicator ${(this.state.mobileOptionsButtonFlag.check && this.state.mobileOptionsButtonFlag.product_id === product.id) ? 'is-active' : null }`} onClick={this.handleProductOptionsButtonMobile.bind(this, product.id)}>
                                                            <span className="icon-dots"></span>
                                                        </div>

                                                    </div>
                                                </div>

                                                <div className="col-12 cartProductItemAccordionCol">

                                                    <div className="cartProductItemBody">

                                                        <div id={`accordion${index}`}>

                                                            <div className="card">
                                                                <div className={`card-header ${(getArtworksListLength(product) > 0) ? 'is-visible' : 'is-disabled' }`} id={`headingOne${index}`}>
                                                                    <h5 className="mb-0">
                                                                        <button className="btn btn-link"
                                                                                data-toggle="collapse"
                                                                                data-target={`#collapseOne${index}`}
                                                                                aria-expanded="false"
                                                                                aria-controls={`collapseOne${index}`}>
                                                                            Artwork
                                                                        </button>
                                                                    </h5>
                                                                </div>

                                                                <div id={`collapseOne${index}`} className="collapse"
                                                                     aria-labelledby={`headingOne${index}`}
                                                                     data-parent={`#accordion${index}`}>
                                                                    <div className="card-body">
                                                                        <div className="cartDesignItemArtworkList">
                                                                            <ul>
                                                                                {renderArtworksList(product)}
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="card">
                                                                <div className="card-header" id={`headingTwo${index}`}>
                                                                    <h5 className="mb-0">
                                                                        <button className="btn btn-link collapsed"
                                                                                data-toggle="collapse"
                                                                                data-target={`#collapseTwo${index}`}
                                                                                aria-expanded="false"
                                                                                aria-controls={`collapseTwo${index}`}>
                                                                            Quantity and size
                                                                        </button>
                                                                    </h5>
                                                                </div>
                                                                <div id={`collapseTwo${index}`} className="collapse"
                                                                     aria-labelledby={`headingTwo${index}`}
                                                                     data-parent={`#accordion${index}`}>
                                                                    <div className="card-body">
                                                                        <div className="cartProductSizesAmountListContainer row">

                                                                            <div className="cartProductSizesAmountList">

                                                                                {

                                                                                    product.product_main_data.product_sizes.map((size, index) => {

                                                                                        return (

                                                                                            <div key={index} className="productSizeItem"
                                                                                                 style={{width: 100 / (product.product_main_data.product_sizes.length + 1) - 2 + '%'}}>
                                                                                                <label>{size.size_label}</label>
                                                                                                <input readOnly type="number" min={0}
                                                                                                       defaultValue={product.product_after_calculate_data.sizeAmountObject[size.size_label]}/>
                                                                                            </div>

                                                                                        )

                                                                                    })

                                                                                }

                                                                                <div className="productSizeItem"
                                                                                     style={{width: 100 / (product.product_main_data.product_sizes.length + 1) + '%'}}>
                                                                                    <label className="totalTitle">Total</label>
                                                                                    <span>{product.product_after_calculate_data.total_products}</span>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>

                                                    </div>

                                                </div>

                                                <div className="col-12 cartProductItemPPPCol">

                                                    <div className="cartProductItemColorPriceBlock row">
                                                        <div className="cartProductItemColorBlock col-3">
                                                            <div className="cartProductItemColorCircle"
                                                                 data-tip={product.product_after_calculate_data.activeProductColor}
                                                                 style={{backgroundColor: product.product_after_calculate_data.activeProductColorHEX}}></div>
                                                            <span
                                                                className="cartProductItemColorName">{product.product_after_calculate_data.activeProductColor}</span>
                                                            {/*<button><span className="icon-plus"></span> Add same product in another color</button>*/}
                                                        </div>
                                                        <div className="cartProductItemPriceBlock col-9">
                                                            <span>Price per product:</span> ${product.product_after_calculate_data.single_price}
                                                            <button
                                                                className="cartPriceBreaksButton"
                                                                onClick={this.saveParametersForPriceBreaks.bind(this, product.product_main_data.product_base_price, product.product_after_calculate_data.sidesColorsAmountObject)}
                                                                data-toggle="modal"
                                                                data-target="#priceBreaksModal"
                                                            >
                                                                Price breaks
                                                            </button>
                                                        </div>
                                                    </div>

                                                </div>

                                                <div className="col-12 cartProductItemTotalPriceCol">

                                                    <div className="cartProductItemFooter row">
                                                        <div
                                                            className="cartProductItemFooterLeft col-6 productionCompletedDate">Production
                                                            Completed
                                                            By: {this.props.deliveringTypeObject.chosen_delivery_type_date_estimate}
                                                            <span data-toggle="modal"
                                                                  data-target="#deliveringTypeModal">Change</span></div>
                                                        {/*<span>Change</span>*/}
                                                        <div className="cartProductItemFooterRight col-6"><span>TOTAL PRICE:</span> ${product.product_after_calculate_data.total_price}
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>

                                    </div>

                                </div>

                        }

                    </div>


                )

            });

        }


    }



    renderOrderPriceList() {

        if ( this.props.productList.length !== 0 ) {


            return this.props.productList.map((product, index) => {


                return (

                    <tr key={index+1} className={((index+1) % 2 === 0) ? 'grayRow' : null}>
                        <td>
                            <span className="orderPriceItemCircle" data-tip={product.product_after_calculate_data.activeProductColor} style={{backgroundColor: product.product_after_calculate_data.activeProductColorHEX}}></span>
                            <p>{product.product_main_data.product_name}</p>
                        </td>
                        <td>

                            <p className="orderServicesType">

                                { (product.product_after_calculate_data.activeServiceType === 'screen printing')
                                    ? <span className="orderPrintingType">PRINT</span>
                                    : <span className="orderEmbroideryType">EMBR</span>
                                }

                            </p>

                        </td>

                        <td><p>{product.product_after_calculate_data.total_products} pcs</p></td>
                        <td><p>${product.product_after_calculate_data.total_price}/pcs</p></td>
                    </tr>


                )

            });

        }

    }


    renderOrderPriceTotal() {

        return (

            <div className="orderPriceTotalContainer">

                <div className="col-7">
                    <div className="orderPriceTotalTitle">Total price:</div>
                    <div className="orderPriceTotalSubTitle">Considering selected production speed</div>
                </div>

                <div className="orderPriceTotalNumber col-5">


                    <div className={( this.state.promoCodeСoefficient ) ? 'activePromoCode' : null }>
                        <span className="totalPriceWithoutPromoCode">${this.getTotalProductsPriceInCartWithDeliveringCoefficient()}*</span>
                        <span className="totalPriceWithPromoCode">${this.calculateDiscountByPromoCode()}*</span>
                    </div>

                    <div className="promoCodeModal">

                                                    <span ref={this.promoCodeModalRef} className="promoCodeModalTitle" data-tip data-for='promoCodeTooltip' data-event='click'>
                                                        {(this.state.promoCodeСoefficient) ? `-${this.state.promoCodeСoefficient}% discount accepted` : "Have a promo code?"}
                                                    </span>

                        <ReactTooltip id="promoCodeTooltip" effect='solid' place="bottom" type="light" clickable={true} globalEventOff='click-no'>

                            <div className={`promoCodeTooltipComponent ${ (this.state.isPromoCodeLoading) ? 'is-sending' : null }`}>
                                <div className="promoCodeInputContainer">
                                    <input
                                        className={(this.state.promoCodeValueErrorMessage) ? "not-valid-promo" : null}
                                        type="text" ref={this.promoCodeInput}
                                        placeholder="Promo code"/>
                                    <button className="applyPromoCodeButton" onClick={this.usePromoCode}>
                                        <span>Apply</span>
                                        <div className="applyPromoCodeLoader">
                                            <Loader
                                                type="ThreeDots"
                                                color="#FFFFFF"
                                                height="25"
                                                width="25"
                                            />
                                        </div>
                                    </button>
                                </div>
                                <div className='promoCodeModalInfo'>
                                    {(this.state.promoCodeValueErrorMessage) ?
                                        <div><span className="icon-warning"></span>{this.state.promoCodeValueErrorMessage}</div> : "Insert code to have discount"}
                                </div>
                            </div>

                        </ReactTooltip>

                    </div>

                </div>
            </div>

        )

    }


    renderOrderDeliveryButtons() {

        return (

            <>

                <div className="orderPriceDeliveringTitle">Your current production speed: <span>{this.props.deliveringTypeObject.delivering_type_name} (Ready by {this.props.deliveringTypeObject.chosen_delivery_type_date_estimate})**</span></div>

                <div className="orderPriceDeliveringList">

                    <div
                        className={(this.props.deliveringTypeObject.delivering_type_name === 'Standard') ? 'orderPriceDeliveringItem orderPriceDeliveringItemActive' : 'orderPriceDeliveringItem'}
                        onClick={this.props.onChangeDeliveringType.bind(this, STANDARD_DELIVERING_COEFFICIENT, 'Standard', STANDARD_DELIVERING_DAYS_AMOUNT)}
                    >
                        <div className="orderPriceDeliveringItemBody">
                            <div className="orderPriceDeliveringItemName">STANDARD</div>
                            <div className="orderPriceDeliveringItemImageBlock">
                                <span className="icon-rabbit"></span>
                                <p className="">2 weeks</p>
                            </div>
                        </div>
                        <div className="orderPriceDeliveringItemInfoBox">Base price</div>
                    </div>

                    <div
                        className={(this.props.deliveringTypeObject.delivering_type_name === 'Rapid') ? 'orderPriceDeliveringItem orderPriceDeliveringItemActive' : 'orderPriceDeliveringItem'}
                        onClick={this.props.onChangeDeliveringType.bind(this, RAPID_DELIVERING_COEFFICIENT, 'Rapid', RAPID_DELIVERING_DAYS_AMOUNT)}
                    >
                        <div className="orderPriceDeliveringItemBody">
                            <div className="orderPriceDeliveringItemName">RAPID</div>
                            <div className="orderPriceDeliveringItemImageBlock">
                                <span className="icon-cheetah"></span>
                                <p>1 week</p>
                            </div>
                        </div>
                        <div className="orderPriceDeliveringItemInfoBox"><span>+15%</span> to total price</div>
                    </div>

                    <div
                        className={(this.props.deliveringTypeObject.delivering_type_name === 'Full throttle') ? 'orderPriceDeliveringItem orderPriceDeliveringItemActive' : 'orderPriceDeliveringItem'}
                        onClick={this.props.onChangeDeliveringType.bind(this, FULL_THROTTLE_DELIVERING_COEFFICIENT, 'Full throttle', FULL_THROTTLE_DELIVERING_DAYS_AMOUNT)}
                    >
                        <div className="orderPriceDeliveringItemBody">
                            <div className="orderPriceDeliveringItemName throttleTitle">FULL THROTTLE</div>
                            <div className="orderPriceDeliveringItemImageBlock">
                                <span className="icon-eagle"></span>
                                <p>3 days</p>
                            </div>
                        </div>
                        <div className="orderPriceDeliveringItemInfoBox"><span>+30%</span> to total price</div>
                    </div>

                </div>

            </>

        )

    }



    confirmOrder() {

        window.scrollTo(0, 0);

        this.setState({
            activeCartStepName: 'cart-delivering'
        });

    }



    backToCart() {

        window.scrollTo(0, 0);

        this.setState({
            activeCartStepName: 'cart-order'
        });

    }



    saveParametersForPriceBreaks(productBasePrice, sidesColorsAmountObject) {

        this.setState({
            activeProductBasePrice: productBasePrice,
            activeSidesColorsAmountObject: sidesColorsAmountObject
        });

    }



    renderPriceBreaksTable() {

        if ( this.state.activeProductBasePrice && this.state.activeSidesColorsAmountObject ) {

            return PRICE_BREAKS_QTY_ARRAY.map((item, index) => {

                let currentPrice = calculateCurrentProductPriceOfAmountForPriceBreaks(item, this.state.activeProductBasePrice, this.state.activeSidesColorsAmountObject);
                let basePrice = calculateCurrentProductPriceOfAmountForPriceBreaks(PRICE_BREAKS_QTY_ARRAY[0], this.state.activeProductBasePrice, this.state.activeSidesColorsAmountObject);
                let savingValue =  ( (1 - ( currentPrice / basePrice ) ) * 100 ).toFixed(0);

                return (

                    <tr key={index}>
                        <td>{ item }</td>
                        <td>${ calculateCurrentProductPriceOfAmountForPriceBreaks(item, this.state.activeProductBasePrice, this.state.activeSidesColorsAmountObject) }</td>
                        <td>{ savingValue }%</td>
                    </tr>

                )

            });

        }

    }



    renderCartStep() {

        // initialize content version type by screen width
        const { screenWidth } = this.state;
        const isMobileVersion = screenWidth < 992;


        switch (this.state.activeCartStepName) {

            case 'cart-delivering':

                return (

                    <div className="deliveryStepBody">

                        <div className={`container ${(isMobileVersion) ? 'mobileVersionContainer' : null }`}>

                        <div className="deliveringBreadcrumbsContainer">
                            <div className="deliveringBreadcrumbsName">
                                <div className="backToStartStepButton" onClick={this.backToCart}>
                                    <span className="icon-arrow-right"></span> Back
                                </div>
                                DELIVERY OPTIONS
                            </div>
                            <button className="backToCartButton" onClick={this.backToCart}>
                                <span className="icon-arrow-right"></span> Back to cart
                            </button>
                        </div>

                        <div className="row">

                            <div className="col-6">

                                <div className="deliveringStepBlock">
                                    <span className="deliveringStepCounter">Step 1 of 3</span>
                                    <div className="deliveringStepName">Contact Information</div>
                                    <form>
                                        <div className="deliveringStepInputBox">
                                            <label>Name</label>
                                            <div className="inputContainer">
                                                <input
                                                    onChange={this.handleChangeFormRequiredData}
                                                    onBlur={this.handleOnBlurOrderInput}
                                                    defaultValue={ this.state.deliveringFormValuesRequired.name }
                                                    name="name"
                                                    type="text"
                                                    placeholder="Brian Donnelly"/>
                                                <div className="messageBox">
                                                    <span className="icon-ok"></span>
                                                    <span className="icon-close"></span>
                                                    <span className="wrongFormatTitle">Wrong format</span>
                                                    <span className="emptyFormatTitle">Required input</span>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="deliveringStepInputBox">
                                            <label>Phone number</label>
                                            <div className="inputContainer">
                                                <input
                                                    onChange={this.handleChangeFormRequiredData}
                                                    onBlur={this.handleOnBlurOrderInput}
                                                    defaultValue={ this.state.deliveringFormValuesRequired.phone }
                                                    name="phone"
                                                    type="text"
                                                    placeholder="(123) 456-7890"
                                                />
                                                <div className="messageBox">
                                                    <span className="icon-ok"></span>
                                                    <span className="icon-close"></span>
                                                    <span className="wrongFormatTitle">Wrong format</span>
                                                    <span className="emptyFormatTitle">Required input</span>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="deliveringStepInputBox">
                                            <label>E-mail</label>
                                            <div className="inputContainer">
                                                <input
                                                    onChange={this.handleChangeFormRequiredData}
                                                    onBlur={this.handleOnBlurOrderInput}
                                                    defaultValue={ this.state.deliveringFormValuesRequired.email }
                                                    name="email"
                                                    type="text"
                                                    placeholder="mailbox@mailbox.com"
                                                />
                                                <div className="messageBox">
                                                    <span className="icon-ok"></span>
                                                    <span className="icon-close"></span>
                                                    <span className="wrongFormatTitle">Wrong format</span>
                                                    <span className="emptyFormatTitle">Required input</span>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>

                                <div className={(this.state.errorEventOfShipping) ? "deliveringStepBlock animated shake stepError" : "animated deliveringStepBlock"}>
                                    <span className="deliveringStepCounter">Step 2 of 3</span>
                                    <div className="deliveringStepName">Shipping address</div>

                                    <div className="shippingAddressTypeContainer">
                                        <div className="shippingAddressTypeName">How do you want to collect your order?</div>

                                        <label className="shippingAddressTypePickupFromGarage">
                                            <input
                                                name="shipping-type-radio-input"
                                                type="radio"
                                                value="pickup"
                                                id="shipping-type-1"
                                                onChange={this.handleChangeShippingType}
                                            />
                                            <span htmlFor="shipping-type-1" className="checkmark">Pickup <span>at Garage Champs</span></span>
                                        </label>

                                        <label className="shippingAddressTypeCustomAddress">
                                            <input
                                                name="shipping-type-radio-input"
                                                type="radio"
                                                value="shipping-address"
                                                id="shipping-type-2"
                                                onChange={this.handleChangeShippingType}
                                            />
                                            <span htmlFor="shipping-type-2" className="checkmark">Shipping <span>to your address</span></span>
                                        </label>

                                    </div>

                                    <div className={`pickupAddressContainer animated ${(this.state.shippingType === 'pickup') ? "pickupAddressContainerVisible fadeIn" : null }`}>
                                        We are located on the
                                        <span>2608 R St. Sacramento, CA 95816.</span>
                                    </div>

                                    <form className={`shippingAddressForm animated ${(this.state.shippingType === 'address') ? "shippingAddressFormVisible fadeIn" : null }`}>
                                        <div className="deliveringStepInputBox">
                                            <label>Address</label>
                                            <div className="inputContainer">
                                                <input
                                                    onChange={this.handleChangeFormRequiredData}
                                                    onBlur={this.handleOnBlurOrderInput}
                                                    defaultValue={ this.state.deliveringFormValuesRequired.address }
                                                    name="address"
                                                    type="text"
                                                    placeholder="Brian Donnelly"
                                                />
                                                <div className="messageBox">
                                                    <span className="icon-ok"></span>
                                                    <span className="icon-close"></span>
                                                    <span className="wrongFormatTitle">Wrong format</span>
                                                    <span className="emptyFormatTitle">Required input</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="deliveringStepInputBox">
                                            <label>ZIP number</label>
                                            <div className="inputContainer">
                                                <input
                                                    onChange={this.handleChangeFormRequiredData}
                                                    onBlur={this.handleOnBlurOrderInput}
                                                    defaultValue={ this.state.deliveringFormValuesRequired.zip }
                                                    name="zip"
                                                    type="text"
                                                    placeholder="12345678"
                                                />
                                                <div className="messageBox">
                                                    <span className="icon-ok"></span>
                                                    <span className="icon-close"></span>
                                                    <span className="wrongFormatTitle">Wrong format</span>
                                                    <span className="emptyFormatTitle">Required input</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="deliveringStepInputBox">
                                            <label>State</label>
                                            <div className="inputContainer">
                                                <input
                                                    onChange={this.handleChangeFormRequiredData}
                                                    onBlur={this.handleOnBlurOrderInput}
                                                    defaultValue={ this.state.deliveringFormValuesRequired.state }
                                                    name="state"
                                                    type="text"
                                                    placeholder="Select state"
                                                />
                                                <div className="messageBox">
                                                    <span className="icon-ok"></span>
                                                    <span className="icon-close"></span>
                                                    <span className="wrongFormatTitle">Wrong format</span>
                                                    <span className="emptyFormatTitle">Required input</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="deliveringStepInputBox">
                                            <label>City</label>
                                            <div className="inputContainer">
                                                <input
                                                    onChange={this.handleChangeFormRequiredData}
                                                    onBlur={this.handleOnBlurOrderInput}
                                                    defaultValue={ this.state.deliveringFormValuesRequired.city }
                                                    name="city"
                                                    type="text"
                                                    placeholder="Select city"
                                                />
                                                <div className="messageBox">
                                                    <span className="icon-ok"></span>
                                                    <span className="icon-close"></span>
                                                    <span className="wrongFormatTitle">Wrong format</span>
                                                    <span className="emptyFormatTitle">Required input</span>
                                                </div>
                                            </div>
                                        </div>
                                    </form>

                                </div>

                                <div className="deliveringStepBlock">
                                    <span className="deliveringStepCounter">Step 3 of 3</span>
                                    <div className="deliveringStepName">Additional information</div>
                                    <form>
                                        <div className="deliveringStepInputBox deliveringStepInputBoxTextarea">
                                            <label>Comment on the order:</label>
                                            <textarea
                                                placeholder="Deadline, due date (optional)"
                                                name="comment"
                                                onChange={this.handleChangeFormOptionalData}
                                                onBlur={this.handleOnBlurOrderInput}
                                                defaultValue={ this.state.deliveringFormValuesOptional.comment }
                                            />
                                        </div>
                                        <UploadFiles
                                            orderUploadedFilesArray={this.props.orderUploadedFilesArray}
                                            onUpdateArraysUploadedFiles={this.props.onUpdateArraysUploadedFiles}
                                        />
                                    </form>
                                </div>

                            </div>
                            <div className="col-6 orderRightPart">


                                {(!isMobileVersion)

                                    ?

                                        <div className="orderPriceBlock">

                                            <div className="orderPriceName">ORDER PRICE</div>

                                            <div className="orderPriceTableContainer">
                                                <table className="orderPriceTable">
                                                    <tbody>
                                                    {this.renderOrderPriceList()}
                                                    </tbody>
                                                </table>
                                            </div>

                                            <div className="orderPriceBottomPart">

                                                <div className="row orderPriceTotalRow">
                                                    {this.renderOrderPriceTotal()}
                                                </div>

                                                <div className="orderPriceDeliveringContainer">

                                                    {this.renderOrderDeliveryButtons()}

                                                    <button className="placeOnOrderButton"
                                                            onClick={this.onPlaceAnOrder}>Place An Order <span
                                                        className="icon-ok"></span></button>

                                                </div>

                                            </div>

                                        </div>

                                    :

                                    <>

                                        <div className="orderPriceBlock">

                                            <div className="orderPriceName">ORDER PRICE</div>

                                            <div className="orderPriceTableContainer">
                                                <table className="orderPriceTable">
                                                    <tbody>
                                                        {this.renderOrderPriceList()}
                                                    </tbody>
                                                </table>
                                            </div>

                                            <div className="orderPriceBottomPart">

                                                <div className="orderPriceDeliveringInfoTitle">Production speed:</div>

                                                <div className="orderPriceDeliveringContainer">

                                                    {this.renderOrderDeliveryButtons()}

                                                </div>

                                            </div>

                                        </div>

                                        <div className="orderPriceTotalPricePanel">

                                            <div className="row orderPriceTotalRow">
                                                {this.renderOrderPriceTotal()}
                                            </div>

                                            <button className="placeOnOrderButton" onClick={this.onPlaceAnOrder}>
                                                Place An Order <span className="icon-ok"></span>
                                            </button>

                                        </div>

                                    </>

                                }


                                <div className="orderPriceInfoAfterPanel">

                                    <div className="orderPriceInfoAfterPanelText">
                                        By clicking "Place an order", you send the order to our sales manager who will verify artwork and print
                                        settings and call you regarding final confirmation and payment.
                                    </div>

                                    <div className="orderPriceInfoAfterPanelLegend">
                                        <span>* price excludes sales tax</span>
                                        <span>** excluding delivery time</span>
                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>

                    </div>

                );

            default:

                return (

                    <>
                        <div className="container">

                            { this.renderProductsList() }

                        </div>

                        <GoToStartStepButton />

                        { ( this.props.productList.length !== 0 )

                            ?

                                <>

                                    <div className="stickyBreakpointContainer">
                                        <div className="stickyBreakpoint"></div>
                                    </div>

                                    <div className="cartFixedFooter">
                                        <div className="container">
                                            <div className="cartFixedFooterTotalPrice"><i>MY TOTAL PRICE FOR THE ORDER:</i> <span>$</span><span className="cartTotalPrice">{this.getTotalProductsPriceInCartWithDeliveringCoefficient()}</span></div>
                                            <button className="confirmMyOrderButton" onClick={this.confirmOrder}>CONFIRM MY ORDER <span className="icon-ok"></span></button>
                                            <div className="confirmDetails">And specify delivery details</div>
                                        </div>
                                    </div>

                                </>

                            : null }



                        {/*{ DELETE PRODUCT MODAL START }*/}
                        <div className="modal fade messageModal" id="deleteProductModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <div className="afterAddedToCartModalTitle">Warning!</div>
                                        <span className="icon-close" data-dismiss="modal" aria-label="Close"></span>
                                    </div>
                                    <div className="modal-body deleteModalBody">
                                        Are you sure?<br /> You want to remove the product from the cart !
                                    </div>
                                    <div className="modal-footer deleteModalButtonsContainer">
                                        <button className="deleteModalCancelButton" onClick={this.cancelEditingProduct} type="button" data-dismiss="modal">Cancel</button>
                                        <button className="deleteModalDelButton" onClick={this.deleteProductFromCart} type="button" data-dismiss="modal">Delete</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*{ DELETE PRODUCT MODAL END }*/}


                        {/* MODAL PRICE BREAKS START */}
                        <div className="modal fade" id="priceBreaksModal" tabIndex="-1" role="dialog" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <div className="priceBreaksModalTitle">Order big, save even bigger!</div>
                                        <span className="icon-close" data-dismiss="modal" aria-label="Close"></span>
                                    </div>
                                    <div className="modal-body">
                                        <div className="priceBreaksModalDescription">
                                            Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                                            Donec odio. Quisque volutpat mattis eros. Nullam malesuada erat ut turpis.
                                            Suspendisse urna nibh, viverra non, semper suscipit, posuere a, pede.
                                        </div>

                                        <table className="priceBreaksModalTable">
                                            <thead>
                                            <tr>
                                                <th>Units</th>
                                                <th>Cost (Each)</th>
                                                <th>Saving</th>
                                            </tr>
                                            </thead>
                                            <tbody>

                                            { this.renderPriceBreaksTable() }

                                            </tbody>
                                        </table>


                                        <div className="priceBreaksAccordionTitle">Money saving tips:</div>
                                        <div className="accordion" id="priceBreaksAccordion">
                                            <div className="card">
                                                <div className="card-header" id="heading_1">
                                                    <h2 className="mb-0">
                                                        <button className="btn btn-link collapsed" type="button"
                                                                data-toggle="collapse" data-target="#collapse_1"
                                                                aria-expanded="false" aria-controls="collapse_1">
                                                            <span className="accordionPlusIndicator"></span>
                                                            Design for a single print location
                                                        </button>
                                                    </h2>
                                                </div>

                                                <div id="collapse_1" className="collapse"
                                                     aria-labelledby="heading_1" data-parent="#priceBreaksAccordion">
                                                    <div className="card-body">
                                                        Aside from print locations, ink colors is the next biggest
                                                        variable when it comes to print cost.  The more colors we use,
                                                        the longer it takes to set up and print your job.
                                                        Single ink color designs will be the most cost effective
                                                        way to design your garments.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card">
                                                <div className="card-header" id="heading_2">
                                                    <h2 className="mb-0">
                                                        <button className="btn btn-link collapsed" type="button"
                                                                data-toggle="collapse" data-target="#collapse_2"
                                                                aria-expanded="false" aria-controls="collapse_2">
                                                            <span className="accordionPlusIndicator"></span>
                                                            Reduce the amount of colors in your design
                                                        </button>
                                                    </h2>
                                                </div>
                                                <div id="collapse_2" className="collapse" aria-labelledby="heading_2"
                                                     data-parent="#priceBreaksAccordion">
                                                    <div className="card-body">
                                                        Aside from print locations, ink colors is the next biggest
                                                        variable when it comes to print cost.  The more colors we use,
                                                        the longer it takes to set up and print your job.
                                                        Single ink color designs will be the most cost effective
                                                        way to design your garments.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card">
                                                <div className="card-header" id="heading_3">
                                                    <h2 className="mb-0">
                                                        <button className="btn btn-link collapsed" type="button"
                                                                data-toggle="collapse" data-target="#collapse_3"
                                                                aria-expanded="false" aria-controls="collapse_3">
                                                            <span className="accordionPlusIndicator"></span>
                                                            Pick the right garment.
                                                        </button>
                                                    </h2>
                                                </div>
                                                <div id="collapse_3" className="collapse"
                                                     aria-labelledby="heading_3" data-parent="#priceBreaksAccordion">
                                                    <div className="card-body">
                                                        Aside from print locations, ink colors is the next biggest
                                                        variable when it comes to print cost.  The more colors we use,
                                                        the longer it takes to set up and print your job.
                                                        Single ink color designs will be the most cost effective
                                                        way to design your garments.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card">
                                                <div className="card-header" id="heading_4">
                                                    <h2 className="mb-0">
                                                        <button className="btn btn-link collapsed" type="button"
                                                                data-toggle="collapse" data-target="#collapse_4"
                                                                aria-expanded="false" aria-controls="collapse_4">
                                                            <span className="accordionPlusIndicator"></span>
                                                            Don't procrastinate
                                                        </button>
                                                    </h2>
                                                </div>
                                                <div id="collapse_4" className="collapse"
                                                     aria-labelledby="heading_4" data-parent="#priceBreaksAccordion">
                                                    <div className="card-body">
                                                        Aside from print locations, ink colors is the next biggest
                                                        variable when it comes to print cost.  The more colors we use,
                                                        the longer it takes to set up and print your job.
                                                        Single ink color designs will be the most cost effective
                                                        way to design your garments.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* MODAL PRICE BREAKS END */}


                    </>

                );

        }


    }







    render() {

        console.log(this.state.mobileOptionsButtonFlag);

        return (

            <div className="stepCart">

                <Helmet>
                    <title>Products Cart | GarageChamps</title>
                    <meta name="description" content="GarageChamps App" />
                </Helmet>


                { this.renderCartStep() }



                {/* MODAL ARTWORK UPLOAD ERROR START */}
                <button id="artworkUploadErrorModalButton" className="hiddenElement" data-toggle="modal" data-target="#artworkUploadErrorModal">-</button>

                <div className="modal fade" id="artworkUploadErrorModal" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">

                        <div className="modal-content">
                            <div className="modal-header">
                                <div className="guidelinesModalTitle modalErrorTitle">Oops, that didn't work</div>
                                <span className="icon-close" data-dismiss="modal" aria-label="Close"></span>
                            </div>
                            <div className="modal-body">
                                <div className="guidelinesModalDescription modalErrorDescription">
                                    <p>The Additional information doesn't support this format.<br/>
                                        It supports other files, such as
                                        <span> jpg, png, bmp, gif, pdf, psd, ai, eps</span><br />
                                        You can also send archives as <span> .zip .rar etc.</span>
                                    </p>
                                </div>

                                <div className="iUnderstandButtonContainer"><button data-dismiss="modal" aria-label="Close">I understand</button></div>
                            </div>
                        </div>

                    </div>
                </div>
                {/* MODAL ARTWORK UPLOAD ERROR END */}


                {/*PAGE LOADER*/}
                { (this.state.isPageLoaderActive) ? <div className="bigLoader">
                    <Loader
                        type="Oval"
                        color="#ED0084"
                        height="150"
                        width="150"
                    />
                </div> : null }

            </div>

        )

    }


}



export default withRouter(connect(
    state => ({
        productList: state.cart.product_list,
        deliveringTypeObject: state.delivering_options,
        userOrderData: state.order_option.user_order_data,
        productObjectForEditing: state.product_option.product_object_for_editing,
        orderUploadedFilesArray: state.order_option.uploaded_files_array,
        userAccountData: state.user_account.user_account_data_object
    }),
    dispatch => ({
        onDeleteProductFromCart: (newCartList) => {
            dispatch(deleteProductFromCart(newCartList));
        },
        onEditProductFromCart: (productMainData, productDataFromCart) => {

            const dataForEditingProductFromCart = {
                productMainData: productMainData,
                productDataFromCart: productDataFromCart
            };

            dispatch(editProductFromCart(dataForEditingProductFromCart));
        },
        onChangeDeliveringType: (delivering_type_coefficient, delivering_type_name, delivering_type_days_amount) => {
            let moment = require('moment-business-days');
            let newDeliveringTypeObject = {
                delivering_type_name: delivering_type_name,
                delivering_type_coefficient: delivering_type_coefficient,
                delivering_type_days_amount: delivering_type_days_amount
            };

            dispatch(changeDeliveringType(newDeliveringTypeObject));
        },
        onUpdateArraysUploadedFiles: (newUploadedFilesArray) => {
            dispatch(updateArraysUploadedFiles(newUploadedFilesArray));
        },
        onUpdateUserOrderData: (deliveringFormValuesRequired, deliveringFormValuesOptional) => {

            let newUserOrderDataObject = {
                deliveringFormValuesRequired: deliveringFormValuesRequired,
                deliveringFormValuesOptional: deliveringFormValuesOptional
            };

            dispatch(updateObjectUserOrderData(newUserOrderDataObject));
        },
        onResetCartList: () => {
            dispatch(resetCartList());
        }
    })
)(StepCart));
