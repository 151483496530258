import React, { Component } from 'react';
import {Image} from "react-konva";

class DraggableImageOnProduct extends React.Component {


    state = {
        image: null,
        isDragging: false
    };

    componentDidMount() {

        this.loadImage();

        if ( !this.props.isScreenshotMode ) {

            let then = this;

            this.draggableArtwork.on('dragend', function () {

                then.transformEndEvent();

            });

        }


    }


    transformEndEvent() {

        // this.draggableArtwork.detach();
        this.props.onCanvasTransformerChanges();

    }

    componentDidUpdate(oldProps) {
        if (oldProps.src !== this.props.src) {
            this.loadImage();
        }
    }


    componentWillUnmount() {
        this.image.removeEventListener('load', this.handleLoad, true);
    }


    loadImage() {
        // this.props.onStartProductImageLoading();
        this.image = new window.Image();
        // save to "this" to remove "load" handler on unmount
        //TODO: переробити через наш сервер , або прохі зробити ( для версії - скріншот )
        this.image.setAttribute('crossOrigin', 'anonymous');
        this.image.src = this.props.src;
        this.image.addEventListener('load', this.handleLoad, true);
    }



    handleLoad = () => {

        this.setState({
            image: this.image,
            imageHeight: this.image.height,
            imageWidth: this.image.width
        }, () => {

            this.props.uploadingImageIsLoaded();

        });

    };



    render() {


        if ( this.props.isScreenshotMode ) {

            return (
                <Image
                    x={this.props.x}
                    y={this.props.y}
                    image={this.state.image}
                    scaleX={this.props.scaleX}
                    scaleY={this.props.scaleY}
                    rotation={this.props.rotation}
                    width={150}
                    height={this.state.imageHeight / (this.state.imageWidth/150)}
                />
            );


        } else {

            return (

                <Image
                    x={this.props.x}
                    y={this.props.y}
                    image={this.state.image}
                    id="draggableImageOnProduct"
                    draggable
                    name="draggableImageOnProduct"
                    onDragEnd={e => {

                        this.props.saveCoordinatesDraggableImageOnProduct(e.target.x(), e.target.y());

                    }}
                    onTransformEnd={e => {

                        console.log(e.target);
                        this.props.saveTransformDataDraggableImageOnProduct(e.target.x(), e.target.y(), e.target.scaleX(), e.target.scaleY(), e.target.rotation());

                    }}
                    scaleX={this.props.scaleX}
                    scaleY={this.props.scaleY}
                    rotation={this.props.rotation}
                    width={150}
                    height={this.state.imageHeight / (this.state.imageWidth/150)}
                    ref={node => {
                        this.draggableArtwork = node;
                    }}
                />

            );

        }



    }
}


export default DraggableImageOnProduct;
